import {Messages} from '../../constants';
import {Alert} from '../../shims/alert';
import {bleManager} from '../ble2/v2/BleManager';

export const showBootloaderAlert = (callback: () => void) => {
  Alert.alert(Messages.OTA.PENDING.title, Messages.OTA.PENDING.body, [
    {
      text: 'Cancel',
      onPress: () => bleManager?.otaDevice?.disconnect(),
    },
    {
      text: 'Continue',
      onPress: callback,
    },
  ]);
};
