import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AlertPrompt} from '../../../components/AlertPrompt';
import {Modal} from '../../../components/Modal';
import {
  Constants,
  ErrorMessages,
  Messages,
  Strings,
  interpolate,
} from '../../../constants';
import {
  currentDeviceSelector,
  updateDeviceAppSettings,
} from '../../../lib/redux/bleSlice';

const {CLEANING_FREQUENCY_MAX_LENGTH, MAX_DAB_LIMIT} = Constants;
const {CUSTOM_DAB_TITLE, CUSTOM_DAB_DESCRIPTION} = Messages;
const {CUSTOM_DAB_LIMIT, CUSTOM_NEGATIVE_DAB} = ErrorMessages;
const {CANCEL, SAVE, DABS} = Strings;

export const CleaningReminderAlertPrompt = () => {
  const dispatch = useDispatch();
  const device = useSelector(currentDeviceSelector);

  const [dabInput, setDabInput] = React.useState<string>('');
  const dabValueError =
    parseInt(dabInput) < 0 || parseInt(dabInput) > MAX_DAB_LIMIT;

  const updateDabValues = (): void => {
    if (!device) return;
    // Convert to number
    const newValue = parseInt(dabInput);
    if (!dabValueError) {
      // Close modal
      Modal.close();

      // Update global
      dispatch(
        updateDeviceAppSettings({
          id: device.id,
          cleaningReminderDabs: newValue,
        }),
      );
    }
  };

  return (
    <AlertPrompt
      keyboardType={'number-pad'}
      visible={true}
      title={CUSTOM_DAB_TITLE}
      description={CUSTOM_DAB_DESCRIPTION}
      btn1Label={CANCEL}
      onPress1={() => {
        Modal.close();
        setDabInput('');
      }}
      btn2Label={SAVE}
      onPress2={updateDabValues}
      value={dabInput}
      placeholder={DABS}
      maxLength={CLEANING_FREQUENCY_MAX_LENGTH}
      error={dabValueError}
      errorMsg={
        Math.sign(parseInt(dabInput)) === -1
          ? CUSTOM_NEGATIVE_DAB
          : interpolate(CUSTOM_DAB_LIMIT, {dabLimit: MAX_DAB_LIMIT})
      }
      onChangeText={setDabInput}
    />
  );
};
