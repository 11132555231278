import React from 'react';
import {ImageSourcePropType, View} from 'react-native';
import Svg, {Circle, Path, Text} from 'react-native-svg';

import {appColors} from '../../constants';

export type LoadingHaloSVGProps = {
  percentage: number;
  color: string;
  showNumericalProgress?: boolean;
  reflectionImage?: ImageSourcePropType;
};

export const LoadingHaloSVG = ({
  percentage,
  color,
  showNumericalProgress = false,
}: LoadingHaloSVGProps) => {
  // From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
  function polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
  ) {
    const angleInRadians = ((angleInDegrees - 270) * Math.PI) / 180.0; // -270 to start progress under peak. Changed from 90.

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  function describeArc(
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number,
  ) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(' ');

    return d;
  }

  return (
    <View
      style={{
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}>
      <Svg
        height="100%"
        width="100%"
        viewBox="0 0 100 100"
        style={{alignSelf: 'center'}}>
        <Circle
          r={48}
          cx={50}
          cy={50}
          fill={'transparent'}
          stroke={appColors.lightMediumGray}
          strokeWidth="0.5"
          strokeDasharray=".5 1"
          strokeOpacity={0.3}
        />
        {showNumericalProgress && (
          <Text
            textAnchor="middle"
            fontFamily={'Roboto-Regular'}
            fontSize="36"
            fill="#000000"
            letterSpacing="0.31"
            opacity="0.3"
            y="65"
            x="50">
            {`${Math.round(percentage * 100)}%`}
          </Text>
        )}
        <Path
          d={describeArc(50, 50, 48, 1, 360 * percentage)}
          stroke={color}
          strokeWidth={7}
          fill="none"
          strokeOpacity={0.01}
        />
        <Path
          d={describeArc(50, 50, 48, 1, 360 * percentage)}
          stroke={color}
          strokeWidth={6}
          fill="none"
          strokeOpacity={0.02}
        />
        <Path
          d={describeArc(50, 50, 48, 1, 360 * percentage)}
          stroke={color}
          strokeWidth={5}
          fill="none"
          strokeOpacity={0.03}
        />
        <Path
          d={describeArc(50, 50, 48, 1, 360 * percentage)}
          stroke={color}
          strokeWidth={4}
          fill="none"
          strokeOpacity={0.04}
        />
        <Path
          d={describeArc(50, 50, 48, 1, 360 * percentage)}
          stroke={color}
          strokeWidth={3}
          fill="none"
          strokeOpacity={0.05}
        />
        <Path
          d={describeArc(50, 50, 48, 1, 360 * percentage)}
          stroke={color}
          strokeWidth={2}
          fill="none"
          strokeOpacity={0.06}
        />
        <Path
          d={describeArc(50, 50, 48, 1, 360 * percentage)}
          stroke={color}
          strokeWidth={1}
          fill="none"
          strokeOpacity={1.0}
        />
      </Svg>
    </View>
  );
};
