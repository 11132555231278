import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

import {backgroundFlourishMoodlightInterstitial} from '../../assets/images';
import {flourishMoodlightInterstitialText} from '../../assets/images';
import {
  ImgBackground,
  Modal,
  StyledButton,
  StyledButtonProps,
  StyledIcon,
} from '../../components';
import {Messages, Screens, appColors} from '../../constants';
import {InterstitialScreen} from '../../lib/InterstitialManager';
import {useMoodLight} from '../../lib/hooks';
import {updateAppFlags} from '../../lib/redux/appFlagsSlice';

interface Props {}

export const FlourishMoodlightInterstitial: React.FC<Props> = () => {
  const {accessMoodLight} = useMoodLight();

  const onCloseButtonPress = () => {
    Modal.close();
  };

  const onCheckItOutButtonPress = () => {
    Modal.close();

    accessMoodLight({});
  };

  return (
    <View style={styles.window}>
      <ImgBackground
        source={backgroundFlourishMoodlightInterstitial}
        style={styles.background}
        resizeMode="cover">
        <CloseButton onPress={onCloseButtonPress} />

        <View style={styles.container}>
          <View style={styles.textContainer}>
            <Text style={styles.text}>{Messages.NEW_EXCLUSIVE_MOODLIGHT}</Text>

            <Image
              source={flourishMoodlightInterstitialText}
              style={styles.flourishText}
            />
          </View>

          <StyledButton
            title={Messages.CHECK_IT_OUT}
            textStyle={{color: appColors.white}}
            style={styles.checkItOutButton}
            onPress={onCheckItOutButtonPress}
          />
        </View>
      </ImgBackground>
    </View>
  );
};

export const flourishMoodlightInterstitial: InterstitialScreen = {
  triggerScreen: Screens.Home,
  conditionMet: ({
    state: {
      appFlags: {hasSeenFlourishMoodlight},
      ble: {connectedDevice, devices},
    },
  }) => {
    if (!connectedDevice) return false;

    const currentDevice = devices.find(({id}) => id === connectedDevice.id);

    if (!currentDevice) return false;

    const isFlourish = currentDevice.product?.name === 'Flourish';

    if (!isFlourish) return false;

    return !hasSeenFlourishMoodlight;
  },
  modalComponent: dispatch => ({
    element: <FlourishMoodlightInterstitial />,
    onClose: () => dispatch(updateAppFlags({hasSeenFlourishMoodlight: true})),
  }),
  startDate: new Date('2024-05-01'),
  retriggerField: 'deviceId',
};

const CloseButton: React.FC<Pick<StyledButtonProps, 'onPress'>> = props => (
  <StyledIcon
    name="close"
    containerSize={30}
    size={14}
    color="black"
    style={styles.closeButton}
    {...props}
  />
);

const styles = StyleSheet.create({
  window: {
    width: '90%',
    height: '85%',
    borderRadius: 18,
    overflow: 'hidden',
  },
  closeButton: {
    backgroundColor: appColors.white,
    position: 'absolute',
    right: 24,
    top: 24,
    zIndex: 1,
  },
  background: {flex: 1},
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    marginHorizontal: 11,
    paddingVertical: 20,
  },
  textContainer: {marginTop: '5%'},
  text: {
    textTransform: 'uppercase',
    color: appColors.white,
    fontFamily: 'BigShouldersDisplay-Bold',
    fontSize: 14,
    letterSpacing: 0.2,
    fontWeight: '700',
  },
  flourishText: {width: 201, height: 59, marginTop: 14},
  checkItOutButton: {
    width: 211,
    backgroundColor: '#6D997F',
  },
});
