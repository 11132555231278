import {useNavigation} from '@react-navigation/native';
import React from 'react';

import {useSafeArea} from '../../lib/useSafeArea';

export const useAdaptiveSafeArea = () => {
  const insets = useSafeArea();
  const navigation = useNavigation();

  React.useLayoutEffect(() => {
    navigation.setOptions({headerStatusBarHeight: insets.top});
  }, [insets.top, navigation]);

  return insets;
};
