import React from 'react';
import {ImageSourcePropType, TextStyle, ViewProps} from 'react-native';

import {Screens} from '../../constants';

export type Theme = {
  // General
  durationStyle?: TextStyle;
  fadedTextColor: string;
  logoTint?: string;
  logoIcon: ImageSourcePropType;
  navMenuIconColor: string;
  navMenuTitleStyle: NavMenuTitleStyle;
  primaryColor: string;
  peakNameTextColor?: string;
  primaryTextColor: string;
  spinnerColor: string;
  alternateSpinnerColor?: string;
  temperatureBigStyle?: TextStyle;
  backgroundColor?: string;

  // Components
  bottomSheetTheme: BottomSheetTheme;
  customSwitchTheme: CustomSwitchTheme;
  heatProfileCardTheme: HeatProfileCardTheme;
  heatProfileCoreImageTheme?: HeatProfileCoreImageTheme;
  heatProfileScreenBackgroundTheme: HeatProfileScreenBackgroundTheme;
  lanternTypeButtonTheme: LanternTypeButtonTheme;
  moodLightCardTheme: MoodLightCardTheme;
  moodTypeButtonTheme: MoodTypeButtonTheme;
  peakImageTheme: PeakImageTheme;
  sectionDividerColor: string;
  sectionTitleTextColor: string;
  statusDisplayTextColor: string;
  styledButtonTheme: StyledButtonTheme;
  tabBarTheme: TabBarTheme;
  statusBarTheme: StatusBarTheme;

  // Screens
  assignToAHeatProfileScreenTheme: AssignToAHeatProfileScreenTheme;
  connectScreenTheme: ConnectScreenTheme;
  controlCenterTheme: ControlCenterTheme;
  dabbingScreenTheme: DabbingScreenTheme;
  devicesListScreenTheme: DevicesListScreenTheme;
  heatProfileEditScreenTheme: HeatProfileEditScreenTheme;
  heatProfileListScreenTheme: HeatProfileListScreenTheme;
  heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme;
  homeScreenTheme: HomeScreenTheme;
  limitedEditionModalScreenTheme?: LimitedEditionModalScreenTheme;
  limitedEditionVideoScreenTheme?: LimitedEditionVideoScreenTheme;
  saveToDeviceScreenTheme: SaveToDeviceScreenTheme;
  sharedMoodScreenTheme: SharedMoodScreenTheme;

  dabTickTheme?: DabTickTheme;
};

export type DabTickTheme = {
  color?: string;
};

// General

export type NavMenuTitleStyle = Required<
  Pick<TextStyle, 'fontFamily' | 'fontSize' | 'color' | 'fontWeight'>
>;

// Components

export type GradientShadeFunction = (
  color: string,
  opacityFactor?: string,
) => string[];

export type BottomSheetTheme = {
  buttonBackgroundColor: string;
  buttonTextColor: string;
  backgroundColor: string;
  dividerColor: string;
  titleColor: string;
  subtitleColor: string;
  lineColor: string;
  iconColor: string;
  gradientShadeFunction: GradientShadeFunction;
  IconBaseBackground?: React.FC<ViewProps>;
};

export type CustomSwitchTheme = {
  backgroundColorOn: string;
  backgroundColorOff: string;
};

export type HeatProfileCardTheme = {
  primaryFontColor: string;
  durationFontColor: string;
  specularColorStart: string;
  specularColorEnd: string;
  iconColor: string;
  gradientShadeFunction: GradientShadeFunction;
  overLayGradientColors?: string[];
  backgroundImage?: ImageSourcePropType;
  editBackgroundImage?: ImageSourcePropType;
  iosShadowAlpha?: number;
  durationBubbleColor?: string;
};

export type HeatProfileCoreImageTheme = {
  baseImageSource: ImageSourcePropType;
  maskImageSource: ImageSourcePropType;
  whiteColorShadePercentage?: number;
  disableEditAnimated?: boolean;
  tickColor?: string;
};

export type HeatProfileScreenBackgroundTheme = {
  gradientShadeFunction: GradientShadeFunction;
  backgroundOpacityFactor?: string;
  background?: ImageSourcePropType;
  backgroundImageOriginalHeight?: number;
  backgroundImageHeightToGemHeightRatioGlow?: number;
  backgroundImageHeightToGemHeightRatioAnimated?: number;
};

export type LanternTypeButtonTheme = {
  activeForeground: string;
  inactiveForeground: string;
  activeBackground: string;
  inactiveBackground: string;
};

export type MoodLightCardTheme = {
  gradientShadeFunction: GradientShadeFunction;
  textColor?: string;
};

export type MoodTypeButtonTheme = {
  activeForeground: string;
  inactiveForeground: string;
  activeBackground: string;
  inactiveBackground: string;
  textColor: string;
};

export type PeakImageLayerKey = keyof PeakImageLayers;

export type PeakImageLayers = {
  midLeft?: ImageSourcePropType;
  midRight?: ImageSourcePropType;
  farLeft?: ImageSourcePropType;
  farRight?: ImageSourcePropType;
  left?: ImageSourcePropType;
  right?: ImageSourcePropType;
};

interface PeakLayers {
  glass: ImageSourcePropType;
  base: ImageSourcePropType;
  chamber: {
    regular: ImageSourcePropType;
    xl: ImageSourcePropType;
  };
}

export type PeakImageTheme = {
  peak: ImageSourcePropType;
  peakLayers?: PeakLayers;
  lightLayers: Record<PeakSection, PeakImageLayers>;
  peakDarkScreen: ImageSourcePropType;
  peakDisconnectedColor: string;
};

export enum PeakSection {
  RING = 'ring',
  GLASS = 'glass',
  BASE = 'base',
}

export type StyledButtonTheme = {
  buttonColor: string;
  buttonTextColor: string;
};

export type TabBarTheme = {
  dividerColor: string;
  activeColor: string;
  inactiveColor: string;
  backgroundColor: string;
  height: number;
  heatProfileListNavBg?: ImageSourcePropType;
};

type ScreenNamesValues = (typeof Screens)[keyof typeof Screens];

export type StatusBarTheme = {
  [K in ScreenNamesValues as `${K & string}StatusBar`]?: StatusBarStyle;
} & {
  light: StatusBarStyle;
  dark: StatusBarStyle;
  themed: StatusBarStyle;
};

export type StatusBarStyle = {
  backgroundColor: string;
  barStyle: 'dark-content' | 'light-content';
};

// Screens

export type AssignToAHeatProfileScreenTheme = {
  selectedBorderColor: string;
};

export type ConnectScreenTheme = {
  backgroundImage: ImageSourcePropType;
  peakNameBackgroundColor: string;
  peakNameTextColor?: string;
  groundLayerImage?: ImageSourcePropType;
  gradientBackground?: {
    backgroundColor: string;
    gradientArray: string[];
  };

  // changing the Peak Image PNG or the Ground Layer Image PNG will
  // likely require changing some of the below values
  // See docs/Ground Layer Alignment Guide.png
  // horizontal alignment is just assumed to be center align for the Connect Screen
  // so we only handle vertical alignment and scaling
  groundLayer?: {
    // used to scale Ground Image based on scaled Peak Image
    groundLayerImageOriginalWidth: number; // native width of Ground Layer Image PNG
    groundLayerImageOriginalHeight: number; // native height of Ground Layer Image PNG
    groundLayerImageWidthToPeakImageWidthRatio: number; // ratio between Ground Layer Image PNG width and Peak Image PNG width such that feature sizes match

    // used to align Ground Image relative to Peak Image
    // normalized values [0, 1]
    // origin 0, 0 is top left
    normalizedPeakImageYOffset: number; // Y offset of alignment point in Peak Image PNG
    normalizedGroundLayerYOffset: number; // Y offset of alignment point in Ground Layer Image PNG
  };
};

export type ControlCenterTheme = {
  titleText: string;
  infoText: string;
  fatSliderColor: string;
  fatSliderBGColor: string;
  buttonActiveForeground: string;
  buttonInactiveForeground: string;
  buttonActiveBackground: string;
  buttonInactiveBackground: string;
  blurBackgroundColor: string;
  backgroundColor: string;
  boostScreenLabel: string;
  gradientArray?: string[];
};

export type DabbingScreenTheme = {
  dabbingScreenActiveText: string;
  dabbingScreenFadedText: string;
  dabbingButtonBackground: string;
  dabbingButtonBackgroundPressed: string;
  dabbingButtonForeground: string;
  dabbingButtonForegroundPressed: string;
  targetTemperatureStyle?: TextStyle;
  timeStatusStyle?: TextStyle;
  iconColor?: string;
  textColor?: string;
  fadedTextColor?: string;
};

export type DevicesListScreenTheme = {
  cardColor: string;
  cardText: string;
  listTitleText: string;
  listDivider: string;
  trashCanIconColor: string;
  activeOpacity: number;
  inactiveOpacity: number;
  connectedIconColor: string;
  disconnectedIconColor: string;
  blurBackgroundColor: string;
};

export type HeatProfileEditScreenTheme = {
  editTextColor: string;
  sliderTitleColor: string;
  sliderThumbColor: string;
  sliderTrackFillColor: string;
  sliderTrackBackgroundColor: string;
  sliderIconTintColor: string;
  moodLightHeaderBackgroundColor: string;
  durationColor: string;
  iconColor?: string;
  navMenuTitleStyle?: NavMenuTitleStyle;
};

export type HeatProfileListScreenTheme = {
  gradientArray?: string[];
  backgroundColor?: string;
  background?: ImageSourcePropType;
  fontFamily?: string;
  letterSpacing?: number;
  hoverBackgroundColor: string;
};

export type HeatProfileSelectScreenTheme = {
  backgroundLineColor: string;
  durationColor: string;
  primaryColor: string;
  startTextStyle?: TextStyle;
};

export type HomeScreenTheme = {
  // General
  backgroundImage: ImageSourcePropType;
  gradientBackground?: {
    backgroundColor: string;
    gradientArray: string[];
  };
  peakDisconnectedColor: string;
  groundLayerImage?: ImageSourcePropType;

  // changing the Peak Image PNG or the Ground Layer Image PNG will
  // likely require changing some of the below values
  // See docs/Ground Layer Alignment Guide.png
  groundLayer?: {
    // used to scale Ground Image based on scaled Peak Image
    groundLayerImageOriginalWidth: number; // native width of Ground Layer Image PNG
    groundLayerImageOriginalHeight: number; // native height of Ground Layer Image PNG
    groundLayerImageWidthToPeakImageWidthRatio: number; // ratio between Ground Layer Image PNG width and Peak Image PNG width such that feature sizes match

    // used to align Ground Image relative to Peak Image
    // normalized values [0, 1]
    // origin 0, 0 is top left
    normalizedPeakImageXOffset: number; // X offset of alignment point in Peak Image PNG
    normalizedPeakImageYOffset: number; // Y offset of alignment point in Peak Image PNG
    normalizedGroundLayerXOffset: number; // X offset of alignment point in Ground Layer Image PNG
    normalizedGroundLayerYOffset: number; // Y offset of alignment point in Ground Layer Image PNG
  };

  // Status info
  batteryBarBackgroundColor: string;
  batteryBarForegroundColor: string;
  batteryDisconnectedColor: string;
  preserveIndicator: string;
  statusTextConnectedColor: string;
  statusTextDisconnectedColor: string;
  statusTextStealthColor: string;
  statusTitleTextColor: string;
  stealthIconColor: string;

  // Metric info
  dataBackgroundGradientArray: string[];
  bowlWarningColor: string;
  metricDisconnectedTextColor: string;
  metricTextColor: string;
  metricTitleTextColor: string;

  // Limited edition info
  limitedEditionText?: {
    leftBorderGradientArray: string[];
    name: {color: string; value: string};
    numberString: {color: string; value: string};
  };
};

export type LimitedEditionModalScreenTheme = {
  backgroundImage: ImageSourcePropType;
  gradientBackground?: {
    backgroundColor: string;
    gradientArray: string[];
  };
  groundLayerImage?: ImageSourcePropType;

  // changing the Peak Image PNG or the Ground Layer Image PNG will
  // likely require changing some of the below values
  // See docs/Ground Layer Alignment Guide.png
  // horizontal alignment is just assumed to be center align for the Connect Screen
  // so we only handle vertical alignment and scaling
  groundLayer?: {
    // used to scale Ground Image based on scaled Peak Image
    groundLayerImageOriginalWidth: number; // native width of Ground Layer Image PNG
    groundLayerImageOriginalHeight: number; // native height of Ground Layer Image PNG
    groundLayerImageWidthToPeakImageWidthRatio: number; // ratio between Ground Layer Image PNG width and Peak Image PNG width such that feature sizes match

    // used to align Ground Image relative to Peak Image
    // normalized values [0, 1]
    // origin 0, 0 is top left
    normalizedPeakImageYOffset: number; // Y offset of alignment point in Peak Image PNG
    normalizedGroundLayerYOffset: number; // Y offset of alignment point in Ground Layer Image PNG
  };
};

export type LimitedEditionVideoScreenTheme = {videoUrl: string};

export type SaveToDeviceScreenTheme = {
  gradientArray?: string[];
  background?: ImageSourcePropType;
  infoLabelColor: string;
};

export type SharedMoodScreenTheme = {
  gradientShadeFunction: (color: string) => string[];
};
