import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {setUser as setSentryUser} from '../../shims/sentry';
import {setUser as setLoggerUser} from '../Logger';
import {Store, User, User5} from '../types';

const userSlice = createSlice({
  name: 'user',
  initialState: null as User | null,
  reducers: {
    setUser(state, action: PayloadAction<User | null>) {
      state = action.payload;
      setLoggerUser(action.payload ?? undefined);
      setSentryUser(action.payload ?? undefined);
      return state;
    },
    updateUser(state, action: PayloadAction<User>) {
      state = {...state, ...action.payload};
      setLoggerUser(action.payload ?? undefined);
      setSentryUser(action.payload ?? undefined);
      return state;
    },
  },
});

export const userSelector = (state: Store): User5 | undefined => state.user;
export const userIdSelector = (state: Store): number | undefined =>
  userSelector(state)?.id;

export const {setUser, updateUser} = userSlice.actions;

export const userReducer = userSlice.reducer;
