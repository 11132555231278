import {ConnectedDevice, Pikaparam} from 'pikaparam';
import {distinctUntilChanged, map} from 'rxjs/operators';

import {bleLog} from '../../Logger';

export interface OtaDeviceOptions {
  device: ConnectedDevice;
  pikaparam: Pikaparam;
}

export class OtaDevice {
  public readonly peripheralId: string;
  public readonly name?: string;

  private readonly pikaparam: Pikaparam;

  constructor({device, pikaparam}: OtaDeviceOptions) {
    this.peripheralId = device.id;
    this.name = device.name;
    this.pikaparam = pikaparam;
  }

  get deviceState() {
    return this.pikaparam.ota.deviceState;
  }

  get deviceStateObs() {
    return this.pikaparam.ota.deviceStateObs;
  }

  get flashState() {
    return this.pikaparam.ota.flashState;
  }

  get flashStateObs() {
    return this.pikaparam.ota.flashStateObs;
  }

  get serialNumber() {
    return this.pikaparam.ota.serialNumber;
  }

  public isInBootloaderMode(): boolean {
    return this.pikaparam.ota.isConnectedLoader;
  }

  public async rebootToBootloader(): Promise<string> {
    bleLog.info('Reboot to bootloader');

    const name = this.pikaparam.ota.enterBootloader();

    await this.pikaparam.ota.awaitDisconnect();

    return name;
  }

  public async flash(data: ArrayBuffer): Promise<void> {
    await this.pikaparam.ota.flash(data);
  }

  public subscribeToFlashProgress(callback: (value: number) => void) {
    return this.pikaparam.ota.flashProgressObs
      .pipe(
        map(value => Math.round(value ?? 0)),
        distinctUntilChanged(),
      )
      .subscribe(callback);
  }

  public async disconnect() {
    bleLog.info('Ota device disconnect.');
    this.pikaparam.conn.disconnect();
    await this.pikaparam.ota.awaitDisconnect();
  }
}
