import {
  peachBlackBackgroundHomeScreen,
  peakPeachBlack,
  peakPeachBlackBase,
  peakPeachBlackChamberRegular,
  peakPeachBlackChamberXl,
  peakPeachBlackGlass,
  peakPeachBlackLightsBaseLeft,
  peakPeachBlackLightsBaseRight,
} from '../assets/images';
import {appColors} from '../constants/Colors';
// Import from exact file to prevent cycle
import {
  DabbingScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  PeakImageTheme,
  Theme,
} from '../lib/types';
import {addOpacityToColorHex} from '../lib/utilityFunctions';
import {defaultTheme} from './DefaultTheme';
import {whitePeachTheme} from './WhitePeachTheme';

const {
  lightLayers: {glass},
} = whitePeachTheme.peakImageTheme;

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: peakPeachBlack,
  peakLayers: {
    glass: peakPeachBlackGlass,
    base: peakPeachBlackBase,
    chamber: {
      regular: peakPeachBlackChamberRegular,
      xl: peakPeachBlackChamberXl,
    },
  },
  lightLayers: {
    glass,
    base: {
      left: peakPeachBlackLightsBaseLeft,
      right: peakPeachBlackLightsBaseRight,
    },
    ring: {},
  },
  peakDarkScreen: peakPeachBlack,
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: appColors.white,
  dabbingScreenFadedText: appColors.mediumGray50,
  dabbingButtonBackground: appColors.bone,
  dabbingButtonBackgroundPressed: appColors.black,
  dabbingButtonForeground: appColors.black,
  dabbingButtonForegroundPressed: appColors.bone,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  ...defaultTheme.heatProfileEditScreenTheme,
  editTextColor: appColors.white,
  iconColor: appColors.white,
  navMenuTitleStyle: {
    ...defaultTheme.navMenuTitleStyle,
    color: appColors.white,
  },
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  durationBubbleColor: appColors.black,
  durationFontColor: appColors.white,
  specularColorStart: appColors.clear,
  specularColorEnd: appColors.clear,
};

export const blackPeachTheme: Theme = {
  ...defaultTheme,
  sectionDividerColor: appColors.white20,
  dabbingScreenTheme,
  tabBarTheme: {
    ...defaultTheme.tabBarTheme,
    backgroundColor: appColors.invisible,
  },
  homeScreenTheme: {
    ...defaultTheme.homeScreenTheme,
    backgroundImage: peachBlackBackgroundHomeScreen,
    // Metric info
    dataBackgroundGradientArray: [
      addOpacityToColorHex(appColors.black, 0.9),
      addOpacityToColorHex(appColors.black, 0),
    ],
  },
  heatProfileCardTheme,
  heatProfileEditScreenTheme,

  // Hide Logo
  logoTint: appColors.invisible,
  spinnerColor: appColors.white,
  peakImageTheme,

  dabTickTheme: {
    color: appColors.white,
  },
};
