import React from 'react';
import {StyleSheet, View} from 'react-native';

import {MoodLightList, StyledIcon} from '../../components';
import {Navigators, Screens, appColors, fillStyle} from '../../constants';
import {useBackPress} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {ControlCenterNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';

interface Props
  extends ControlCenterNavigatorScreenProps<typeof Screens.MoodLightLibrary> {}

export const MoodLightLibraryScreen = ({navigation}: Props) => {
  useDisconnectGuard();

  const insets = useAdaptiveSafeArea();

  const onBackPress = React.useCallback(() => {
    navigation.goBack();

    return true;
  }, [navigation]);

  useBackPress(onBackPress);

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <StyledIcon
          name="add"
          onPress={() =>
            navigation.navigate(Navigators.MoodLightController, {
              screen: Screens.MoodLightController,
            })
          }
          iconStyle={{tintColor: appColors.white}}
        />
      ),
    });
  }, [navigation]);

  return (
    <View
      style={[
        styles.container,
        {
          paddingTop: insets.top + 60,
          paddingBottom: insets.bottom,
          backgroundColor: appColors.black,
        },
      ]}>
      <MoodLightList />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {...fillStyle, flexDirection: 'column', justifyContent: 'center'},
});
