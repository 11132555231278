import React from 'react';
import {Text} from 'react-native';
import {useSelector} from 'react-redux';

import {backgroundAccountCreated} from '../../assets/images';
import {Navigators, Screens, Strings, appColors} from '../../constants';
import {Messages} from '../../constants';
import {useBackPress, useWindowHeight} from '../../lib/hooks';
import {currentDeviceIdSelector} from '../../lib/redux/bleSlice';
import styled from '../../lib/styled';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {RedirectionParam} from '../../navigation/navigators/params';
import {toHome} from '../../navigation/navigators/util';
import {ImageAndContentContainer} from '../components';

export interface Props extends RedirectionParam {
  isSocial?: boolean;
  email?: string;
}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.AccountCreated> {}

export const AccountCreatedScreen: React.FC<ScreenProps> = ({
  route,
  navigation,
}) => {
  const hasCurrentDevice = !!useSelector(currentDeviceIdSelector);

  const isSocial = route.params?.isSocial;
  const email = route.params?.email ?? '';
  const redirect = route.params?.redirect;

  const {isMedium} = useWindowHeight();

  const onClick = async () => {
    if (!hasCurrentDevice)
      return navigation.replace(Navigators.MainNavigator, {
        screen: Screens.BluetoothStartPairing,
        params: {redirect},
      });

    return navigation.replace(Navigators.MainNavigator, {
      screen: Navigators.HomeDrawerNavigator,
      params: {
        screen: Navigators.HomeEmulatedDrawer,
        params: {
          screen: Screens.ChangeUsername,
          params: {redirect},
        },
      },
    });
  };

  useBackPress(() => {
    if (!hasCurrentDevice) return true;

    navigation.replace(...(redirect ?? toHome));
  });

  return (
    <ImageAndContentContainer
      image={backgroundAccountCreated}
      header={{content: Messages.ACCOUNT_CREATED_TITLE}}
      body={{
        content: isSocial
          ? Messages.ACCOUNT_CREATED_SOCIAL
          : Messages.ACCOUNT_CREATED_SIGNUP,
      }}
      imageContainerStyle={{maxHeight: isMedium ? undefined : 502}}
      bodyContainerStyle={{flex: isMedium ? undefined : 0.5}}
      primaryButton={{
        title: Strings.CONTINUE,
        onClick,
      }}>
      <EmailText numberOfLines={2}>{email ?? ''}</EmailText>
    </ImageAndContentContainer>
  );
};

const EmailText = styled(Text)({
  textAlign: 'center',
  color: appColors.black,
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 24,
  lineHeight: 27,
  marginVertical: 12,
  marginHorizontal: 24,
});
