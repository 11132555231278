import {Linking} from 'react-native';

import {
  Analytics,
  CustomCategory,
  CustomEventAction,
} from '../analytics/Analytics';
import {Constants} from '../constants';
import {appLog} from './Logger';

export const navigateToWebsite = async (page: string) => {
  if (await Linking.canOpenURL(Constants.WEBSITE_URL)) {
    const path = `${Constants.WEBSITE_URL}/${page}`;
    Analytics.shared().logCustomEventAction(
      CustomEventAction.LINK_CLICK,
      CustomCategory.OUTBOUND,
      path,
    );
    Linking.openURL(path);
  } else {
    appLog.error('cannot open url', {url: `${Constants.WEBSITE_URL}/${page}`});
  }
};
