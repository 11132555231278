import {addDays} from 'date-fns';
import React from 'react';
import {useDispatch} from 'react-redux';

import {backgroundV3ChamberScreen} from '../../assets/images';
import {
  Constants,
  Messages,
  Navigators,
  Screens,
  Strings,
} from '../../constants';
import {InterstitialScreen} from '../../lib/InterstitialManager';
import {useWindowHeight} from '../../lib/hooks';
import {navigateToWebsite} from '../../lib/navigateToWebsite';
import {updateSeen} from '../../lib/redux/interstitialScreenSlice';
import {ChamberType} from '../../lib/types';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {ImageAndContentContainer} from '../components';

const {SHOP_COLLECTION_PATH} = Constants;
const {V3_CHAMBER_SCREEN_MSG, V3_CHAMBER_SCREEN_TITLE} = Messages;

const v3ChamberScreenStartDate = new Date('2023-07-27');

export const v3ChamberInterstitial: InterstitialScreen = {
  screen: Screens.V3Chamber,
  conditionMet: ({initializeTime, lastSeenTime, hasChamberType}) => {
    // if not initialized or already seen
    if (!initializeTime || lastSeenTime) return false;
    // do not show if 3D or XL chamber installed
    if (hasChamberType([ChamberType.Performance, ChamberType.XL])) return false;
    // trigger after 30 days
    return new Date().getTime() >= addDays(initializeTime, 30).getTime();
  },
  // expire after 90 days from interstitial added
  expireDate: addDays(v3ChamberScreenStartDate, 90),
  autoInitialize: true,
};

interface Props
  extends HomeEmulatedDrawerStackScreenProps<typeof Screens.V3Chamber> {}

export const V3ChamberScreen = ({navigation}: Props) => {
  const dispatch = useDispatch();
  const {windowHeight} = useWindowHeight();

  return (
    <ImageAndContentContainer
      image={backgroundV3ChamberScreen}
      imageContainerStyle={{
        maxHeight: windowHeight / 2.5,
      }}
      header={{content: V3_CHAMBER_SCREEN_TITLE}}
      body={{
        content: V3_CHAMBER_SCREEN_MSG,
        style: {textAlign: 'left'},
      }}
      footerContainerStyle={{marginTop: 'auto'}}
      primaryButton={{
        title: Strings.SHOP_NOW,
        onClick: () => {
          dispatch(updateSeen({screen: Screens.V3Chamber}));
          navigateToWebsite(SHOP_COLLECTION_PATH);
        },
      }}
      secondaryButton={{
        title: Strings.MAYBE_LATER,
        onClick: () => {
          dispatch(updateSeen({screen: Screens.V3Chamber}));
          navigation.navigate(Navigators.HomeTabNavigator, {
            screen: Screens.Home,
          });
        },
      }}
    />
  );
};
