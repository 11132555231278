import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  Block,
  Document,
  INLINES,
  Inline,
  MARKS,
} from '@contentful/rich-text-types';
import {EntryFields} from 'contentful';
import React from 'react';
import {StyleSheet, Text, TextStyle, View} from 'react-native';

import {appColors} from '../../../constants';
import {resolveAssetUrl} from '../../../lib/api';
import {VideoItem} from './DeviceVideoCarousel/VideoItem';
import {Hyperlink} from './Hyperlink';
import {ScaledImage} from './ScaledImage';

export const isEmptyParagraph = (node: Block | Inline): boolean => {
  return (
    node.nodeType === BLOCKS.PARAGRAPH &&
    node.content.length === 1 &&
    node.content[0].nodeType === 'text' &&
    node.content[0].value === ''
  );
};

const defaultOptions: Options = {
  renderNode: {
    [BLOCKS.DOCUMENT]: (_node: Block | Inline, children: React.ReactNode) => {
      return typeof children === 'string' ? (
        <Text style={styles.text}>{children}</Text>
      ) : (
        <View style={styles.documentView}>{children}</View>
      );
    },
    [BLOCKS.UL_LIST]: (_node: Block | Inline, children: React.ReactNode) => {
      return <View style={styles.ulList}>{children}</View>;
    },
    [BLOCKS.LIST_ITEM]: (_node: Block | Inline, children: React.ReactNode) => {
      return (
        <View style={styles.listItemContainer}>
          <ListMarker />
          <Text>{children}</Text>
        </View>
      );
    },
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => {
      if (isEmptyParagraph(node)) {
        return <View style={styles.emptyParagraph} />;
      }

      return <Text style={styles.text}>{children}</Text>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
      if (node.data.target.sys.contentType.sys.id !== 'video') return;

      return (
        <VideoItem
          thumbnail={resolveAssetUrl(node.data.target.fields.thumbnailImage)}
          title={node.data.target.fields.title}
          link={node.data.target.fields.link}
        />
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      const uri = resolveAssetUrl(node.data.target);

      return <ScaledImage height={30} uri={uri} />;
    },
    [BLOCKS.HEADING_3]: (_node: Block | Inline, children: React.ReactNode) => {
      return <Text style={styles.heading3}>{children}</Text>;
    },
    [BLOCKS.HEADING_2]: (_node: Block | Inline, children: React.ReactNode) => {
      return <Text style={styles.heading2}>{children}</Text>;
    },
    [INLINES.HYPERLINK]: (node: Block | Inline, children: React.ReactNode) => {
      const {uri} = node.data;

      return (
        <Hyperlink appearance="primary" {...{uri}}>
          {children}
        </Hyperlink>
      );
    },
  },
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => (
      <Text style={styles.bold}>{text}</Text>
    ),
    [MARKS.ITALIC]: (text: React.ReactNode) => (
      <Text style={styles.italic}>{text}</Text>
    ),
    [MARKS.UNDERLINE]: (text: React.ReactNode) => (
      <Text style={styles.underline}>{text}</Text>
    ),
  },
};

interface RichTextRendererProps {
  content: EntryFields.RichText;
  customOptions?: Options;
}

export const RichTextRenderer: React.FC<RichTextRendererProps> = ({
  content,
  customOptions,
}) => {
  const renderNode = {
    ...defaultOptions.renderNode,
    ...customOptions?.renderNode,
  };

  const renderMark = {
    ...defaultOptions.renderMark,
    ...customOptions?.renderMark,
  };

  const options = {renderNode, renderMark};

  const document = {
    ...content,
    content: content.content.filter(node => !!renderNode[node.nodeType]),
  };

  return (
    content && (
      <View>{documentToReactComponents(document as Document, options)}</View>
    )
  );
};

export const ListMarker = ({style}: {style?: TextStyle}) => (
  <Text style={[styles.bulletPoint, style]}>•</Text>
);

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    alignSelf: 'flex-start',
    color: appColors.textOnSurface,
    lineHeight: 26,
  },
  documentView: {
    display: 'flex',
    flexDirection: 'column',
  },
  ulList: {
    paddingLeft: 8,
    paddingTop: 12,
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  bulletPoint: {
    marginRight: 12,
    color: appColors.textOnSurface,
    fontSize: 16,
    lineHeight: 22,
  },
  emptyParagraph: {
    height: 12,
  },
  heading3: {
    color: appColors.white,
    fontSize: 16,
  },
  heading2: {
    color: appColors.white,
    fontSize: 18,
  },
  bold: {
    fontWeight: '700',
  },
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    textDecorationLine: 'underline',
  },
});
