import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Text, View} from 'react-native';
import {useSharedValue} from 'react-native-reanimated';
import {useSelector} from 'react-redux';

import {stopwatch, thermometerDark} from '../../assets/images';
import {
  AppText,
  BlurredBackgroundSafeArea,
  CloseButton,
  FatVerticalSlider,
} from '../../components';
import {
  Constants,
  Messages,
  NavTextButtonTitleStyle,
  Screens,
  appColors,
  fillStyle,
} from '../../constants';
import {useAppDispatch, useBackPress} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {appSettingsSelector} from '../../lib/redux/appSettingsSlice';
import {
  connectedPeakSelector,
  currentDeviceSelector,
  updateDeviceSettings,
} from '../../lib/redux/bleSlice';
import styled from '../../lib/styled';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {useTheme} from '../../lib/useTheme';
import {createInterpolate} from '../../lib/utilityFunctions/createInterpolate';
import {Temperature} from '../../lib/utils/temperature';
import {ControlCenterNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {desertTheme} from '../../themes';
import {InfoText} from './components/InfoText';

const SLIDER_HEIGHT = 300;
const SLIDER_WIDTH = 70;

const durationInterpolate = createInterpolate(
  [Constants.BOOST_DURATION_MIN, Constants.BOOST_DURATION_MAX],
  [0, 1],
);

interface Props
  extends ControlCenterNavigatorScreenProps<
    typeof Screens.ControlCenterBoost
  > {}

export const ControlCenterBoostScreen = ({navigation}: Props) => {
  useDisconnectGuard();
  useAdaptiveSafeArea();

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const {
    controlCenterTheme: {
      backgroundColor,
      boostScreenLabel,
      fatSliderBGColor,
      fatSliderColor,
    },
    navMenuIconColor,
    navMenuTitleStyle,
  } = theme;

  const appSettings = useSelector(appSettingsSelector);
  const peak = useSelector(connectedPeakSelector);
  const currentDevice = useSelector(currentDeviceSelector);

  const prefersCelsius = appSettings.tempPreference === TemperatureUnit.Celsius;

  const defaultTemperature = prefersCelsius
    ? Constants.DEFAULT_BOOST_TEMP_CELSIUS
    : Constants.DEFAULT_BOOST_TEMP_FAHRENHEIT;

  const temperatureInterpolate = React.useMemo(
    () =>
      createInterpolate(
        [
          prefersCelsius
            ? Constants.BOOST_TEMPERATURE_MIN_CELSIUS
            : Constants.BOOST_TEMPERATURE_MIN_FAHRENHEIT,
          prefersCelsius
            ? Constants.BOOST_TEMPERATURE_MAX_CELSIUS
            : Constants.BOOST_TEMPERATURE_MAX_FAHRENHEIT,
        ],
        [0, 1],
      ),
    [prefersCelsius],
  );

  // Device temperature is always in Celsius degrees
  const temperature = useSharedValue(
    temperatureInterpolate.from(
      currentDevice?.settings?.boostTemp !== undefined &&
        currentDevice?.settings?.boostTemp >= 0
        ? Math.round(
            Temperature.convertDifference(currentDevice?.settings?.boostTemp, {
              from: TemperatureUnit.Celsius,
              to: appSettings.tempPreference,
            }),
          )
        : defaultTemperature,
    ),
  );

  const duration = useSharedValue(
    durationInterpolate.from(
      currentDevice?.settings?.boostDuration !== undefined &&
        currentDevice?.settings?.boostDuration >= 0
        ? Math.floor(currentDevice?.settings?.boostDuration)
        : Constants.DEFAULT_BOOST_DURATION,
    ),
  );

  // We need this to trigger rerender when sliders change
  const [_, setDate] = React.useState<Date>();

  const isDesert = theme === desertTheme;

  const updateTemperature = React.useCallback(
    async (temperature: number) => {
      if (!currentDevice?.id) return;

      const boostTemp = Temperature.convertDifference(temperature, {
        from: appSettings.tempPreference,
        to: TemperatureUnit.Celsius,
      });

      dispatch(
        updateDeviceSettings({
          id: currentDevice.id,
          settings: {boostTemp},
        }),
      );

      await peak?.writeBoostTemperature(boostTemp);
    },
    [dispatch, peak, appSettings.tempPreference, currentDevice?.id],
  );

  const updateDuration = React.useCallback(
    async (duration: number) => {
      if (!currentDevice?.id) return;

      dispatch(
        updateDeviceSettings({
          id: currentDevice.id,
          settings: {boostDuration: duration},
        }),
      );

      await peak?.writeBoostDuration(duration);
    },
    [dispatch, peak, currentDevice?.id],
  );

  React.useEffect(() => {
    navigation.setParams({
      titleStyle: {
        ...navMenuTitleStyle,
        ...(isDesert && {color: appColors.white}),
      },
      iconColor: isDesert ? appColors.white : navMenuIconColor,
    });
  }, []);

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: 'BOOST MODE',
      headerTitleStyle: {
        ...navMenuTitleStyle,
        ...(isDesert && {color: appColors.white}),
      },
      headerLeft: () => (
        <HeaderTextButton
          onPress={async () => {
            temperature.value = temperatureInterpolate.from(defaultTemperature);
            await updateTemperature(defaultTemperature);

            duration.value = durationInterpolate.from(
              Constants.DEFAULT_BOOST_DURATION,
            );
            await updateDuration(Constants.DEFAULT_BOOST_DURATION);
          }}
          style={{color: isDesert ? appColors.white : navMenuIconColor}}>
          Reset
        </HeaderTextButton>
      ),
      headerRight: () => (
        <CloseButton
          onPress={() => navigation.replace(Screens.ControlCenter)}
          iconStyle={{tintColor: isDesert ? appColors.white : navMenuIconColor}}
        />
      ),
    });
  }, [
    navigation,
    navMenuTitleStyle,
    isDesert,
    navMenuIconColor,
    defaultTemperature,
    temperature,
    duration,
    updateTemperature,
    updateDuration,
  ]);

  useBackPress(
    React.useCallback(() => {
      navigation.replace(Screens.ControlCenter);
      return true;
    }, [navigation]),
  );

  return (
    <BlurredBackgroundSafeArea style={{...fillStyle, backgroundColor}}>
      <Container>
        <InfoLabel>{Messages.DEVICE_SETTINGS_BOOST}</InfoLabel>

        <SliderContainer>
          <LeftSideContainer>
            <TopSliderLabel style={{color: boostScreenLabel}}>
              +{Math.round(temperatureInterpolate.to(temperature.value))}°
              {prefersCelsius ? 'C' : 'F'}
            </TopSliderLabel>

            <FatVerticalSlider
              style={{
                width: SLIDER_WIDTH,
                height: SLIDER_HEIGHT,
                backgroundColor: fatSliderBGColor,
              }}
              color={fatSliderColor}
              iconSource={thermometerDark}
              value={temperature}
              onChange={() => setDate(new Date())}
              onEnd={value => {
                updateTemperature(Math.round(temperatureInterpolate.to(value)));
              }}
            />

            <BottomSliderLabel style={{color: boostScreenLabel}}>
              TEMPERATURE
            </BottomSliderLabel>
          </LeftSideContainer>

          <RightSideContainer>
            <TopSliderLabel style={{color: boostScreenLabel}}>
              +{Math.round(durationInterpolate.to(duration.value))}s
            </TopSliderLabel>

            <FatVerticalSlider
              style={{
                width: SLIDER_WIDTH,
                height: SLIDER_HEIGHT,
                backgroundColor: fatSliderBGColor,
              }}
              color={fatSliderColor}
              iconSource={stopwatch}
              value={duration}
              onChange={() => setDate(new Date())}
              onEnd={value => {
                updateDuration(Math.round(durationInterpolate.to(value)));
              }}
            />

            <BottomSliderLabel style={{color: boostScreenLabel}}>
              TIME
            </BottomSliderLabel>
          </RightSideContainer>
        </SliderContainer>
      </Container>
    </BlurredBackgroundSafeArea>
  );
};

const Container = styled(View)({
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
});

const SliderContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '65%',
  height: SLIDER_HEIGHT,
  alignSelf: 'center',
});

const LeftSideContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: SLIDER_WIDTH,
  justifyContent: 'center',
});

const RightSideContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  width: SLIDER_WIDTH,
  alignItems: 'center',
  justifyContent: 'center',
});

const InfoLabel = styled(InfoText)({
  width: '100%',
  marginTop: 60,
  marginBottom: 70,
});

const TopSliderLabel = styled(Text)({
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 22,
  marginBottom: 10,
});

const BottomSliderLabel = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  marginTop: 10,
  fontSize: 12,
});

const HeaderTextButton = styled(AppText)({
  ...NavTextButtonTitleStyle,
  marginHorizontal: 10,
});
