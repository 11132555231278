import {MoodLightCategory} from 'puffco-api-axios-client';
import React from 'react';
import {ImageSourcePropType, StyleSheet, View, ViewProps} from 'react-native';
import {useSelector} from 'react-redux';

import {peakBackgroundGlow} from '../assets/images';
import {Constants, appColors, centerStyle, fillStyle} from '../constants';
import {useMixColors, usePrefetchImages} from '../lib/hooks';
import {appSettingsSelector} from '../lib/redux/appSettingsSlice';
import {currentDeviceSelector} from '../lib/redux/bleSlice';
import {ChamberType, isExclusiveMoodLight} from '../lib/types';
import {
  DeviceModelType,
  MoodLight,
  MoodType,
  PeakImageLayerKey,
  PeakSection,
} from '../lib/types';
import {useTheme} from '../lib/useTheme';
import {
  calcTempoCpm,
  getDeviceModelType,
  getPeakImageColorOffsetsDictionary,
  getPeakImageOpacityOffsetsDictionary,
} from '../lib/utilityFunctions';
import {getProductName} from '../lib/utilityFunctions/getProductName';
import {Image} from '../shims/ImageWithFilter';
import {
  defaultTheme,
  desertTheme,
  flourishTheme,
  opalTheme,
  whitePeachTheme,
} from '../themes';
import {LoadingHalo} from './LoadingHalo';

interface Props extends ViewProps {
  colorSource: string | MoodLight;
  peakImageContainerProps?: ViewProps;
  haloProps?: React.ComponentProps<typeof LoadingHalo>;
  useDefaultTheme?: boolean;
  darkScreen?: boolean;
  shouldGlow?: boolean;
  chamberType?: ChamberType;
}

export const PeakImage = ({
  colorSource,
  peakImageContainerProps,
  haloProps,
  useDefaultTheme,
  darkScreen,
  chamberType,
  shouldGlow = false,
  ...rest
}: Props) => {
  const {enablePeakAnimation} = useSelector(appSettingsSelector);
  const {colors, moodType, tempo, colorsPerSecond} = React.useMemo(
    () => getValues(colorSource, enablePeakAnimation),
    [colorSource, enablePeakAnimation],
  );

  const transitionDuration = React.useMemo(
    () =>
      getPeakTransitionDuration(
        colors.length,
        tempo,
        moodType,
        colorsPerSecond,
      ),
    [colors.length, tempo, moodType, colorsPerSecond],
  );

  const currentTheme = useTheme();
  const isOpal = currentTheme === opalTheme;
  const isDesert = currentTheme === desertTheme;
  const isFlourish = currentTheme === flourishTheme;

  const {peakImageTheme} = useDefaultTheme ? defaultTheme : currentTheme;

  const [animatedColors, animatedNum] = useMixColors({
    colors,
    isAnimating: enablePeakAnimation,
    transitionDuration,
  });
  const isOpalAndDarkScreen = isOpal && darkScreen;

  const device = useSelector(currentDeviceSelector);

  const deviceModelType = useDefaultTheme
    ? DeviceModelType.OG
    : getDeviceModelType(device ? getProductName(device) : 'OG');

  const getOpacity = (
    source: PeakSection,
    peakImageLayerKey: PeakImageLayerKey,
    moodType: MoodType,
  ) => {
    switch (deviceModelType) {
      case DeviceModelType.LIGHTNING: {
        switch (moodType) {
          case MoodType.SPIN: {
            const opacityRange =
              0.5 +
              Math.sin(
                (animatedNum +
                  getPeakImageOpacityOffsetsDictionary(deviceModelType)[
                    moodType
                  ][source][peakImageLayerKey]) *
                  2 *
                  Math.PI,
              ) /
                2; // Opacity range of 0-1

            if (source === PeakSection.GLASS) {
              // Opacity range of 0.5 - 1
              return 0.5 + 0.5 * opacityRange;
            }
            // Opacity range of 0.1 - 0.5
            return 0.1 + 0.4 * opacityRange;
          }
          default: {
            if (source === PeakSection.GLASS) {
              return 1;
            }
            return 0.5;
          }
        }
      }
      default: {
        switch (moodType) {
          case MoodType.SPIN: {
            const opacityRange =
              0.5 +
              Math.sin(
                (animatedNum +
                  getPeakImageOpacityOffsetsDictionary(deviceModelType)[
                    moodType
                  ][source][peakImageLayerKey]) *
                  2 *
                  Math.PI,
              ) /
                2; // Opacity range of 0-1

            // Opacity range of 0.5 - 1
            return 0.5 + 0.5 * opacityRange;
          }
          default: {
            return 1;
          }
        }
      }
    }
  };

  const isLayeredPeakImage = !!peakImageTheme.peakLayers;

  const layers = React.useMemo(() => {
    if (!peakImageTheme.peakLayers) return [];

    const {glass, base} = peakImageTheme.peakLayers;

    return [
      {key: 'glass', value: glass},
      {key: 'base', value: base},
    ];
  }, [peakImageTheme.peakLayers]);

  const chamberAssets = React.useMemo(
    () =>
      peakImageTheme.peakLayers
        ? Object.values(peakImageTheme.peakLayers.chamber)
        : [],
    [peakImageTheme.peakLayers],
  );

  usePrefetchImages(chamberAssets);

  const chamber = React.useMemo(() => {
    if (!peakImageTheme.peakLayers) return undefined;

    const {chamber} = peakImageTheme.peakLayers;

    const chambers: Record<
      ChamberType,
      {key: string; value?: ImageSourcePropType}
    > = {
      [ChamberType.None]: {
        key: 'chamber-regular',
        value: chamber.regular,
      },
      [ChamberType.Classic]: {
        key: 'chamber-regular',
        value: chamber.regular,
      },
      [ChamberType.Performance]: {
        key: 'chamber-regular',
        value: chamber.regular,
      },
      [ChamberType.XL]: {
        key: 'chamber-xl',
        value: chamber.xl,
      },
    };

    if (!chamberType) return chambers[ChamberType.None];

    return chambers[chamberType];
  }, [chamberType, peakImageTheme.peakLayers]);

  return (
    <View {...rest}>
      {haloProps && <LoadingHalo {...haloProps} />}

      <View style={styles.container} {...peakImageContainerProps}>
        <PeakBackgroundGlow visible={shouldGlow} />

        {layers.length ? (
          <View style={styles.absolute}>
            {layers.map(({key, value: source}) => (
              <Image
                key={`layer-${key}`}
                style={[styles.absolute, styles.layer]}
                {...{source}}
              />
            ))}
          </View>
        ) : (
          <Image
            source={
              isOpalAndDarkScreen
                ? peakImageTheme.peakDarkScreen
                : peakImageTheme.peak
            }
            style={[styles.absolute, styles.layer]}
          />
        )}

        {colors &&
          colors[0] !== appColors.black &&
          !isOpalAndDarkScreen &&
          Object.entries(peakImageTheme.lightLayers)
            .map(([peakSection, value]) =>
              Object.entries(value).map(([peakImageLayerKey, source]) => {
                const tintColor =
                  animatedColors[
                    getPeakImageColorOffsetsDictionary(deviceModelType)[
                      moodType
                    ][peakSection as PeakSection]?.[
                      peakImageLayerKey as PeakImageLayerKey
                    ]?.[animatedColors.length]
                  ];

                const reduceOpacity = (value: number) => {
                  /* Workaround: Desert LE Skin opacity need to be decreased manually */
                  if (
                    (isDesert || isFlourish) &&
                    peakSection === PeakSection.GLASS
                  )
                    return value - 0.3;

                  if (
                    isLayeredPeakImage &&
                    currentTheme === whitePeachTheme &&
                    peakSection === PeakSection.GLASS
                  )
                    return value * 0.8;

                  return value;
                };

                const opacity = reduceOpacity(
                  getOpacity(
                    peakSection as PeakSection,
                    peakImageLayerKey as PeakImageLayerKey,
                    moodType,
                  ),
                );

                return (
                  source && (
                    <Image
                      key={`${peakSection}-${peakImageLayerKey}`}
                      source={source}
                      style={[
                        styles.absolute,
                        styles.layer,
                        {
                          tintColor,
                          opacity,
                        },
                      ]}
                    />
                  )
                );
              }),
            )
            .reduce((accumulator, image) => [...accumulator, ...image])}

        {chamber?.value && (
          <View style={styles.absolute}>
            <Image
              key={`layer-${chamber.key}`}
              style={[styles.absolute, styles.layer]}
              source={chamber.value}
            />
          </View>
        )}
      </View>
    </View>
  );
};

interface PeakBackgroundGlowProps {
  visible?: boolean;
}

const PeakBackgroundGlow = ({visible}: PeakBackgroundGlowProps) => {
  if (!visible) return null;

  return (
    <Image
      source={peakBackgroundGlow}
      style={{
        position: 'absolute',
        ...fillStyle,
        // Calculate scale factor by dividing glow image height by peak image height
        transform: [{scale: 700 / 480}],
      }}
      resizeMode="contain"
    />
  );
};

const getValues = (
  colorSource: string | MoodLight,
  enablePeakAnimation: boolean,
) => {
  let colors: string[];
  let moodType = MoodType.NO_ANIMATION;
  let tempo: number | undefined;
  let colorsPerSecond: number | undefined;
  if (typeof colorSource === 'string') {
    colors = [colorSource];
  } else if (colorSource.category === MoodLightCategory.Custom) {
    colors = colorSource.colors;
    moodType = enablePeakAnimation ? colorSource.type : MoodType.NO_ANIMATION;
    tempo = colorSource.tempo;
  } else if (isExclusiveMoodLight(colorSource)) {
    colors = colorSource.colors;
    const {colorArray, tableColor} = colorSource.rawMoodLight;
    if (enablePeakAnimation) {
      switch (colorSource.name.toUpperCase()) {
        case 'DISCO':
          moodType = MoodType.DISCO;
          break;
        default:
          moodType = MoodType.DISCO;
          break;
      }
    }
    colorsPerSecond =
      ((Constants.LED_API_2_ASYNC_LSB_CPS * tableColor.speed) /
        (colorArray.length / 4)) *
      colors.length;
  } else {
    // Default appearance for undefined exclusives
    colors = [appColors.defaultColor];
  }
  return {colors, moodType, tempo, colorsPerSecond};
};

const getPeakTransitionDuration = (
  numberOfColors: number,
  tempo?: number,
  moodType?: MoodType,
  colorsPerSecond?: number,
) => {
  if (
    numberOfColors === 1 ||
    (!tempo && colorsPerSecond === undefined) ||
    moodType === undefined ||
    moodType === MoodType.NO_ANIMATION
  ) {
    return Number.POSITIVE_INFINITY;
  }
  if (tempo) {
    const tempoCpm = calcTempoCpm(tempo);
    return 60000 / tempoCpm; // 60000 milliseconds per minute
  } else if (colorsPerSecond !== undefined) {
    return 1000 / colorsPerSecond; // 1000 milliseconds per second
  }
  return Number.POSITIVE_INFINITY;
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    ...centerStyle,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  layer: {
    resizeMode: 'contain',
  },
});
