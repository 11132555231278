import {Strings} from '../../constants';
import {Profile, Vapor, isPreTHeatProfile} from '../types';
import {useChamberType} from './';

export function useGetVaporSettingText(profile?: Profile): Vapor {
  const {isXLChamber} = useChamberType();
  const standard = Strings.STANDARD.toUpperCase() as Vapor;
  const max = Strings.MAX.toUpperCase() as Vapor;
  const high = Strings.HIGH.toUpperCase() as Vapor;
  const xl = Strings.XL.toUpperCase() as Vapor;

  if (!profile || isPreTHeatProfile(profile)) return standard;

  const {vaporSetting} = profile;
  if (!vaporSetting) return standard;

  if (vaporSetting === 1.5 && isXLChamber) return xl;
  if (vaporSetting === 1.5 && !isXLChamber) return max;
  if (vaporSetting >= 1.0) return max;
  if (vaporSetting >= 0.5) return high;
  return standard;
}
