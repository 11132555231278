import React from 'react';
import {
  Image,
  Linking,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {cleaningInstructionsThumbnail} from '../../assets/images';
import {base64 as PeakProCleaningInstructionsBase64} from '../../assets/pdf/PeakPro_Cleaning_Instructions_base64.json';
import {ImgBackground, ProgressBar, StyledIcon} from '../../components';
import {Constants, Messages, Screens, appColors} from '../../constants';
import {useWatchDevice} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  currentDeviceSelector,
  updateDeviceAppSettings,
} from '../../lib/redux/bleSlice';
import styled from '../../lib/styled';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';

const source = {
  uri: `data:application/pdf;base64,${PeakProCleaningInstructionsBase64}`,
};

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.CleaningReminder
>;

export const CleaningReminderScreen = ({navigation}: ScreenProps) => {
  useAdaptiveSafeArea();
  const dispatch = useDispatch();

  const device = useSelector(currentDeviceSelector);
  const deviceHits = useWatchDevice('hits');

  const currentDabs =
    device &&
    deviceHits &&
    device.appSettings &&
    device.appSettings.lastCleanReminderDabCount
      ? deviceHits - device.appSettings.lastCleanReminderDabCount
      : 0;
  const getProgressBarColor = () => {
    if (!device || !device.appSettings) return '';
    const cleaningReminderDabsCount =
      device.appSettings.cleaningReminderDabs || 100;

    const remainPct =
      (cleaningReminderDabsCount - currentDabs) / cleaningReminderDabsCount;
    if (remainPct >= 0.26) {
      return appColors.progressGreen;
    } else if (remainPct >= 0.1) {
      return appColors.progressYellow;
    } else {
      return appColors.progressRed;
    }
  };

  const handleResetReminder = () => {
    if (!device || !deviceHits) return;

    // just update redux
    dispatch(
      updateDeviceAppSettings({
        id: device.id,
        lastCleanReminderDabCount: deviceHits,
        delay: 0,
      }),
    );
  };

  const calculateProgressWidth = (): ViewStyle['width'] => {
    if (
      !device?.appSettings ||
      !device?.appSettings.cleaningReminderDabs ||
      device?.appSettings.cleaningReminderDabs === 0
    )
      return '0%';
    return currentDabs <= device?.appSettings.cleaningReminderDabs &&
      !!device?.appSettings.cleaningReminderDabs
      ? `${
          (currentDabs * 100) / device?.appSettings.cleaningReminderDabs || 0
        }%`
      : '100%';
  };

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1, backgroundColor: 'black'}}>
        <ScrollView
          style={{flex: 1, marginTop: 64, marginBottom: 14}}
          contentContainerStyle={{
            height: Constants.IS_WEB ? 0 : undefined,
          }}>
          <SectionHeaderContainer>
            <SectionHeaderText>
              {Messages.REMINDER_SECTION_HEADER_TITLE}
            </SectionHeaderText>
          </SectionHeaderContainer>

          <SectionContainer>
            <SectionItemTouchable
              style={{
                flexDirection: 'column',
                height: 101,
                justifyContent: 'flex-start',
              }}
              onPress={() => {
                // navigate reminder frequency screen
                navigation.navigate(Screens.ReminderFrequency);
              }}>
              <SectionItem
                style={{
                  width: '100%',
                  borderBottomWidth: 0,
                  paddingLeft: 0,
                }}>
                <SectionHeaderWrapper>
                  <SectionHeader>
                    {Messages.CLEANING_REMINDER_HEADER_TITLE}
                  </SectionHeader>
                </SectionHeaderWrapper>
                <FlexRowContainer>
                  <AboutSectionInfoLink>
                    {device?.appSettings?.cleaningReminderDabs &&
                    device?.appSettings.cleaningReminderDabs > 0
                      ? `${device?.appSettings.cleaningReminderDabs} dabs`
                      : 'Off'}
                  </AboutSectionInfoLink>

                  <StyledArrowIcon name={'chevronRight'} size={12} />
                </FlexRowContainer>
              </SectionItem>
              <ProgressBar
                width={calculateProgressWidth()}
                innerStyle={{backgroundColor: getProgressBarColor()}}
                textStyle={{
                  color: appColors.gray,
                  marginTop: 6,
                  fontSize: 12,
                  fontFamily: 'Roboto-Regular',
                  fontWeight: '400',
                  letterSpacing: 0.37,
                }}
                text={
                  device?.appSettings &&
                  device?.appSettings.cleaningReminderDabs !== 0
                    ? `${currentDabs} of ${device.appSettings.cleaningReminderDabs}`
                    : Messages.CLEANING_REMINDER_OFF_TEXT
                }
              />
            </SectionItemTouchable>

            <SectionItemTouchable
              onPress={() => {
                // navigate snooze screen
                navigation.navigate(Screens.SnoozeFrequency);
              }}>
              <SectionHeaderWrapper>
                <SectionHeader>
                  {Messages.SNOOZE_SECTION_HEADER_TITLE}
                </SectionHeader>
              </SectionHeaderWrapper>
              <FlexRowContainer>
                <AboutSectionInfoLink>{`${
                  device?.appSettings?.snoozeDabs
                    ? device?.appSettings.snoozeDabs
                    : 10
                } dabs`}</AboutSectionInfoLink>
                <StyledArrowIcon name={'chevronRight'} size={12} />
              </FlexRowContainer>
            </SectionItemTouchable>
            <SectionItemTouchable
              style={{borderBottomWidth: 0}}
              onPress={handleResetReminder}>
              <SectionHeaderWrapper>
                <SectionHeader>{Messages.RESET_REMINDER_TITLE}</SectionHeader>
              </SectionHeaderWrapper>
              <StyledCircularIcon name={'circular'} size={16} />
            </SectionItemTouchable>
          </SectionContainer>
          <SectionHeaderContainer style={{marginTop: 12}}>
            <SectionHeaderText>
              {Messages.CLEANING_INSTRUCTIONS_HEADER_TITLE}
            </SectionHeaderText>
          </SectionHeaderContainer>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL('https://www.youtube.com/watch?v=wIi3bht8em4')
            }>
            <View style={{marginHorizontal: 24}}>
              <Image
                source={cleaningInstructionsThumbnail}
                style={{
                  marginVertical: 10,
                  width: '100%',
                  resizeMode: 'contain',
                }}
              />
            </View>
          </TouchableOpacity>

          <SectionContainer>
            <SectionItemTouchable
              style={{borderBottomWidth: 0}}
              onPress={() => {
                if (Constants.IS_WEB) {
                  Linking.openURL(Constants.CLEANING_INSTRUCTIONS_URL);
                } else {
                  navigation.navigate(Screens.CleaningManual, {
                    source,
                  });
                }
              }}>
              <SectionHeaderWrapper>
                <SectionHeader>{Messages.DOWNLOAD_CLEANING_PDF}</SectionHeader>
              </SectionHeaderWrapper>
              <StyledArrowIcon name={'chevronRight'} size={12} />
            </SectionItemTouchable>
          </SectionContainer>
        </ScrollView>
      </SafeAreaView>
    </ImgBackground>
  );
};

const SectionHeaderText = styled(Text)({
  color: appColors.mediumGray50,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 12,
  marginLeft: 24,
  marginTop: 18,
  lineHeight: 12,
  textTransform: 'uppercase',
});

const SectionHeaderContainer = styled(View)({
  height: 30,
});

const SectionContainer = styled(View)({
  flexDirection: 'column',
  marginTop: 8,
  backgroundColor: appColors.coal,
  marginHorizontal: 24,
  borderRadius: 6,
});

const SectionItem = styled(View)({
  borderBottomWidth: 1,
  borderColor: appColors.greySettings20,
  flexDirection: 'row',
  height: 51,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 16,
});

const SectionItemTouchable = styled(TouchableOpacity)({
  borderBottomWidth: 1,
  borderColor: appColors.greySettings20,
  flexDirection: 'row',
  height: 51,
  justifyContent: 'space-between',
  paddingLeft: 16,
});

const SectionHeader = styled(Text)({
  alignSelf: 'center',
  color: appColors.baseText,
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  textAlign: 'left',
  fontWeight: '400',
});

const SectionHeaderWrapper = styled(View)({
  flexDirection: 'row',
});

const AboutSectionInfoLink = styled(Text)({
  alignSelf: 'center',
  color: appColors.gray,
  fontFamily: 'Roboto-Regular',
  fontSize: 13,
  marginRight: -6,
  fontWeight: '400',
});

const StyledArrowIcon = styled(StyledIcon)({
  alignSelf: 'center',
});

const StyledCircularIcon = styled(StyledIcon)({
  alignSelf: 'center',
});

const FlexRowContainer = styled(View)({
  flexDirection: 'row',
});
