import React from 'react';
import {
  ImageSourcePropType,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';

import {ImgBackground} from '../../components';
import {Constants, appColors} from '../../constants';
import styled from '../../lib/styled';

const {IS_USING_DESKTOP_WEB} = Constants;

export type Props = {
  background: ImageSourcePropType;
  title: string;
  body: string;
  onButtonClick: () => void;
  buttonTitle?: string;
};

export const DesktopConfirmationScreen = ({
  background,
  title,
  body,
  onButtonClick,
  buttonTitle,
  children,
}: React.PropsWithChildren<Props>) => {
  const {height} = useWindowDimensions();

  const getMarginBottom = (height: number) => {
    if (IS_USING_DESKTOP_WEB) {
      if (height < 1020) {
        return '6%';
      } else if (height < 940) {
        return '4%';
      } else if (height < 860) {
        return '1%';
      } else {
        return '7%';
      }
    } else {
      return 125;
    }
  };

  const getButtonViewHeight = (height: number) => {
    if (IS_USING_DESKTOP_WEB) {
      if (height < 940) {
        return 100;
      } else {
        return '100%';
      }
    } else {
      return '100%';
    }
  };

  const Button = styled(TouchableOpacity)({
    backgroundColor: appColors.baseText,
    borderRadius: 25,
    height: 50,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginHorizontal: IS_USING_DESKTOP_WEB ? '40%' : 48,
    marginBottom: getMarginBottom(height),
    letterSpacing: 0.75,
  });

  return (
    <ImgBackground source={background}>
      <Container>
        <InnerContainer>
          <Header>{title}</Header>
          <Body>{body}</Body>
          {children}
          <View style={{height: getButtonViewHeight(height), marginBottom: 0}}>
            <Button onPress={onButtonClick}>
              <ButtonText>{buttonTitle || 'Got it!'}</ButtonText>
            </Button>
          </View>
        </InnerContainer>
      </Container>
    </ImgBackground>
  );
};

const Container = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  height: '100%',
});

const InnerContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const Header = styled(Text)({
  textAlign: 'center',
  color: appColors.baseText,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: IS_USING_DESKTOP_WEB ? 60 : 32,
  fontWeight: '100',
  textTransform: 'uppercase',
  letterSpacing: 0.75,
  marginBottom: IS_USING_DESKTOP_WEB ? 26 : 10,
});

const Body = styled(Text)({
  textAlign: 'center',
  color: appColors.baseText,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: IS_USING_DESKTOP_WEB ? 22 : 16,
  marginHorizontal: IS_USING_DESKTOP_WEB ? '37%' : 48,
  letterSpacing: 0.25,
  lineHeight: 32,
});

export const ButtonText = styled(Text)({
  textAlign: 'center',
  color: appColors.black,
  fontFamily: 'Roboto-Medium',
  fontWeight: '400',
  fontSize: 16,
  textTransform: 'uppercase',
  letterSpacing: 0.75,
});
