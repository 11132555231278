import {format} from 'date-fns';
import React from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from 'react-redux';

import {
  CustomSwitch,
  ImgBackground,
  Modal,
  PeakNameAlertPrompt,
  StyledIcon,
} from '../../components';
import {
  Alerts,
  Constants,
  Navigators,
  Screens,
  appColors,
  Messages as m,
} from '../../constants';
import {updateUser} from '../../lib/api';
import {useAppDispatch, useWatchDevice} from '../../lib/hooks';
import {
  appSettingsSelector,
  updateAppSettings,
} from '../../lib/redux/appSettingsSlice';
import {
  bleConnectionStatusSelector,
  connectedPeakSelector,
  currentDeviceSelector,
  isOtaAvailableSelector,
} from '../../lib/redux/bleSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {getProductName} from '../../lib/utilityFunctions/getProductName';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {RedirectionParameter} from '../../navigation/navigators/params';
import {appVersion} from '../../shims/AppVersion';
import {Badge} from '../../shims/Badge';
import {
  exitFullscreen,
  getfullscreenElement,
  requestFullscreen,
  supportsFullscreen,
} from '../../shims/Fullscreen';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {Alert} from '../../shims/alert';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.Preferences
>;

export const PreferencesScreen = ({navigation}: ScreenProps) => {
  const dispatch = useAppDispatch();

  const [isFullScreen, setIsFullScreen] = React.useState(
    () => !!getfullscreenElement(),
  );

  const {enablePeakAnimation, tempPreference, batteryPreservationOn} =
    useSelector(appSettingsSelector);
  const peak = useSelector(connectedPeakSelector);
  const device = useSelector(currentDeviceSelector);
  const deviceHits = useWatchDevice('hits');
  const user = useSelector(userSelector);
  const connected = useSelector(bleConnectionStatusSelector);
  const isOtaAvailable = useSelector(isOtaAvailableSelector);

  const [pushNotification, setPushNotification] = React.useState(
    user?.pushNotifications ?? false,
  );

  const currentDabs =
    deviceHits && device?.appSettings?.lastCleanReminderDabCount
      ? deviceHits - (device.appSettings.lastCleanReminderDabCount ?? 0)
      : 0;

  const onBatteryPreservationChange = async (enabled: boolean) => {
    const supported = meetsMinimumFirmware(
      device?.softwareRevisionString,
      Constants.MINIMUM_FIRMWARE_VERSION.BATTERY_PRESERVATION,
    );

    if (!supported) {
      return Alert.alert(Alerts.UPDATE_FIRMWARE_MESSAGE, undefined, [
        {
          text: Alerts.START_UPDATE_FIRMWARE_MESSAGE,
          onPress: () => navigation.navigate(Screens.FirmwareInfo),
        },
        {text: Alerts.UPDATE_FIRMWARE_LATER_MESSAGE},
      ]);
    }

    await peak?.updateBatterySaver(enabled);

    dispatch(updateAppSettings({batteryPreservationOn: enabled}));

    if (user) updateUser({batteryPreservation: enabled});
  };

  const onPushNotificationChange = (enabled: boolean) => {
    if (user) {
      updateUser({pushNotifications: enabled});
      return setPushNotification(enabled);
    }

    navigation.navigate(Screens.Login, {
      redirect: new RedirectionParameter(Navigators.MainNavigator, {
        screen: Navigators.HomeDrawerNavigator,
        params: {
          screen: Navigators.HomeEmulatedDrawer,
          params: {
            screen: Screens.Preferences,
          },
        },
      }).encode(),
    });
  };

  const onPeakAnimationChange = (enabled: boolean) => {
    if (user) updateUser({peakAnimation: enabled});

    dispatch(updateAppSettings({enablePeakAnimation: enabled}));
  };

  const onFullscreenChange = (enabled: boolean) => {
    if (enabled) requestFullscreen();
    else exitFullscreen();

    setIsFullScreen(!!getfullscreenElement());
  };

  const displayName = React.useMemo(() => {
    const product = device ? getProductName(device) : 'OG';

    return product === 'OG' ? 'Peak Pro' : product;
  }, [device]);

  return (
    <ImgBackground>
      <SafeArea>
        <Scroller>
          <SectionHeaderContainer>
            <SectionHeaderText>
              {m.DEVICE_SECTION_HEADER_TITLE}
            </SectionHeaderText>
          </SectionHeaderContainer>

          <SectionContainer>
            <SectionItem>
              <SectionHeader>{m.MODEL_SECTION_TITLE}</SectionHeader>
              <AboutSectionInfo>{displayName}</AboutSectionInfo>
            </SectionItem>

            <SectionItemTouchable
              onPress={() => {
                if (connected)
                  return Modal.display({element: <PeakNameAlertPrompt />});

                Alert.alert('Not Connected', 'Connect to a Peak to name it.');
              }}>
              <SectionHeader>{m.DEVICE_NAME_SECTION_TITLE}</SectionHeader>

              <FlexRowContainer>
                <AboutSectionInfoLink>
                  {device && device.name}
                </AboutSectionInfoLink>

                <StyledIcon
                  name="chevronRight"
                  size={12}
                  style={{alignSelf: 'center'}}
                />
              </FlexRowContainer>
            </SectionItemTouchable>

            <SectionItemTouchable
              onPress={() => navigation.navigate(Screens.FirmwareInfo)}>
              <SectionHeaderWrapper>
                <SectionHeader>{m.FIRMWARE_SECTION_TITLE}</SectionHeader>

                {!!isOtaAvailable && (
                  <Badge style={{marginLeft: 6}}>
                    <BadgeText>{1}</BadgeText>
                  </Badge>
                )}
              </SectionHeaderWrapper>

              <FlexRowContainer>
                <AboutSectionInfoLink>
                  {device?.softwareRevisionString + '-' + device?.gitHashString}
                </AboutSectionInfoLink>

                <StyledArrowIcon name="chevronRight" size={12} />
              </FlexRowContainer>
            </SectionItemTouchable>

            <SectionItem>
              <SectionHeader>{m.SERIAL_NUMBER_SECTION_TITLE}</SectionHeader>

              <AboutSectionInfo>{device?.serialNumberString}</AboutSectionInfo>
            </SectionItem>

            <SectionItem>
              <View>
                <SectionHeader>{m.DOB_SECTION_TITLE}</SectionHeader>
              </View>

              <AboutSectionInfo>
                {!!device?.dob && format(new Date(device.dob), 'MM/dd/yyyy')}
              </AboutSectionInfo>
            </SectionItem>

            <SectionItem>
              <SectionHeader>{m.SESSION_COUNT_SECTION_TITLE}</SectionHeader>

              <AboutSectionInfo>{device && deviceHits}</AboutSectionInfo>
            </SectionItem>

            <SectionItemTouchable
              onPress={() => navigation.navigate(Screens.CleaningReminder)}>
              <SectionHeader>
                {m.CLEANING_REMINDER_NAVIGATION_TITLE}
              </SectionHeader>

              <FlexRowContainer>
                <AboutSectionInfoLink>
                  {!!device?.appSettings?.cleaningReminderDabs &&
                  device.appSettings.cleaningReminderDabs !== 0
                    ? `${currentDabs} / ${device.appSettings.cleaningReminderDabs}`
                    : ''}
                </AboutSectionInfoLink>

                <StyledIcon
                  name="chevronRight"
                  size={12}
                  style={{alignSelf: 'center'}}
                />
              </FlexRowContainer>
            </SectionItemTouchable>

            <SectionItem style={{borderBottomWidth: 0, height: 67}}>
              <View>
                <SectionGroupHeader>
                  {m.BATTERY_PRESERVATION_SECTION_TITLE}
                </SectionGroupHeader>

                <SectionSubHeader>
                  {m.BATTERY_PRESERVATION_SUBTITLE}
                </SectionSubHeader>
              </View>

              <CustomSwitch
                value={batteryPreservationOn}
                onValueChange={onBatteryPreservationChange}
                style={{marginRight: 18}}
                rightBackgroundColor={appColors.ash}
                leftBackgroundColor={appColors.switchGreen}
              />
            </SectionItem>
          </SectionContainer>

          <SectionHeaderContainer style={{marginTop: 35}}>
            <SectionHeaderText>
              {m.APPLICATION_SECTION_HEADER_TITLE}
            </SectionHeaderText>
          </SectionHeaderContainer>

          <SectionContainer>
            {!!supportsFullscreen() && (
              <SectionItem>
                <SectionHeader>{m.FULLSCREEN}</SectionHeader>

                <CustomSwitch
                  value={isFullScreen}
                  onValueChange={onFullscreenChange}
                  style={{marginRight: 18}}
                  rightBackgroundColor={appColors.ash}
                  leftBackgroundColor={appColors.switchGreen}
                />
              </SectionItem>
            )}

            <SectionItem>
              <SectionHeader>
                {m.PUSH_NOTIFICATIONS_SECTION_TITLE}
              </SectionHeader>

              <CustomSwitch
                value={!!user && !!pushNotification}
                onValueChange={onPushNotificationChange}
                style={{marginRight: 18}}
                rightBackgroundColor={appColors.ash}
                leftBackgroundColor={appColors.switchGreen}
              />
            </SectionItem>

            <SectionItemTouchable
              onPress={() => navigation.navigate(Screens.TemperatureSettings)}>
              <SectionHeader>{m.TEMPERATURE_SECTION_TITLE}</SectionHeader>

              <FlexRowContainer>
                <AboutSectionInfoLink>{tempPreference[0]}</AboutSectionInfoLink>

                <StyledArrowIcon name="chevronRight" size={12} />
              </FlexRowContainer>
            </SectionItemTouchable>

            <SectionItemTouchable
              onPress={() => navigation.navigate(Screens.PrivacyPolicy, {})}>
              <SectionHeader>{m.PRIVACY_SECTION_TITLE}</SectionHeader>

              <StyledArrowIcon name="chevronRight" size={12} />
            </SectionItemTouchable>

            <SectionItemTouchable
              onPress={() => navigation.navigate(Screens.TermsConditions, {})}>
              <SectionHeader>{m.TOS_SECTION_TITLE}</SectionHeader>

              <StyledArrowIcon name="chevronRight" size={12} />
            </SectionItemTouchable>

            <SectionItem>
              <SectionHeader>{m.VERSION_SECTION_TITLE}</SectionHeader>

              <AboutSectionInfo>{appVersion}</AboutSectionInfo>
            </SectionItem>

            <SectionItem>
              <SectionHeader>
                {m.ENABLE_PEAK_ANIMATION_SECTION_TITLE}
              </SectionHeader>

              <CustomSwitch
                value={enablePeakAnimation}
                onValueChange={onPeakAnimationChange}
                style={{marginRight: 18}}
                rightBackgroundColor={appColors.ash}
                leftBackgroundColor={appColors.switchGreen}
              />
            </SectionItem>

            <SectionItemTouchable
              style={{borderBottomWidth: 0}}
              onPress={() => navigation.navigate(Screens.FactoryReset)}>
              <SectionHeaderWrapper>
                <SectionHeader>{m.FACTORY_RESET_TITLE}</SectionHeader>
              </SectionHeaderWrapper>

              <StyledArrowIcon name="chevronRight" size={12} />
            </SectionItemTouchable>
          </SectionContainer>
        </Scroller>
      </SafeArea>
    </ImgBackground>
  );
};

const SafeArea = styled(SafeAreaView)({
  flex: 1,
  flexBasis: 0,
  backgroundColor: appColors.black,
});

const Scroller = styled(ScrollView)({
  flex: 1,
  marginTop: 64,
  marginBottom: 14,
});

const SectionHeaderText = styled(Text)({
  color: appColors.mediumGray50,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
  marginLeft: 24,
  lineHeight: 12,
  textTransform: 'uppercase',
  letterSpacing: 0.75,
});

const SectionHeaderContainer = styled(View)({
  marginBottom: 8,
});

const SectionContainer = styled(View)({
  flexDirection: 'column',
  marginTop: 8,
  backgroundColor: appColors.coal,
  marginHorizontal: 24,
  borderRadius: 6,
});

const SectionItem = styled(View)({
  borderBottomWidth: 1,
  borderColor: appColors.white10,
  flexDirection: 'row',
  height: 51,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 16,
});

const SectionItemTouchable = styled(TouchableOpacity)({
  borderBottomWidth: 1,
  borderColor: appColors.white10,
  flexDirection: 'row',
  height: 51,
  justifyContent: 'space-between',
  paddingLeft: 16,
});

const SectionHeader = styled(Text)({
  alignSelf: 'center',
  color: appColors.baseText,
  fontFamily: 'Roboto-Medium',
  fontSize: 14,
  textAlign: 'left',
  fontWeight: '400',
});

const SectionSubHeader = styled(Text)({
  fontSize: 12,
  color: appColors.gray,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
});

const SectionGroupHeader = styled(SectionHeader)({
  alignSelf: 'flex-start',
});

const SectionHeaderWrapper = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
});

const AboutSectionInfo = styled(Text)({
  alignSelf: 'center',
  color: appColors.gray,
  fontFamily: 'Roboto-Regular',
  fontSize: 13,
  marginRight: 18,
  fontWeight: '400',
});

const AboutSectionInfoLink = styled(Text)({
  alignSelf: 'center',
  color: appColors.gray,
  fontFamily: 'Roboto-Regular',
  fontSize: 13,
  marginRight: -6,
  fontWeight: '400',
});

const StyledArrowIcon = styled(StyledIcon)({
  alignSelf: 'center',
});

const FlexRowContainer = styled(View)({
  flexDirection: 'row',
});

const BadgeText = styled(Text)({
  color: appColors.white,
  fontFamily: 'Roboto-Bold',
  textTransform: 'uppercase',
  fontSize: 12,
});
