import {convertHexToRgbArray} from '../convertHexToRgbArray';
import {convertRgbArrayToHex} from '../convertRgbArrayToHex';

type mixColorProps = {
  colorA: string;
  colorB: string;
  percentage?: number; // Percentage mix from colorA to colorB
};

/* These functions only accept hex color strings */

export const mixColor = ({colorA, colorB, percentage = 0.5}: mixColorProps) => {
  const [aR, aG, aB] = convertHexToRgbArray(colorA);
  const [bR, bG, bB] = convertHexToRgbArray(colorB);
  const rgbArr = [
    Math.round((bR - aR) * percentage + aR),
    Math.round((bG - aG) * percentage + aG),
    Math.round((bB - aB) * percentage + aB),
  ];
  return convertRgbArrayToHex(rgbArr);
};

export const mixColorAlpha = ({
  colorA,
  colorB,
  percentage = 0.5,
}: mixColorProps) => {
  let aA = 255;
  let bA = 255;
  if (colorA[0] === '#' && colorA.length === 9) {
    aA = +('0x' + colorA[7] + colorA[8]);
  }
  if (colorB[0] === '#' && colorB.length === 9) {
    bA = +('0x' + colorB[7] + colorB[8]);
  }
  let alphaValue = Math.round((bA - aA) * percentage + aA).toString(16);
  if (alphaValue.length === 1) {
    alphaValue = '0' + alphaValue;
  }

  return (
    mixColor({
      colorA: colorA.slice(0, 7),
      colorB: colorB.slice(0, 7),
      percentage,
    }) + alphaValue
  );
};
