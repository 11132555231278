import {ApiFeature, ProductName, ProductType} from 'pikaparam';
import {TemperatureUnit} from 'puffco-api-axios-client';

import {Constants} from '../../../../constants';
import {
  ChamberType,
  DeviceSettings,
  Dictionary,
  ExclusiveMoodLight,
  LightingPattern,
  MoodLight,
  OperatingState,
  Profile,
  Scratchpad,
} from '../../../types';
import {HeatCycleArrayIndex, TEMP_HEAT_CYCLE_ARRAY_INDEX} from '../pikaparam';
import {StartPeakLogCollectorOptions} from './PeakLogCollector';

export interface DeviceAttributes extends DabSummary {
  boostTemperature: number;
  boostTime: number;
  devBrightness: Buffer;
  mode: number;
  dateOfBirth: number;
  utcTime: number;
  lanternTime: number;
  selectedHeatCycle: number;
  stealth: number;
  batteryLevel: number;
  batteryCapacity: number;
  chargeState: number;
  chargeEstimatedTimeToFull: number;
  dabTotalTime: number;
  chamberType: ChamberType;
  currentTemperature: number;
  targetTemperature: number;
  stateElapsedTime: number;
  stateTotalTime: number;
  operatingState: OperatingState;
  faultEndIndex: number;
}

export type HeatProfileIndex =
  | typeof TEMP_HEAT_CYCLE_ARRAY_INDEX
  | HeatCycleArrayIndex;

export enum FirmwareType {
  Flat = 'Flat',
  Lorax = 'Lorax',
}

export type PeakFeature = keyof (typeof Constants)['MINIMUM_FIRMWARE_VERSION'];

export interface Broadcast {
  counter: number;
  key: number[];
}

export interface DabSummary {
  totalHeatCycles: number;
  dabsPerDay: number;
  approxDabsRemaining: number;
}

export interface IPeakProduct {
  name?: ProductName;
  type?: ProductType;
  consistent: boolean;
  features?: ApiFeature[];
}

export interface IPeakDevice {
  product: IPeakProduct;
  firmwareType: FirmwareType;
  peripheralId: string;
  name: string;
  softwareRevision?: string;
  gitHash?: string;
  serialNumber?: string;
  modelNumber?: string;
  euid?: string;
  broadcast?: Broadcast;
  attributes: Partial<DeviceAttributes>;
  connected: boolean;

  initialize(): Promise<void>;

  supports(feature: PeakFeature): boolean;

  updateBatterySaver(enabled: boolean): Promise<void>;

  readDabSummary(): Promise<DabSummary>;
  readDeviceBattery(): Promise<number>;
  readDeviceAttributes(): Promise<DeviceAttributes>;
  readStealthMode(): Promise<number>;
  readCurrentTemperature(): Promise<number>;
  readHeatCycles(): Promise<number>;
  readDabbingValues(): Promise<{
    state: OperatingState;
    settings: DeviceSettings;
  }>;
  writeDeviceName(name: string): Promise<void>;
  writeDeviceReadyProfile(profileIndex: number): Promise<void>;
  writeReadyModeOff(): Promise<void>;
  writeDeviceBrightness(brightness: number): Promise<void>;
  writeStealthMode(mode: number): Promise<void>;
  writeMasterOff(): Promise<void>;
  writeUtcTime(newTime: number): Promise<void>;
  writeResetDevice(): Promise<void>;

  startDabbing(profileIndex: number): Promise<void>;
  stopDabbing(): Promise<void>;
  addDabbingTime(time: number): Promise<void>;
  addDabbingTemperature(
    temperature: number,
    unit: TemperatureUnit,
  ): Promise<void>;

  readLanternColorArrayIndex(): Promise<number>;
  readLanternScratchpadBuffer(): Promise<Buffer | undefined>;

  clearScratchpad(profileIndex?: HeatProfileIndex): Promise<void>;
  writeScratchpad(
    scratchpad: Scratchpad,
    heatProfileIndex?: HeatProfileIndex,
  ): Promise<void>;

  startLantern(): Promise<void>;
  stopLantern(): Promise<void>;
  readLanternRemaining(): Promise<number>;

  readLanternSettings(
    exclusiveMoodlights: Dictionary<string, ExclusiveMoodLight>,
  ): Promise<{
    lColor?: string | undefined;
    lPattern?: number | undefined;
    partyMode?: boolean | undefined;
    lanternMoodLight?: MoodLight | undefined;
  }>;
  setPartyMode(): Promise<void>;

  writeBoostTemperature(temperature: number): Promise<void>;
  writeBoostDuration(temperature: number): Promise<void>;

  // TODO: Rename to selectedHeatProfileIndex
  readHeatProfileSelect(): Promise<number>;
  // TODO: Rename to selectHeatProfile
  writeHeatProfileSelect(profileIndex: number): Promise<void>;
  readHeatProfile(
    index: HeatProfileIndex,
  ): Promise<{profile: Profile; moodLight: MoodLight | undefined} | undefined>;
  writeHeatProfile(
    profile: Profile,
    moodLight?: MoodLight | undefined,
  ): Promise<void>;
  writeTempHeatProfile(profile: Profile, moodLight?: MoodLight): Promise<void>;
  writeHeatProfiles(
    profiles: Profile[],
    moodLights: (MoodLight | undefined)[],
  ): Promise<void>;

  writeLanternMoodLight(moodLight: MoodLight): Promise<void>;
  writeLanternColor(
    color: string,
    lightingPattern?: LightingPattern,
    isLantern?: boolean,
  ): Promise<void>;

  subscribeToLogs(options: StartPeakLogCollectorOptions): void;

  disconnect(): Promise<void>;
}
