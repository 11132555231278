import {FirmwareType} from '../lib/ble2/v2/PeakDevice/IPeakDevice';
import {
  Analytics,
  CustomCategory,
  CustomEventAction,
  bleConnectError,
} from './Analytics';

export const bleConnectSuccessEvent = (
  firmwareType?: FirmwareType,
  modelNumber?: string,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.SUCCESS,
    undefined,
    {firmwareType, modelNumber},
  );

export const bleConnectScanErrorEvent = (
  firmwareType?: FirmwareType,
  modelNumber?: string,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.ERROR,
    bleConnectError.SCAN_ERROR,
    {firmwareType, modelNumber},
  );

export const bleConnectNotFoundErrorEvent = (
  firmwareType?: FirmwareType,
  modelNumber?: string,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.INFO,
    bleConnectError.NOT_FOUND,
    {firmwareType, modelNumber},
  );

export const bleConnectServiceOrCharacteristicNotFoundErrorEvent = (
  firmwareType?: FirmwareType,
  modelNumber?: string,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.INFO,
    bleConnectError.SERVICE_NOT_FOUND,
    {firmwareType, modelNumber},
  );

export const bleConnectReadCharacteristicOrDescriptorErrorEvent = (
  firmwareType?: FirmwareType,
  modelNumber?: string,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.ERROR,
    bleConnectError.CHAR_DESP_READ,
    {firmwareType, modelNumber},
  );

export const bleConnectPowerOffErrorEvent = (
  firmwareType?: FirmwareType,
  modelNumber?: string,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.ERROR,
    bleConnectError.POWER_OFF,
    {firmwareType, modelNumber},
  );

export const bleConnectDisconnectErrorEvent = (
  firmwareType?: FirmwareType,
  modelNumber?: string,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.ERROR,
    bleConnectError.DISCONNECT,
    {firmwareType, modelNumber},
  );

export const bleConnectBondErrorEvent = (
  bond: 'ios' | 'android' | 'web',
  firmwareType?: FirmwareType,
  modelNumber?: string,
  reconnectAttemptsMax?: number,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.ERROR,
    bond === 'ios' ? bleConnectError.IOS_BOND : bleConnectError.ANDROID_BOND,
    {firmwareType, modelNumber, reconnectAttemptsMax},
  );

export const bleConnectUnknownErrorEvent = (
  firmwareType: FirmwareType | undefined,
  message: any,
  modelNumber?: string,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.ERROR,
    message,
    {firmwareType, modelNumber},
  );

export const bleConnectReconnectMaxAttempts = (
  firmwareType?: FirmwareType,
  modelNumber?: string,
  reconnectAttemptsCount?: number,
) =>
  Analytics.shared().logCustomEventAction(
    CustomEventAction.BLE_CONNECT,
    CustomCategory.ERROR,
    bleConnectError.RECONNECT_MAX_ATTEMPTS,
    {firmwareType, modelNumber, reconnectAttemptsCount},
  );
