import {ContentfulClientApi, createClient} from 'contentful';

import {Environment} from '../../../constants';
import {TypeDeviceFields, TypeFaqFields, TypeGuideFields} from './types';

export enum GuideType {
  NEW_USER = 'NEW_USER',
  DEVICE = 'DEVICE',
}

interface ContentfulClientOptions {
  accessToken?: string;
  spaceId?: string;
  environment?: string;
}

class ContentAccess {
  private readonly client: ContentfulClientApi;

  public constructor({
    accessToken,
    spaceId,
    environment,
  }: ContentfulClientOptions) {
    if (!accessToken || !spaceId || !environment)
      throw new Error('Missing credentials');

    this.client = createClient({
      accessToken,
      space: spaceId,
      environment,
    });
  }

  public getEntry({id}: {id: string}) {
    return this.client.getEntry(id);
  }

  public getDevices() {
    return this.client.getEntries<TypeDeviceFields>({
      content_type: 'device',
      // @ts-ignore
      order: 'fields.order',
      include: 2,
    });
  }

  public getGuide({type}: {type: GuideType}) {
    return this.client
      .getEntries<TypeGuideFields>({
        content_type: 'guide',
        'fields.type[match]': type,
      })
      .then(({items}) => items[0]);
  }

  public getFaqsForReference({
    query,
    tag,
    referenceId,
  }: {
    referenceId: string;
    query?: string;
    tag?: string;
  }) {
    return this.client.getEntries<TypeFaqFields>({
      content_type: 'faq',
      query,
      'fields.references.sys.id[in]': referenceId,
      // @ts-ignore
      order: 'fields.order',
      // @ts-ignore
      'fields.tags.sys.id[in]': tag,
    });
  }
}

export const contentAccess = new ContentAccess({
  spaceId: Environment.contentful.spaceId,
  environment: Environment.contentful.environment,
  accessToken: Environment.contentful.accessToken,
});
