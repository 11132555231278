import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {useSelector} from 'react-redux';

import {currentDeviceIdSelector} from '../lib/redux/bleSlice';
import {
  RootStackParamList,
  RootStackScreenProps,
} from '../navigation/navigators/RootStackNavigator';
import {RedirectOptions} from '../navigation/navigators/params';
import {
  toBluetoothStartPairingRedirect,
  toHome,
} from '../navigation/navigators/util';
import {StyledIcon, StyledIconProps} from './StyledIcon';

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

interface BackButtonProps extends Omit<StyledIconProps, 'name' | 'onPress'> {
  fallback?: RedirectOptions;
  onPress?(): void;
}

export const BackButton: React.FC<BackButtonProps> = ({
  fallback,
  onPress: onCallback,
  ...props
}) => {
  const deviceId = useSelector(currentDeviceIdSelector);
  const navigation = useNavigation<Navigation>();

  const onPress = React.useCallback(() => {
    if (onCallback) return onCallback();

    return navigation.replace(
      ...(fallback ??
        (deviceId ? toHome : toBluetoothStartPairingRedirect.value)),
    );
  }, [onCallback, fallback, deviceId, navigation]);

  return <StyledIcon name="chevronLeft" {...props} {...{onPress}} />;
};
