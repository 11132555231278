import React from 'react';
import {
  ImageBackground,
  ImageSourcePropType,
  ImageStyle,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import {LinearGradientBackground} from '.';
import {Constants} from '../constants';

export type SwitchableBackgroundProps = React.PropsWithChildren<
  {
    isGradient: boolean;
    isDesertTheme?: boolean;
    isFlourishTheme?: boolean;
    gradientArr?: string[];
    backgroundColor?: string;
    background?: ImageSourcePropType;
    imageStyle?: StyleProp<ImageStyle>;
  } & ViewProps
>;

export const SwitchableBackground = ({
  isGradient,
  isDesertTheme,
  isFlourishTheme,
  gradientArr,
  backgroundColor,
  background,
  imageStyle,
  children,
  style,
  ...rest
}: SwitchableBackgroundProps) => {
  if (isGradient && gradientArr) {
    if (isDesertTheme || isFlourishTheme) {
      return (
        <View
          style={{
            backgroundColor,
            ...backgroundStyle,
            ...StyleSheet.flatten(style),
          }}
          {...rest}>
          <LinearGradientBackground
            style={{
              width: '100%',
              height: '63.5%',
              position: 'absolute',
              bottom: 0,
              ...gradientStyle[isFlourishTheme ? 'flourish' : 'desert'],
            }}
            colors={gradientArr}
          />
          {children}
        </View>
      );
    } else {
      return (
        <LinearGradientBackground
          colors={gradientArr}
          style={{
            ...backgroundStyle,
            ...StyleSheet.flatten(style),
          }}
          {...rest}>
          <View style={{width: '100%', height: '100%'}}>{children}</View>
        </LinearGradientBackground>
      );
    }
  } else if (!isGradient && background) {
    return (
      <ImageBackground
        source={background}
        style={{
          ...backgroundStyle,
          ...StyleSheet.flatten(style),
        }}
        imageStyle={[
          imageStyle,
          Platform.OS === 'web' ? {width: '100%', height: '100%'} : {},
        ]}>
        {children}
      </ImageBackground>
    );
  } else {
    return (
      <View style={{...backgroundStyle, ...StyleSheet.flatten(style)}}>
        {children}
      </View>
    );
  }
};

const backgroundStyle: ViewStyle = {
  flex: 1,
};

const gradientStyle: Record<'desert' | 'flourish', ViewStyle> = {
  desert: {
    opacity: 0.7,
  },
  flourish: {
    opacity: Constants.IS_WEB ? 0.85 : 1,
  },
};
