import React from 'react';
import {Image, StyleSheet, Text} from 'react-native';

import {backToTop} from '../../../assets/images';
import {appColors} from '../../../constants';
import {PressableOpacity} from '../../../shims/PressableOpacity';

export interface Props {
  onPress: () => void;
}

export const BackToTopButton = ({onPress}: Props) => {
  return (
    <PressableOpacity style={styles.container} {...{onPress}}>
      <Image style={styles.image} source={backToTop} />

      <Text style={styles.text}>Back to Top</Text>
    </PressableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  image: {
    width: 54,
    height: 54,
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: appColors.white,
    marginHorizontal: 20,
    marginTop: 10,
  },
});
