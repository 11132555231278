import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {useSelector} from 'react-redux';

import {Alerts, Constants, Navigators, Screens, Strings} from '../../constants';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {RedirectionParam} from '../../navigation/navigators/params';
import {
  toEpilepsyWarning,
  toHeatProfileEdit,
  toHome,
  toMoodLightLibrary,
} from '../../navigation/navigators/util';
import {Alert} from '../../shims/alert';
import {appFlagsSelector} from '../redux/appFlagsSlice';
import {currentDeviceSWRevisionSelector} from '../redux/bleSlice';
import {userSelector} from '../redux/userSlice';
import {meetsMinimumFirmware} from '../utilityFunctions';

type Navigation = RootStackScreenProps<
  typeof Navigators.MainNavigator | typeof Navigators.HeatProfileEdit
>['navigation'];

export const useMoodLight = () => {
  const navigation = useNavigation<Navigation>();
  const loggedIn = !!useSelector(userSelector);
  const {hasSeenEpilepsyWarning} = useSelector(appFlagsSelector);
  const deviceSWRevision = useSelector(currentDeviceSWRevisionSelector);

  const canUseMoodLighting = meetsMinimumFirmware(
    deviceSWRevision,
    Constants.MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
  );

  const accessMoodLight = React.useCallback(
    ({profileId, redirect}: {profileId?: string} & RedirectionParam) => {
      if (!canUseMoodLighting || !loggedIn) {
        return Alert.alert(
          Alerts.ACCESS_DENIED,
          canUseMoodLighting
            ? Alerts.FEATURE_ACCESS_MESSAGE
            : Alerts.MOOD_LIGHT_UPDATE_MESSAGE,
          [
            {
              text: canUseMoodLighting ? Strings.LOG_IN : Strings.UPDATE,
              onPress: () => {
                if (canUseMoodLighting) {
                  return navigation.navigate(Screens.Login, {
                    redirect: profileId
                      ? toHeatProfileEdit({profileId}).value
                      : hasSeenEpilepsyWarning
                        ? redirect ?? toMoodLightLibrary.value
                        : toEpilepsyWarning(redirect).value,
                  });
                }

                navigation.navigate(...toHome);

                navigation.navigate(Navigators.MainNavigator, {
                  screen: Navigators.HomeDrawerNavigator,
                  params: {
                    screen: Navigators.HomeEmulatedDrawer,
                    params: {
                      screen: Screens.FirmwareInfo,
                    },
                  },
                });
              },
              style: 'default',
            },
            {text: Strings.CANCEL, style: 'cancel'},
          ],
          {cancelable: false},
        );
      }

      if (hasSeenEpilepsyWarning) {
        return navigation.navigate(...(redirect ?? toMoodLightLibrary.value));
      }

      if (profileId) {
        return navigation.navigate(Navigators.HeatProfileEdit, {
          screen: Screens.EpilepsyWarning,
          params: {
            redirect: toHeatProfileEdit({profileId}).value,
          },
        });
      }

      return navigation.navigate(...toEpilepsyWarning(redirect).value);
    },
    [canUseMoodLighting, hasSeenEpilepsyWarning, loggedIn, navigation],
  );

  return {canUseMoodLighting, hasSeenEpilepsyWarning, accessMoodLight};
};
