import {AppState, AppStateStatus} from 'react-native';

interface WaitForPermissionOptions {
  onRequest(): Promise<void>;
  onCheck(): Promise<void>;
}

export class BasePermissionService {
  protected waitForActive({onRequest, onCheck}: WaitForPermissionOptions) {
    return new Promise<void>((resolve, reject) => {
      const onChange = (state: AppStateStatus) => {
        if (state !== 'active') return;

        onCheck().then(resolve).catch(reject);
      };

      const subscription = AppState.addEventListener('change', onChange);

      onRequest()
        .then(() => {
          if (AppState.currentState === 'active') resolve();
        })
        .catch(reject)
        .finally(() => {
          subscription.remove();
        });
    });
  }
}
