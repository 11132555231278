import {useNavigation} from '@react-navigation/native';
import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {ImageBackground, Text, TouchableOpacity, View} from 'react-native';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';

import {backgroundMarketOptInEarlyAccess} from '../../assets/images';
import {
  CheckBoxContent,
  CloseButton,
  Modal,
  StyledButton,
  StyledCheckBox,
  StyledField,
  displayRightIconMessage,
  getRightIconName,
} from '../../components';
import {
  Constants,
  ErrorMessages,
  Messages,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {marketOptInAccess} from '../../lib/api/market-opt-in-access';
import {updateAppFlags} from '../../lib/redux/appFlagsSlice';
import styled from '../../lib/styled';
import {
  formatDate,
  formatPhoneNumber,
  phoneRegExp,
} from '../../lib/utilityFunctions/';
import {checkAgeTest} from '../../screens/login/lib/checkAge';
import {HomeEmulatedDrawerNavigatorScreenProps} from '../navigators/HomeDrawerNavigator';

interface SmsOptInValues {
  birthday: string;
  phoneNumber: string;
  agreePhone: boolean;
}

const {
  MARKET_OPT_IN_AGREE,
  SMS_OPT_IN_HEADER,
  SMS_OPT_IN_CHECKBOX_TEXT,
  SMS_AGE_REQUIREMENT,
} = Messages;

const {SIGN_ME_UP} = Strings;
const {IS_WEB} = Constants;

type Navigation = HomeEmulatedDrawerNavigatorScreenProps<any>['navigation'];

export const SMSMarketOptIn = () => {
  const dispatch = useDispatch();

  const navigation = useNavigation<Navigation>();

  const handleSubmit = async (
    values: SmsOptInValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);
    await marketOptInAccess.subscribeToSms({phoneNumber: values.phoneNumber});

    actions.setSubmitting(false);
    dispatch(updateAppFlags({hasSeenSMSMarketOptIn: true}));
    Modal.close();

    const userInput = {
      phoneNumberNewUser: values.phoneNumber,
    };
    navigation.navigate(Navigators.MarketOptIn, {
      screen: Screens.MarketOptInConfirmed,
      params: {
        userData: userInput,
      },
    });
  };

  const SmsOptInSchema = Yup.object().shape({
    birthday: checkAgeTest.required(ErrorMessages.REQUIRED_BIRTHDAY),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .when('agreePhone', (agreePhone: boolean, schema: Yup.StringSchema) => {
        return agreePhone
          ? schema.required(ErrorMessages.REQUIRED_PHONE_NUMBER)
          : schema.notRequired();
      }),
    agreePhone: Yup.boolean().test({
      name: 'checkAgreePhone',
      test: function (value: boolean) {
        return (
          value && this.parent.phoneNumber && this.parent.phoneNumber.length > 0
        );
      },
    }),
  });

  return (
    <SmsContainer>
      <SmsContent>
        <View>
          <ImageStyle source={backgroundMarketOptInEarlyAccess}>
            <IconContainer
              onPress={() => {
                dispatch(updateAppFlags({hasSeenSMSMarketOptIn: true}));
                Modal.close();
              }}>
              <CloseButton size={12} />
            </IconContainer>
            <SmsHeaderText>{SMS_OPT_IN_HEADER}</SmsHeaderText>
          </ImageStyle>
        </View>
        <Formik
          initialValues={{
            birthday: '',
            phoneNumber: '',
            agreePhone: false,
          }}
          onSubmit={handleSubmit}
          validationSchema={SmsOptInSchema}>
          {({
            handleBlur,
            handleChange,
            isSubmitting,
            submitForm,
            touched,
            values,
            errors,
            isValid,
            setFieldValue,
          }) => (
            <FormContainer>
              <View>
                <FieldLabel>BIRTHDAY</FieldLabel>
                <StyledField
                  name={'calendar'}
                  borderColor={appColors.lightGray}
                  height={35}
                  placeholder={'MM / DD / YYYY'}
                  onChangeText={handleChange('birthday')}
                  onBlur={handleBlur('birthday')}
                  value={formatDate(values.birthday)}
                  keyboardType="number-pad"
                  leftIconColor={appColors.mediumGray}
                  rightIcon={getRightIconName(
                    values.birthday,
                    errors.birthday,
                    touched.birthday,
                  )}
                  rightIconColor={
                    errors.birthday ? appColors.red : appColors.green
                  }
                  rightIconMessage={displayRightIconMessage(
                    values.birthday,
                    errors.birthday,
                    touched.birthday,
                  )}
                />
                <GrayText>{SMS_AGE_REQUIREMENT}</GrayText>
              </View>
              <View>
                <FieldLabel>MOBILE NUMBER</FieldLabel>
                <StyledField
                  name={'phone'}
                  borderColor={appColors.lightGray}
                  height={35}
                  placeholder={'(   )   -'}
                  onChangeText={handleChange('phoneNumber')}
                  onBlur={handleBlur('phoneNumber')}
                  value={formatPhoneNumber(values.phoneNumber)}
                  keyboardType="number-pad"
                  leftIconColor={appColors.gray}
                  rightIcon={getRightIconName(
                    values.phoneNumber,
                    errors.phoneNumber,
                    touched.phoneNumber,
                  )}
                  rightIconColor={
                    errors.phoneNumber ? appColors.red : appColors.green
                  }
                  rightIconMessage={displayRightIconMessage(
                    values.phoneNumber,
                    errors.phoneNumber,
                    touched.phoneNumber,
                  )}
                />
              </View>
              <CheckboxContainer>
                <StyledCheckBox
                  checked={values.agreePhone}
                  onChange={value => setFieldValue('agreePhone', value)}>
                  <CheckBoxContent
                    style={{
                      fontSize: 10,
                      letterSpacing: 0.25,
                      lineHeight: 15,
                    }}>
                    {SMS_OPT_IN_CHECKBOX_TEXT}
                  </CheckBoxContent>
                </StyledCheckBox>
              </CheckboxContainer>
              <ButtonContainer>
                <StyledButton
                  title={SIGN_ME_UP}
                  disabled={!isValid || isSubmitting}
                  onPress={submitForm}
                  style={{
                    backgroundColor: appColors.black,
                    width: '100%',
                    marginBottom: 14,
                  }}
                  textStyle={{color: appColors.white}}
                />
              </ButtonContainer>
              <TextAgree>{MARKET_OPT_IN_AGREE}</TextAgree>
              <LinkContainer>
                <Text
                  style={{
                    color: appColors.lightGray,
                    fontFamily: 'Roboto-Regular',
                    fontWeight: '400',
                  }}>
                  <UnderlinedGrayText
                    onPress={() => {
                      Modal.close();
                      navigation.navigate(Screens.TermsConditions, {});
                    }}>
                    Terms of Service
                  </UnderlinedGrayText>
                  {' & '}
                  <UnderlinedGrayText
                    onPress={() => {
                      Modal.close();
                      navigation.navigate(Screens.PrivacyPolicy, {});
                    }}>
                    Privacy Policy
                  </UnderlinedGrayText>
                </Text>
              </LinkContainer>
            </FormContainer>
          )}
        </Formik>
      </SmsContent>
    </SmsContainer>
  );
};

const SmsContainer = styled(View)({
  width: '90%',
  borderRadius: 18,
  overflow: 'hidden',
  marginTop: IS_WEB ? 60 : undefined,
});

const SmsContent = styled(View)({
  borderRadius: 18,
  overflow: 'hidden',
});

const SmsHeaderText = styled(Text)({
  color: 'white',
  position: 'absolute',
  fontFamily: 'BigShouldersDisplay-Regular',
  fontSize: IS_WEB ? 44 : 40,
  textTransform: 'uppercase',
  textAlign: 'center',
  paddingBottom: 12,
  fontWeight: '400',
});

const IconContainer = styled(TouchableOpacity)({
  position: 'absolute',
  top: 13,
  right: 13,
});

const ImageStyle = styled(ImageBackground)({
  height: 200,
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  resizeMode: 'cover',
});

const FormContainer = styled(View)({
  backgroundColor: appColors.white,
  paddingVertical: 26,
  paddingHorizontal: 28,
});

const FieldLabel = styled(Text)({
  color: appColors.black,
  fontFamily: 'Roboto-Bold',
  fontSize: 15,
  fontWeight: '100',
  letterSpacing: 0.75,
  marginTop: 10,
  marginBottom: 10,
});

const GrayText = styled(Text)({
  color: appColors.gray,
  fontSize: 12,
  marginTop: 2,
  marginBottom: 5,
  fontFamily: 'Roboto-Regular',
  letterSpacing: 0.37,
  fontWeight: '400',
});

const ButtonContainer = styled(View)({
  justifyContent: 'flex-end',
  width: '100%',
});

const CheckboxContainer = styled(View)({
  marginTop: 10,
  alignItems: 'center',
});

const TextAgree = styled(Text)({
  fontSize: 10,
  letterSpacing: 0.25,
  lineHeight: 15,
  color: appColors.gray,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
});

const LinkContainer = styled(View)({
  alignItems: 'center',
  marginTop: 22,
  height: 20,
});

const UnderlinedGrayText = styled(Text)({
  color: appColors.lightGray,
  textDecorationLine: 'underline',
  fontSize: 12,
  fontFamily: 'Roboto-Regular',
  letterSpacing: 0.43,
  fontWeight: '400',
});
