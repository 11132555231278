import {settingsService} from '../src/services/settings';
import NavigationService, {linking} from './NavigationService';

export const openDeeplink = async (
  url: string,
  initial?: boolean,
  cb?: () => unknown,
) => {
  const action = await settingsService.getActionFromUrl(url);

  if (action?.type === 'internal' && action.action) {
    // TODO: find a better way/function to do this
    // Once a deeplink has been opened, we want to clear the url on web
    // so reopening the app, won't end up on the same deeplink

    window?.history?.pushState({}, '', '/');

    return NavigationService.instance()?.dispatch(action.action);
  }

  if (action?.type === 'external')
    return settingsService.openUrlInBrowser(action.url).catch(() => void 0);

  if (!initial) return;

  if (cb) return cb();
};

export const openDeeplinkPath = (path: string) => {
  const prefix = linking.prefixes[0];

  return openDeeplink(`${prefix}${path}`);
};
