import {Share, ShareOptions} from 'react-native';

import {
  Analytics,
  CustomCategory,
  CustomEventAction,
} from '../../analytics/Analytics';
import {Constants} from '../../constants';

interface ShareContent {
  url: string;
  title?: string;
  message?: string;
}

export const shareUrl = async (
  {title, message = '', url}: ShareContent,
  options?: ShareOptions,
) => {
  message = [
    ...(message ? [message] : []),
    // On iOS, the url is appended to the message by default. On Android we have to add it manually.
    ...(Constants.IS_NATIVE_ANDROID ? [url] : []),
  ].join(' ');

  const result = await Share.share({title, message, url}, options);

  if (result.action !== Share.sharedAction) return;

  Analytics.shared().logCustomEventAction(
    CustomEventAction.SHARE_SENT,
    CustomCategory.URLSHARING,
  );
};
