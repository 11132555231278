import {RootStackParamList} from './RootStackNavigator';

export interface RedirectionParam {
  redirect?: RedirectOptions;
}

type NavigateArgs<
  RouteName extends keyof ParamList,
  ParamList,
> = RouteName extends unknown
  ? undefined extends ParamList[RouteName]
    ? [screen: RouteName] | [screen: RouteName, params: ParamList[RouteName]]
    : [screen: RouteName, params: ParamList[RouteName]]
  : never;

export type RedirectOptions = NavigateArgs<
  keyof RootStackParamList,
  RootStackParamList
>;

export class RedirectionParameter {
  private options: RedirectOptions;

  constructor(...args: RedirectOptions) {
    this.options = args;
  }

  static decode(options: RedirectOptions): RedirectOptions {
    return options;
    // return JSON.parse(atob(options));
  }

  static parse(options: RedirectOptions): RedirectionParameter {
    return new RedirectionParameter(...RedirectionParameter.decode(options));
  }

  get value() {
    return this.options;
  }

  public encode() {
    return this.options;
    // return btoa(JSON.stringify(this.options));
  }

  public add(params: object) {
    if (!this.options[1]) return this;

    const options = insertParams<any>(this.options[1], params);

    this.options = [this.options[0], options];

    return this;
  }
}

type Params<T> = T & {
  params?: Params<T>;
};

const insertParams = <T>(obj: Params<T>, params: Params<T>): Params<T> => {
  if (!obj.params) return {...obj, params};

  return {...obj, params: insertParams(obj.params, params)};
};
