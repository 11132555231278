import React from 'react';
import {Text, View} from 'react-native';

import {Messages, appColors} from '../../../constants';
import styled from '../../../lib/styled';

export const XLFooter = () => (
  <FooterContainer>
    <FooterText>{Messages.XL_CHAMBER_FOOTER}</FooterText>
  </FooterContainer>
);

const FooterContainer = styled(View)({
  width: '100%',
});

const FooterText = styled(Text)({
  color: appColors.black,
  textAlign: 'center',
  marginHorizontal: 10,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
});
