import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import {MoodLightCategory, ProfileVersion} from 'puffco-api-axios-client';
import {MigrationManifest, PersistedState} from 'redux-persist';

import {transformApiProfileToProfile} from '../profileFunctions';
import {Profile, StoreVersions} from '../types';

export const migrations: MigrationManifest = {
  0: state => {
    // Update profile versions to PRE_T
    const store = cloneDeep(state) as PersistedState & StoreVersions[0];
    const migrateProfile = (profile: Profile) =>
      ({
        ...transformApiProfileToProfile(profile),
        version: ProfileVersion.PreT,
      }) as Profile;

    store.profile.activeProfiles =
      store.profile.activeProfiles.map(migrateProfile);
    store.profile.profiles = store.profile.profiles.map(migrateProfile);
    if (store.profile.tempProfile) {
      store.profile.tempProfile = migrateProfile(store.profile.tempProfile);
    }
    return store;
  },
  1: state => {
    // Add user lantern preference state
    const store = cloneDeep(state) as PersistedState & StoreVersions[0];
    store.ble.devices = store.ble.devices.map(device => {
      return {...device, userLanternPreference: undefined};
    });
    return store;
  },
  2: state => {
    // Change custom mood light modified values to time in milliseconds
    const store = cloneDeep(state) as PersistedState & StoreVersions[0];
    Object.values(store.moodLight.customMoodLights.byId).forEach(
      customMoodLight => {
        if (customMoodLight) {
          store.moodLight.customMoodLights.byId[customMoodLight.id] = {
            ...customMoodLight,
            modified: new Date(customMoodLight.modified).getTime(),
          };
        }
      },
    );
    return store;
  },
  3: state => {
    // Change user lantern preference modified from string date to miliseconds
    const store = cloneDeep(state) as PersistedState & StoreVersions[0];
    store.ble.devices = store.ble.devices.map(device => {
      const userLanternPreference = device.userLanternPreference;
      if (userLanternPreference && new Date(userLanternPreference.modified)) {
        userLanternPreference.modified = new Date(
          userLanternPreference.modified,
        ).getTime();
        return {...device, userLanternPreference: userLanternPreference};
      }
      return device;
    });
    return store;
  },
  4: state => {
    // Change otaInfo to be null instead of an empty object
    const store = cloneDeep(state) as PersistedState & StoreVersions[1];
    return store;
  },
  5: state => {
    // Rename currentId to currentProfileIndex and initialize it
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[2];
    store.profile = {
      ...omit((clonedState as PersistedState & StoreVersions[1]).profile, [
        'currentId',
      ]),
      currentProfileIndex: 0,
    };
    return store;
  },
  6: state => {
    // Remove areMoodLightsEnabled
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[6];
    store.appSettings = omit(
      (clonedState as PersistedState & StoreVersions[5]).appSettings,
      ['areMoodLightsEnabled'],
    );
    return store;
  },
  7: state => {
    // Delete customMoodLights and initialize accountMoodLights and peakMoodLights tables
    // Delete currentId and add lanternMoodLightId
    // Set connected as false
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[7];
    store.moodLight = {
      ...omit((clonedState as PersistedState & StoreVersions[6]).moodLight, [
        'currentId',
        'customMoodLights',
      ]),
      accountMoodLights: {
        byId: {},
        allIds: [],
      },
      peakMoodLights: {
        byId: {},
        allIds: [],
      },
      lanternMoodLightId: undefined,
    };
    return store;
  },
  8: state => {
    // Add user username state
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[7];
    return {
      ...store,
      ...(store.user && {user: {...store.user, username: ''}}),
    };
  },
  9: state => {
    // migrate hasSeenEpilepsyWarning to appFlags
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[8];
    return {
      appFlags: {
        hasSeenEpilepsyWarning: store.hasSeenEpilepsyWarning,
        hasSeenNewUsername: false,
      },
      ...omit(store, ['hasSeenEpilepsyWarning']),
    };
  },
  10: state => {
    // migrate moodlight slice to include username cache and originalMoodLightId
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[10];
    store.moodLight.usernameCache = {};
    store.moodLight.accountMoodLights.allIds.forEach(id => {
      const moodLight = store.moodLight.accountMoodLights.byId[id];
      if (moodLight) {
        moodLight.originalMoodLightId = moodLight.id;
      }
    });
    store.moodLight.peakMoodLights.allIds.forEach(id => {
      const moodLight = store.moodLight.peakMoodLights.byId[id];
      if (moodLight && moodLight.category === MoodLightCategory.Custom) {
        moodLight.originalMoodLightId = moodLight.id;
      }
    });
    return store;
  },
  11: state => {
    // Add user defaultUsername state
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[10];
    return {
      ...store,
      ...(store.user && {user: {...store.user, defaultUsername: true}}),
    };
  },
  12: state => {
    // Add user verified state
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[11];
    return {
      ...store,
      ...(store.user && {user: {...store.user, verified: false}}),
    };
  },
  13: state => {
    // Add appsetting agreeAgreement state
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[12];
    return {
      ...store,
      appSettings: {
        ...store.appSettings,
        agreeAgreement: {
          TC: false,
          PP: false,
        },
      },
    };
  },
  14: state => {
    // Migrate launchCount to appFlags
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[13];
    return {
      ...store,
      appFlags: {
        ...store.appFlags,
        launchCount: 0,
      },
    };
  },
  15: state => {
    // Add batteryPreservationOn, cleaning reminder dabs and snooze dabs
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[14];

    const retval = {
      ...store,
      appSettings: {
        ...store.appSettings,
        batteryPreservationOn: false,
      },
      // because `ble` is a nested persistReducer, I'm not sure that this actually
      // does anything. Definitely not sufficient by itself (need the bleMigration).
      ble: {
        ...store.ble,
        devices: store.ble.devices.map(device => ({
          ...device,
          appSettings: {
            lastCleanReminderDabCount: device.hits || 0,
            cleaningReminderDabs: 100,
            snoozeDabs: 10,
            delay: 0,
            cleaningReminderLastUpdated: 0,
          },
        })),
      },
    };

    return retval;
  },
  16: state => {
    // Add hasSeenMarketOptIn to appFlags
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[15];
    return {
      ...store,
      appFlags: {
        ...store.appFlags,
        hasSeenMarketOptIn: false,
      },
    };
  },
  17: state => {
    // Add enableBugfender to user
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[16];
    return {
      ...store,
      ...(store.user && {user: {...store.user, enableBugfender: false}}),
    };
  },
  18: state => {
    // Add scanAndConnectCount to bleSlice
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[17];
    return store;
  },
  19: state => {
    // Add hasSeenVaporUnlockedAlert to appFlags
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[18];
    return {
      ...store,
      appFlags: {
        ...store.appFlags,
        hasSeenVaporUnlockedAlert: false,
        hasSeenAdvancedMetrics: false,
      },
    };
  },
  20: state => {
    // migrate moodlight slice to include led 3 meta
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[20];
    store.moodLight.accountMoodLights.allIds.forEach(id => {
      const moodLight = store.moodLight.accountMoodLights.byId[id];
      if (moodLight) {
        moodLight.led3Meta = {
          userColors: [...moodLight.colors],
          tempoFrac: moodLight.tempo,
        };
      }
    });
    store.moodLight.peakMoodLights.allIds.forEach(id => {
      const moodLight = store.moodLight.peakMoodLights.byId[id];
      if (moodLight && moodLight.category === MoodLightCategory.Custom) {
        moodLight.led3Meta = {
          userColors: [...moodLight.colors],
          tempoFrac: moodLight.tempo,
        };
      }
    });
    return store;
  },
  21: state => {
    // Add hasSeenMetricsAlertScreen to appFlags
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[20];
    return {
      ...store,
      appFlags: {
        ...store.appFlags,
        hasSeenMetricsAlertScreen: false,
      },
    };
  },
  22: state => {
    // Add hasSeenXLUnlockedAlertScreen, hasSeenXLProductReleaseAlertScreen, hasSeenXLUpsellAlertScreen to appFlags
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[21];
    return {
      ...store,
      appFlags: {
        ...store.appFlags,
        hasSeenXLUnlockedAlertScreen: false,
        hasSeenXLProductReleaseAlertScreen: false,
        hasSeenXLUpsellAlertScreen: false,
      },
    };
  },
  23: state => {
    // Add Interstitial state, hasSeenSMSMarketOptIn to migration
    const clonedState = cloneDeep(state);
    const store = clonedState as PersistedState & StoreVersions[23];
    return {
      ...store,
      interstitialScreen: {},
      appFlags: {
        ...store.appFlags,
        hasSeenSMSMarketOptIn: false,
      },
    };
  },
};

export const migrationVersion = 23;
