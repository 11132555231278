import {createStackNavigator} from '@react-navigation/stack';
import React, {ReactNode} from 'react';

import {Messages, Screens, Strings} from '../../constants';
import {MoodType} from '../../lib/types';
import {
  MoodLightAnimationScreen,
  MoodLightControllerScreen,
} from '../../screens';
import {fadeStackOptions, withBackButton} from './common';

export type MoodLightControllerStackParamList = {
  [Screens.MoodLightController]:
    | {
        isFromEditProfile?: boolean;
        id: string;
        headerRight?: ReactNode;
      }
    | undefined;
  [Screens.MoodLightAnimation]: {
    onAnimationValueChange: (m: MoodType) => void;
    initialAnimationValue: MoodType;
  };
};

const MoodLightControllerStack =
  createStackNavigator<MoodLightControllerStackParamList>();

export const MoodLightControllerStackNavigator = () => {
  return (
    <MoodLightControllerStack.Navigator
      initialRouteName={Screens.MoodLightController}
      screenOptions={() => fadeStackOptions}>
      <MoodLightControllerStack.Screen
        name={Screens.MoodLightController}
        component={MoodLightControllerScreen}
        options={{
          headerTitle: Messages.MOOD_LIGHT_EDITOR_TITLE,
          ...withBackButton(),
        }}
      />

      <MoodLightControllerStack.Screen
        name={Screens.MoodLightAnimation}
        component={MoodLightAnimationScreen}
        options={{
          headerTitle: Strings.ANIMATIONS,
          ...withBackButton(),
        }}
      />
    </MoodLightControllerStack.Navigator>
  );
};
