import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {useNavigation} from '@react-navigation/native';
import {InterstitialActiveResultDto} from 'puffco-api-axios-client';
import React from 'react';
import {Linking, View, ViewProps} from 'react-native';
import {OnShouldStartLoadWithRequest} from 'react-native-webview/lib/WebViewTypes';

import {
  Analytics,
  CustomCategory,
  CustomEventAction,
} from '../analytics/Analytics';
import {Constants, Navigators} from '../constants';
import {appLog} from '../lib/Logger';
import {useMoodLight} from '../lib/hooks';
import {openDeeplinkPath} from '../lib/openDeeplink';
import styled from '../lib/styled';
import {WebView} from '../shims/WebView';
import {Modal} from './Modal';

export type InterstitialProps = {
  interstitial: InterstitialActiveResultDto;
} & ViewProps;

interface ActionPayload {
  action: INTERSTITIAL_ACTION;
  path: string;
}

const WEBVIEW_BORDER_RADIUS = 20;

enum INTERSTITIAL_ACTION {
  NAVIGATE_MOODLIGHT = 'navigateMoodLight',
  NAVIGATE_EDUCATION = 'navigateEducation',
  DEEPLINK = 'deeplink',
  DISMISS = 'dismiss',
}

const parseMessage = (message: string) => {
  try {
    const parsed = JSON.parse(message) as ActionPayload;
    return parsed;
  } catch (_) {
    return message;
  }
};

export const Interstitial = ({
  interstitial: {url},
  ...rest
}: InterstitialProps) => {
  const {accessMoodLight} = useMoodLight();
  const handler = React.useRef<(event: any) => void>();
  const navigation = useNavigation<DrawerContentComponentProps['navigation']>();

  const onShouldStartLoadWithRequest =
    React.useCallback<OnShouldStartLoadWithRequest>(({url}) => {
      const urlPrefixes = ['http://', 'https://'];
      const shouldLoad = urlPrefixes.some(prefix => url.startsWith(prefix));

      if (!shouldLoad) {
        Linking.openURL(url);
      }

      return shouldLoad;
    }, []);

  const handleEventAction = React.useCallback(
    (event: any) => {
      Modal.close();

      const message = event.nativeEvent?.data || event.data || event.message;

      const data = parseMessage(message);

      if (typeof data === 'string') {
        switch (data) {
          case INTERSTITIAL_ACTION.NAVIGATE_MOODLIGHT:
            accessMoodLight({});
            break;
          case INTERSTITIAL_ACTION.NAVIGATE_EDUCATION:
            navigation.navigate(Navigators.DeviceEducation);
            break;
          case INTERSTITIAL_ACTION.DISMISS:
            break;
          default:
            appLog.info(`Unknown action: ${data}`);
        }

        return;
      }

      if (data?.action !== INTERSTITIAL_ACTION.DEEPLINK) {
        appLog.info(`Unknown action: ${data.action}`);

        return;
      }

      openDeeplinkPath(data.path);
    },
    [accessMoodLight, navigation],
  );

  React.useEffect(() => {
    if (Constants.IS_WEB) {
      if (handler.current) {
        window.removeEventListener('message', handler.current);
      }
      handler.current = (event: any) => handleEventAction(event);
      window.addEventListener('message', handler.current);

      return () => {
        window.removeEventListener('message', handler.current!);
      };
    }
  }, [handleEventAction]);

  React.useEffect(() => {
    Analytics.shared().logCustomEventAction(
      CustomEventAction.VIEW_PROMOTION,
      CustomCategory.PROMOTION,
      url,
    );
  }, []);

  return (
    <Window {...rest}>
      <WebView
        source={{
          uri: url,
        }}
        {...(Constants.IS_NATIVE_ANDROID && {
          containerStyle: {borderRadius: WEBVIEW_BORDER_RADIUS},
        })}
        style={{
          margin: -1,
          ...(Constants.IS_WEB && {
            borderRadius: WEBVIEW_BORDER_RADIUS,
          }),
        }}
        {...{onShouldStartLoadWithRequest}}
        onMessage={event => {
          handleEventAction(event);
        }}
      />
    </Window>
  );
};

const Window = styled(View)({
  width: '85%',
  height: '100%',
  position: 'absolute',
  paddingVertical: 60,
  borderRadius: WEBVIEW_BORDER_RADIUS,
  zIndex: 1,
  overflow: 'hidden',
});
