import React from 'react';

type HandlerProps<T> = {
  initialValue: T;
  update?: (value: T) => void;
  children: (
    value: T,
    setValue: React.Dispatch<React.SetStateAction<T>>,
  ) => React.ReactNode;
  slideAutoUpdate?: boolean;
};

export type SlideHandleRefProps<T = any> = {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
};

function SHandler<T = any>(
  {initialValue, update, children, slideAutoUpdate}: HandlerProps<T>,
  ref: React.Ref<SlideHandleRefProps<T>>,
) {
  const [temp, setTemp] = React.useState<T>(initialValue);

  React.useImperativeHandle(ref, () => {
    return {
      value: temp,
      setValue: setTemp,
    };
  }, [temp]);

  React.useEffect(() => {
    if (!slideAutoUpdate) {
      const delay = setTimeout(() => {
        if (temp !== undefined) update?.(temp);
      }, 100);
      return () => clearTimeout(delay);
    } else {
      if (temp !== undefined) update?.(temp);
    }
  }, [temp, slideAutoUpdate]);

  return <>{children(temp, setTemp)}</>;
}

export const SlideHandler = React.forwardRef(SHandler) as <T = any>(
  props: HandlerProps<T> & {
    ref?: React.Ref<{
      value: T;
      setValue: React.Dispatch<React.SetStateAction<T>>;
    }>;
  },
) => ReturnType<typeof SHandler>;
