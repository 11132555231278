import React from 'react';
import {useDispatch} from 'react-redux';

import {backgroundProductRelease} from '../../assets/images';
import {
  Constants,
  Messages,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {InterstitialScreen} from '../../lib/InterstitialManager';
import {navigateToWebsite} from '../../lib/navigateToWebsite';
import {updateSeen} from '../../lib/redux/interstitialScreenSlice';
import {ChamberType} from '../../lib/types';
import {HomeEmulatedDrawerNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {ImageAndContentContainer} from '../components';
import {XLMetrics} from './components/XLMetrics';

const xlMetrics = [
  {type: 'more vapor', value: '2x'},
  {type: 'larger bowl', value: '+78%'},
  {type: 'ready to rip', value: '100%'},
];

interface Props
  extends HomeEmulatedDrawerNavigatorScreenProps<
    typeof Screens.XLProductReleaseAlert
  > {}

export const XLProductReleaseAlertScreen = ({navigation}: Props) => {
  const dispatch = useDispatch();

  return (
    <ImageAndContentContainer
      image={backgroundProductRelease}
      header={{
        content: Messages.UPGRADE_3DXL_CHAMBER_TITLE,
      }}
      body={{
        content: Messages.UPGRADE_3DXL_CHAMBER_BODY,
        style: {
          textAlign: 'left',
          color: appColors.black,
          width: '100%',
          fontSize: 16,
        },
      }}
      primaryButton={{
        title: Strings.SHOP_NOW,
        onClick: () => {
          dispatch(
            updateSeen({
              screen: Screens.XLProductReleaseAlert,
            }),
          );

          navigateToWebsite(Constants.XL_CHAMBER_SHOP_URL_PATH);
        },
      }}
      secondaryButton={{
        onClick: () => {
          dispatch(
            updateSeen({
              screen: Screens.XLProductReleaseAlert,
            }),
          );

          navigation.navigate(Navigators.HomeTabNavigator, {
            screen: Screens.Home,
          });
        },
      }}>
      <XLMetrics xlMetricsData={xlMetrics} />
    </ImageAndContentContainer>
  );
};

export const XLProductReleaseInterstitial: InterstitialScreen = {
  screen: Screens.XLProductReleaseAlert,
  conditionMet: ({initializeTime, lastSeenTime, state, hasChamberType}) => {
    if (
      !initializeTime ||
      lastSeenTime ||
      state.appFlags.hasSeenXLProductReleaseAlertScreen
    )
      return false;

    return !hasChamberType(ChamberType.XL);
  },
  autoInitialize: true,
  startDate: new Date('2023-06-23'),
  // disable for now as client does not know startDate
  disable: true,
};
