import {Buffer} from 'buffer';
import {ConnectedDevice, Pikaparam, isValidCodec} from 'pikaparam';
import {MoodLightCategory, TemperatureUnit} from 'puffco-api-axios-client';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  throwError,
  timeout,
} from 'rxjs';
import {ulid} from 'ulid';

import {
  Constants,
  ScratchpadFieldVersionMap,
  ScratchpadIdField,
  ScratchpadVersions,
} from '../../../../constants';
import {bleLog} from '../../../Logger';
import {convertBufferValue} from '../../../convertReadValue';
import {writeValueToBuffer} from '../../../convertWriteValue';
import {
  ChamberType,
  LanternCustom0,
  LanternCustom1,
  LanternCustomNewest,
  LanternExclusiveNewest,
  LanternScratchpadId,
  LightingPattern,
  MoodLight,
  OperatingState,
  Profile,
  ProfileCustom0,
  ProfileCustom1,
  ProfileNewest,
  ProfileScratchpadId,
  Scratchpad,
  ScratchpadId,
  TableColor,
  isCustomMoodLight,
  isTHeatProfile,
} from '../../../types';
import {
  getMiddlewareValueByteSize,
  getSecondsFromMilliseconds,
  meetsMinimumFirmware,
} from '../../../utilityFunctions';
import {Temperature} from '../../../utils/temperature';
import {
  AnyDeviceProperties,
  HeatCycleArrayIndex,
  NvmIndex,
  isTempHeatProfileIndex,
} from '../pikaparam';
import {
  Broadcast,
  DeviceAttributes,
  FirmwareType,
  HeatProfileIndex,
  IPeakProduct,
  PeakFeature,
} from './IPeakDevice';
import {
  PeakLogCollector,
  StartPeakLogCollectorOptions,
} from './PeakLogCollector';

export interface PeakDeviceBaseOptions {
  device: ConnectedDevice;
  pikaparam: Pikaparam;
  firmwareType: FirmwareType;
  softwareRevision: string;
}

const observables = [
  'approxDabsRemaining',
  'battSoc',
  'battChargeEstTimeToFull',
  'battChargeState',
  'heaterType',
  'userHeaterTemp',
  'dabsPerDay',
  'faultLogEnd',
  'selectedHeatCycle',
  'lanternRemainingTime',
  'totalHeatCycles',
  'operatingState',
  'stateElapsedTime',
  'stateTotalTime',
  'heaterTempCommand',
] as const;

const setters = {
  // observable
  approxDabsRemaining: (a: Partial<DeviceAttributes>, value: number) => {
    a.approxDabsRemaining = value;
  },
  battSoc: (a: Partial<DeviceAttributes>, value: number) => {
    a.batteryLevel = Math.round(value);
  },
  battChargeEstTimeToFull: (a: Partial<DeviceAttributes>, value: number) => {
    a.chargeEstimatedTimeToFull = value;
  },
  battChargeState: (a: Partial<DeviceAttributes>, value: number) => {
    a.chargeState = value;
  },
  heaterType: (a: Partial<DeviceAttributes>, value: number) => {
    a.chamberType = value;
  },
  userHeaterTemp: (a: Partial<DeviceAttributes>, value: number) => {
    a.currentTemperature = Math.round(value);
  },
  dabsPerDay: (a: Partial<DeviceAttributes>, value: number) => {
    a.dabsPerDay = value;
  },
  faultLogEnd: (a: Partial<DeviceAttributes>, value: number) => {
    a.faultEndIndex = value;
  },
  selectedHeatCycle: (a: Partial<DeviceAttributes>, value: number) => {
    a.selectedHeatCycle = value;
  },
  lanternRemainingTime: (a: Partial<DeviceAttributes>, value: number) => {
    a.lanternTime = value;
  },
  totalHeatCycles: (a: Partial<DeviceAttributes>, value: number) => {
    a.totalHeatCycles = value;
  },
  operatingState: (a: Partial<DeviceAttributes>, value: OperatingState) => {
    a.operatingState = value;
  },
  stateTotalTime: (a: Partial<DeviceAttributes>, value: number) => {
    a.stateTotalTime = Number.isFinite(value) ? value : 0;
  },
  stateElapsedTime: (a: Partial<DeviceAttributes>, value: number) => {
    a.stateElapsedTime = Number.isFinite(value) ? value : 0;
  },
  heaterTempCommand: (a: Partial<DeviceAttributes>, value: number) => {
    a.targetTemperature = Math.round(value);
  },

  // static
  heatCycle0BoostTempDelta: (a: Partial<DeviceAttributes>, value: number) => {
    a.boostTemperature = value;
  },
  heatCycle0BoostTimeDelta: (a: Partial<DeviceAttributes>, value: number) => {
    a.boostTime = value;
  },
  ledBrightness: (a: Partial<DeviceAttributes>, value: Buffer) => {
    a.devBrightness = value;
  },
  readyModeCycle: (a: Partial<DeviceAttributes>, value: number) => {
    a.mode = value;
  },
  deviceBirthday: (a: Partial<DeviceAttributes>, value: number) => {
    a.dateOfBirth = value;
  },
  utcTime: (a: Partial<DeviceAttributes>, value: number) => {
    a.utcTime = value;
  },
  stealthMode: (a: Partial<DeviceAttributes>, value: number) => {
    a.stealth = value;
  },
  batteryCapacity: (a: Partial<DeviceAttributes>, value: number) => {
    a.batteryCapacity = value;
  },
  totalHeatCycleTime: (a: Partial<DeviceAttributes>, value: number) => {
    a.dabTotalTime = value;
  },
  userHeaterTempCommand: (a: Partial<DeviceAttributes>, value: number) => {
    a.targetTemperature = value;
  },
} as const;

export abstract class PeakDeviceBase<P extends AnyDeviceProperties> {
  static advertisedServices: string[] = [];

  name: string;
  attributes: Partial<DeviceAttributes> = {};

  readonly firmwareType: FirmwareType;
  readonly peripheralId: string;
  readonly softwareRevision: string;
  readonly serialNumber?: string;
  readonly modelNumber?: string;
  readonly product: IPeakProduct;

  euid?: string;
  gitHash?: string;
  broadcast?: Broadcast;

  protected readonly pikaparam: Pikaparam;
  protected readonly watchers: Map<
    keyof P,
    {clean: () => void; callbacks: Set<(p: string, value: unknown) => void>}
  > = new Map();

  private readonly device: ConnectedDevice;
  private readonly logCollector: PeakLogCollector;

  constructor({
    device,
    pikaparam,
    firmwareType,
    softwareRevision,
  }: PeakDeviceBaseOptions) {
    this.device = device;
    this.peripheralId = this.device.id;
    this.name = this.device.name ?? '';
    this.firmwareType = firmwareType;
    this.softwareRevision = softwareRevision;
    this.serialNumber = pikaparam.devInfo.info?.serialNumber;
    this.modelNumber = pikaparam.devInfo.info?.modelCode?.toString();
    this.product = {
      name: pikaparam.devInfo.info?.marketingName,
      type: pikaparam.devInfo.info?.product,
      consistent: !!pikaparam.devInfo.info?.isConsistent,
      features: pikaparam.devInfo.info?.features ?? [],
    };
    this.pikaparam = pikaparam;

    this.logCollector = new PeakLogCollector(pikaparam);

    observables.forEach(async key => {
      const setter = setters[key];

      await this.createWatch(key, (_, value: number) => {
        if (value == undefined) return;

        setter(this.attributes, value);
      }).catch(error => bleLog.error('Create watch failed.', {error}));
    });
  }

  abstract writeHeatProfile(
    profile: Profile,
    moodLight?: MoodLight,
  ): Promise<void>;

  abstract readHeatProfile(
    index: HeatProfileIndex,
  ): Promise<{profile: Profile; moodLight: MoodLight | undefined} | undefined>;

  abstract writeColor(
    color: string,
    lightingPattern: LightingPattern,
    isLantern: boolean,
    index?: HeatProfileIndex,
  ): Promise<void>;

  abstract setPartyMode(): Promise<void>;

  public get connected() {
    return this.device.state === 'connected';
  }

  public async initialize() {
    // Device name is empty after factory reset so we fallback
    this.name =
      (await this.readCommand('deviceName')) ||
      this.name ||
      this.device.name ||
      'Unnamed device';

    this.euid = await this.readEuidNumber();

    this.gitHash = await this.readCommand('gitHash');

    await this.writeBirthday();
    await this.terminateBondingAnimation();
  }

  private async readEuidNumber() {
    const euid = await this.readCommand('euid');

    return Array.from(new Uint8Array(euid))
      .reverse()
      .map(v => ('00' + v.toString(16)).slice(-2))
      .join('')
      .toUpperCase();
  }

  private async writeBirthday() {
    const param = this.pikaparam.param.getInApiThrow<number>('deviceBirthday');

    const dateOfBirth = await param.readOnce();

    if (dateOfBirth !== 4294967295) return;

    await param.set(Math.floor(Date.now() / 1000));
  }

  private async terminateBondingAnimation() {
    await this.writeCommand('terminateBondingAnim', 1);
  }

  public async readDeviceAttributes() {
    const devBrightness = Buffer.from(await this.readCommand('ledBrightness'));
    const attributes: DeviceAttributes = {
      boostTemperature: await this.readCommand('heatCycle0BoostTempDelta'),
      boostTime: await this.readCommand('heatCycle0BoostTimeDelta'),
      devBrightness,
      mode: await this.readCommand('readyModeCycle'),
      chargeState: await this.readCommand('battChargeState'),
      chargeEstimatedTimeToFull: await this.readCommand(
        'battChargeEstTimeToFull',
      ),
      batteryLevel: await this.readDeviceBattery(),
      batteryCapacity: await this.readCommand('batteryCapacity'),
      dateOfBirth: await this.readCommand('deviceBirthday'),
      utcTime: await this.readCommand('utcTime'),
      lanternTime: await this.readCommand('lanternRemainingTime'),
      selectedHeatCycle: await this.readHeatProfileSelect(),
      dabTotalTime: await this.readCommand('totalHeatCycleTime'),
      ...(await this.readDabSummary()),
      stealth: await this.readStealthMode(),
      chamberType: await this.readChamberType(),
      stateElapsedTime: await this.readCommand('stateElapsedTime'),
      stateTotalTime: await this.readCommand('stateTotalTime'),
      operatingState: await this.readCommand('operatingState'),
      faultEndIndex: await this.readCommand('faultLogEnd'),
      currentTemperature: await this.readCommand('userHeaterTemp'),
      targetTemperature: await this.readCommand('userHeaterTempCommand'),
    };

    this.attributes = {...this.attributes, ...attributes};

    return attributes;
  }

  protected abstract readChamberType(): Promise<ChamberType>;

  public async readDabSummary() {
    return {
      totalHeatCycles: await this.readCommand('totalHeatCycles'),
      dabsPerDay: await this.readCommand('dabsPerDay'),
      approxDabsRemaining: await this.readCommand('approxDabsRemaining'),
    };
  }

  public async readDeviceBattery() {
    return Math.round(await this.readCommand('battSoc'));
  }

  public supports(feature: PeakFeature): boolean {
    const featureFirmware = Constants.MINIMUM_FIRMWARE_VERSION[feature];

    return meetsMinimumFirmware(this.softwareRevision, featureFirmware);
  }

  public async updateBatterySaver(enabled: boolean) {
    if (!this.supports('BATTERY_PRESERVATION')) return;

    const percentage = await this.readCommand('maxBattSoc');
    const current = percentage < 100;

    if (enabled === current) return;

    await this.writeCommand(
      'maxBattSoc',
      enabled ? Constants.BATTERY_PRESERVATION_PCT : 100,
    );
  }

  public async readStealthMode() {
    return this.readCommand('stealthMode');
  }

  public async readCurrentTemperature() {
    return this.readCommand('heaterTemp');
  }

  public async readHeatCycles(): Promise<number> {
    return this.readCommand('totalHeatCycles');
  }

  public async writeDeviceName(name: string) {
    await this.writeCommand('deviceName', name);

    this.name = name;
  }

  public async writeDeviceReadyProfile(profileIndex: number) {
    await this.writeCommand('readyModeCycle', profileIndex);
  }

  public async writeReadyModeOff() {
    await this.writeCommand(
      'readyModeCycle',
      10, // NaN or out of range will turn off ready mode -- hopefully no Peak can support 10 in the near future
    );
  }

  public async writeDeviceBrightness(brightness: number) {
    await this.writeCommand(
      'ledBrightness',
      [brightness, brightness, brightness, brightness], // [base, mid, glass, logo]
    );
  }

  public async writeStealthMode(mode: number) {
    await this.writeCommand('stealthMode', mode);
  }

  public async writeMasterOff() {
    await this.writeCommand('lanternStart', 0);
    await this.writeCommand('modeCmd', Constants.MODE_COMMAND.masterOff);
  }

  public async addDabbingTime(time: number) {
    return this.writeCommand('timeOverride', time);
  }

  public async addDabbingTemperature(
    temperature: number,
    unit: TemperatureUnit,
  ) {
    const temperatureInCelsius = Temperature.convertDifference(temperature, {
      from: unit,
      to: TemperatureUnit.Celsius,
    });

    return this.writeCommand('tempOverride', temperatureInCelsius);
  }

  public async readLanternScratchpadBuffer(): Promise<Buffer | undefined> {
    const lanternScratchpad = await this.readCommand('lanternScratchpad');

    return Buffer.from(lanternScratchpad);
  }

  public async clearScratchpad(profileIndex?: HeatProfileIndex): Promise<void> {
    const buffer = Buffer.alloc(Constants.SCRATCHPAD_MAX_SIZE, 0xff);

    const getProperty = () => {
      if (profileIndex === undefined) {
        return `lanternScratchpad`;
      } else if (profileIndex === -1) {
        return `tempHeatCycleScratchpad`;
      } else {
        return `heatCycle${profileIndex}Scratchpad` as const;
      }
    };

    const property = getProperty();

    await this.writeCommand(property, [...buffer]);
  }

  public async writeScratchpad(
    scratchpad: Scratchpad,
    heatProfileIndex?: HeatProfileIndex,
  ): Promise<void> {
    const scratchpadId = scratchpad.version;
    const version = ScratchpadFieldVersionMap[scratchpadId];
    if (version) {
      const buffer = Buffer.alloc(Constants.SCRATCHPAD_MAX_SIZE);
      const versionBuffer = writeValueToBuffer(
        scratchpadId,
        ScratchpadIdField.type,
      );
      buffer.set(versionBuffer, 0);
      let index = getMiddlewareValueByteSize(ScratchpadIdField.type);

      version.forEach(field => {
        const newbuff = writeValueToBuffer(
          scratchpad[field.key as keyof Scratchpad],
          field.type,
        );
        buffer.set(newbuff, index);
        index += field.length ?? getMiddlewareValueByteSize(field.type);
      });

      const getProperty = () => {
        if (!(scratchpadId in ProfileScratchpadId))
          return `lanternScratchpad` as const;

        const index = heatProfileIndex ?? -1;

        if (isTempHeatProfileIndex(index))
          return `tempHeatCycleScratchpad` as const;

        return `heatCycle${index}Scratchpad` as const;
      };

      const property = getProperty();

      await this.writeCommand(property, [...buffer]);
    }
  }

  public async startLantern() {
    await this.writeCommand('lanternStart', 1);
    await this.writeCommand('lanternTime', Constants.LANTERN_TIME_SEC);
  }

  public async stopLantern() {
    await this.writeCommand('lanternStart', 0);
  }

  public async readLanternRemaining() {
    return this.readCommand('lanternRemainingTime');
  }

  public async writeUtcTime(newTime: number) {
    await this.writeCommand('utcTime', newTime);
  }

  public async writeResetDevice() {
    await this.writeCommand('factoryReset', 1);
  }

  public async readHeatProfileSelect() {
    return this.readCommand('selectedHeatCycle');
  }

  public async writeHeatProfileSelect(profileIndex: number) {
    await this.writeCommand('selectedHeatCycle', profileIndex);
  }

  public parseScratchpad(buffer: Buffer) {
    const id = convertBufferValue(
      buffer,
      ScratchpadIdField.type,
    ) as ScratchpadId;
    if (ScratchpadFieldVersionMap[id]) {
      const version = ScratchpadFieldVersionMap[id];
      const obj = {} as Scratchpad;
      let index = 1; // Set index one byte after scratchcard index offset
      version.forEach(field => {
        const endIndex =
          index + (field.length ?? getMiddlewareValueByteSize(field.type));
        const slice = buffer.slice(index, endIndex);
        // @ts-expect-error scratchpad is a union type
        obj[field.key] = convertBufferValue(slice, field.type);
        index = endIndex;
      });
      obj.version = id;

      // if LANTERN_CUSTOM_0 or PROFILE_CUSTOM_0, auto upgrade them to CUSTOM_1
      if (obj.version === LanternScratchpadId.LANTERN_CUSTOM_0) {
        (obj as unknown as LanternCustom1).originalMoodUlid = (
          obj as LanternCustom0
        ).moodUlid;
        (obj as unknown as LanternCustom1).version =
          LanternScratchpadId.LANTERN_CUSTOM_1;
      } else if (obj.version === ProfileScratchpadId.PROFILE_CUSTOM_0) {
        (obj as unknown as ProfileCustom1).originalMoodUlid = (
          obj as ProfileCustom0
        ).moodUlid;
        (obj as unknown as ProfileCustom1).version =
          ProfileScratchpadId.PROFILE_CUSTOM_1;
      }
      return obj;
    }
  }

  public async writeHeatProfiles(
    profiles: Profile[],
    moodLights: (MoodLight | undefined)[],
  ) {
    if (profiles.length === moodLights.length) {
      for (let i = 0; i < profiles.length; i++) {
        await this.writeHeatProfile(profiles[i], moodLights[i]);
      }
    }
  }

  public async writeTempHeatProfile(profile: Profile, moodLight?: MoodLight) {
    return this.writeHeatProfile(
      {...profile, order: Constants.TEMP_HEAT_PROFILE_INDEX},
      moodLight,
    );
  }

  public async readTempHeatProfile(): Promise<
    {profile: Profile; moodLight: MoodLight | undefined} | undefined
  > {
    return this.readHeatProfile(Constants.TEMP_HEAT_PROFILE_INDEX);
  }

  public getMoodLightFromScratchpad = (scratchpad: Scratchpad) => {
    if (
      scratchpad.version === ScratchpadVersions.LanternCustomNewest ||
      scratchpad.version === ScratchpadVersions.ProfileCustomNewest
    ) {
      const retval: MoodLight = {
        category: MoodLightCategory.Custom,
        id: scratchpad.moodUlid,
        modified: (scratchpad.moodDateModified
          ? new Date(
              scratchpad.moodDateModified *
                Constants.UNIT_CONVERSION.SECONDS_TO_MILLISECONDS,
            )
          : new Date()
        ).getTime(),
        name: scratchpad.moodName.replace(/\0/g, ''),
        colors: scratchpad.colors,
        type: scratchpad.moodType,
        tempo: scratchpad.tempo,
        originalMoodLightId: scratchpad.originalMoodUlid,
        led3Meta: scratchpad as any,
      };
      return retval;
    }
  };

  public abstract readLanternColorArrayIndex(): Promise<number>;

  public async alternateLanternNvmIndex(
    nvmIndex: NvmIndex<P>,
  ): Promise<NvmIndex<P>> {
    const currentColorIndex = await this.readLanternColorArrayIndex();
    if (
      nvmIndex === currentColorIndex &&
      currentColorIndex ===
        Constants.NVM_ARRAY_INDICES.DEFAULT_LANTERN_ARRAY_INDEX
    ) {
      return Constants.NVM_ARRAY_INDICES
        .ALTERNATIVE_LANTERN_ARRAY_INDEX as NvmIndex<P>;
    }
    return nvmIndex;
  }

  public async readScratchpad(buffer: Buffer): Promise<Scratchpad | undefined> {
    const id = convertBufferValue(
      buffer,
      ScratchpadIdField.type,
    ) as ScratchpadId;
    if (ScratchpadFieldVersionMap[id]) {
      const version = ScratchpadFieldVersionMap[id];
      const obj = {} as Scratchpad;
      let index = 1; // Set index one byte after scratchcard index offset
      // TODO: Fix typing
      // eslint-disable-next-line
      // @ts-ignore
      version.forEach(field => {
        const endIndex =
          index + (field.length ?? getMiddlewareValueByteSize(field.type));
        const slice = buffer.slice(index, endIndex);
        // TODO: Fix typing
        // eslint-disable-next-line
        // @ts-ignore
        obj[field.key] = convertBufferValue(slice, field.type);
        index = endIndex;
      });
      obj.version = id;

      // if LANTERN_CUSTOM_0 or PROFILE_CUSTOM_0, auto upgrade them to CUSTOM_1
      if (obj.version === LanternScratchpadId.LANTERN_CUSTOM_0) {
        (obj as unknown as LanternCustom1).originalMoodUlid = (
          obj as LanternCustom0
        ).moodUlid;
        (obj as unknown as LanternCustom1).version =
          LanternScratchpadId.LANTERN_CUSTOM_1;
      } else if (obj.version === ProfileScratchpadId.PROFILE_CUSTOM_0) {
        (obj as unknown as ProfileCustom1).originalMoodUlid = (
          obj as ProfileCustom0
        ).moodUlid;
        (obj as unknown as ProfileCustom1).version =
          ProfileScratchpadId.PROFILE_CUSTOM_1;
      }
      return obj;
    }
  }

  public async writeLanternColor(
    color: string,
    lightingPattern = LightingPattern.STEADY,
    isLantern = true,
  ) {
    await this.writeColor(color, lightingPattern, isLantern);
  }

  public async writeLanternMoodLight(moodLight: MoodLight) {
    await this.writeMoodLight(
      Constants.CHARACTERISTIC_OFFSET.lanternColorSetting,
      Constants.NVM_ARRAY_INDICES.DEFAULT_LANTERN_ARRAY_INDEX,
      moodLight,
    );
  }

  public makeScratchpad(moodLight: MoodLight, profile?: Profile): Scratchpad {
    let scratchpad: Scratchpad;
    const lanternExclusiveScratchpad: Omit<LanternExclusiveNewest, 'version'> =
      {
        moodUlid: moodLight.id,
      };
    const profileNewest: Omit<ProfileNewest, 'version'> | undefined = profile
      ? {
          heatProfileUlid: profile.id ?? ulid(),
          heatProfileDateModified: getSecondsFromMilliseconds(
            isTHeatProfile(profile) && profile?.modified
              ? profile.modified
              : new Date().getTime(),
          ),
        }
      : undefined;
    if (isCustomMoodLight(moodLight)) {
      const lanternScratchpad: Omit<LanternCustomNewest, 'version'> = {
        ...lanternExclusiveScratchpad,
        moodName: moodLight.name,
        moodDateModified: getSecondsFromMilliseconds(moodLight.modified),
        moodType: moodLight.type,
        tempo: moodLight.tempo,
        colors: moodLight.colors,
        originalMoodUlid: moodLight.originalMoodLightId,
      };
      scratchpad = {
        version: LanternScratchpadId.LANTERN_CUSTOM_NEWEST,
        ...lanternScratchpad,
        ...(profile && {
          ...profileNewest,
          version: ProfileScratchpadId.PROFILE_CUSTOM_NEWEST,
        }),
      } as Scratchpad;
    } else {
      scratchpad = {
        version: LanternScratchpadId.LANTERN_EXCLUSIVE_NEWEST,
        ...lanternExclusiveScratchpad,
        ...(profile && {
          ...profileNewest,
          version: ProfileScratchpadId.PROFILE_EXCLUSIVE_NEWEST,
        }),
      } as Scratchpad;
    }

    return scratchpad;
  }

  public async writeMoodLightScratchpad(
    moodLight: MoodLight,
    profile?: Profile,
  ) {
    const scratchpad = this.makeScratchpad(moodLight, profile);
    await this.writeScratchpad(scratchpad, profile?.order as HeatProfileIndex);
  }

  async startDabbing(profileIndex: number): Promise<void> {
    await this.writeCommand('selectedHeatCycle', profileIndex);
    await this.writeCommand('modeCmd', Constants.MODE_COMMAND.heatCycleStart);
  }

  public async writeBoostTemperature(temperature: number): Promise<void> {
    if (typeof temperature !== 'number' || temperature < 0) return;

    await this.writeCommand('tempHeatCycleBoostTempDelta', temperature);

    for (let i = 0; i < 4; ++i) {
      await this.writeCommand(
        `heatCycle${i as HeatCycleArrayIndex}BoostTempDelta` as const,
        temperature,
      );
    }
  }

  public async writeBoostDuration(duration: number): Promise<void> {
    if (typeof duration !== 'number' || duration < 0) return;

    await this.writeCommand('tempHeatCycleBoostTimeDelta', duration);

    for (let i = 0; i < 4; i++) {
      await this.writeCommand(
        `heatCycle${i as HeatCycleArrayIndex}BoostTimeDelta` as const,
        duration,
      );
    }
  }

  public abstract writeMoodLight(
    characteristicUuid: string,
    nvmIndex: number,
    moodLight: MoodLight,
    profile?: Profile | undefined,
  ): Promise<void>;

  public async subscribeToLogs(o: StartPeakLogCollectorOptions) {
    this.logCollector.start(o);
  }

  public async disconnect(): Promise<void> {
    bleLog.info(`Peak disconnect.`);

    this.cleanupWatches();
    this.logCollector.stop();

    await this.pikaparam.conn.disconnect();

    await firstValueFrom(
      this.pikaparam.conn.deviceStateObs.pipe(
        filter(v => v === 'disconnected'),
        timeout({
          each: 5000,
          with: () =>
            throwError(
              () => new Error("Peak didn't disconnect after 5 seconds."),
            ),
        }),
      ),
    );
  }

  protected abstract writeTableColor(
    characteristicUuid: string,
    nvmIndex: number,
    moodLight: MoodLight,
    profile?: Profile | undefined,
    tableColor?: TableColor | undefined,
    shouldWriteScratchpad?: boolean | undefined,
  ): Promise<void>;

  protected async readCommand<K extends keyof P>(key: K) {
    bleLog.info(`getInApiThrow(${String(key)}).readOnce`);

    const value = await this.pikaparam.param
      // @ts-ignore
      .getInApiThrow<P[K]>(key)
      .readOnce();

    return value;
  }

  protected tryGetApi<K extends keyof P>(key: K) {
    bleLog.info(`getInApiTry(${String(key)})`);

    // @ts-ignore
    return this.pikaparam.param.getInApiTry<P[K]>(key);
  }

  protected async writeCommand<K extends keyof P>(key: K, value: P[K]) {
    bleLog.info(`getInApiThrow(${key.toString()}).set`, {value});

    // @ts-ignore
    await this.pikaparam.param.getInApiThrow(key).set(value, false);

    if (key in setters) {
      const setter = setters[key as keyof typeof setters];

      // @ts-ignore
      setter(this.attributes, value);
    }
  }

  private async createWatch<K extends keyof P>(
    property: K,
    callback?: (path: K, value: any) => void,
  ): Promise<() => void> {
    const watch = this.watchers.get(property);
    const callbacks = watch?.callbacks ?? new Set();

    // @ts-ignore
    callbacks.add(callback);

    bleLog.info('Create watch.', {property, active: callbacks.size});

    if (!watch) {
      bleLog.info('Setting up watch.', {property});

      const clean = await this.watchCommand(property, value => {
        Array.from(callbacks.values()).forEach(callback =>
          callback(property as string, value),
        );
      });

      this.watchers.set(property, {clean, callbacks});
    }

    return () => {
      bleLog.info('Remove watch.', {property, active: callbacks.size});

      // @ts-ignore
      callbacks.delete(callback);

      // Do not clean up watch - it will be cleaned up on disconnect
      // Otherwise it will create/delete the watches while navigating between screens.
    };
  }

  private async watchCommand<K extends keyof P>(
    key: K,
    callback: (value: P[K]) => void,
  ): Promise<() => void> {
    bleLog.info(`getInApiThrow(${key.toString()}).watch`, {key});

    const observable = this.pikaparam.param.getInApiThrow(key.toString()).obs;

    const subscription = observable
      .pipe(filter(isValidCodec), distinctUntilChanged())
      .subscribe(value => callback(value as P[K]));

    return () => subscription.unsubscribe();
  }

  private async cleanupWatches(): Promise<void> {
    bleLog.info(`Clean up watches.`);

    Array.from(this.watchers.entries()).forEach(([, {clean}]) => clean());

    this.watchers.clear();
  }
}
