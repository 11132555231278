import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {Store} from '../types';

const interstitialSlice = createSlice({
  name: 'viewedInterstitialIds',
  initialState: [] as number[],
  reducers: {
    addId(state, action: PayloadAction<number>) {
      const id = action.payload;
      !state.includes(id) && state.push(id);
    },
  },
});

export const viewedInterstitialIdsSelector = (state: Store) =>
  state.viewedInterstitialIds;

export const {addId} = interstitialSlice.actions;
export const interstitialReducer = interstitialSlice.reducer;
