import {useIsFocused, useNavigation} from '@react-navigation/native';
import {format, parse} from 'date-fns';
import {TemperatureUnit, TimeFilterPeriod} from 'puffco-api-axios-client';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Image,
  ScrollView,
  StyleProp,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {getTimeZone} from 'react-native-localize';
import {useDispatch, useSelector} from 'react-redux';
import {useAsync} from 'react-use';

import {
  Analytics,
  CustomCategory,
  CustomEventAction,
} from '../../../../analytics/Analytics';
import {dataUnavailable, noLightbulb} from '../../../../assets/images';
import {
  AppText,
  ImgBackground,
  Modal,
  StyledButton,
  StyledIcon,
} from '../../../../components';
import {MoodLightCircle} from '../../../../components/MoodLightCircle';
import {
  Alerts,
  Constants,
  Messages,
  Navigators,
  RECORDS,
  Screens,
  Strings,
  appColors,
} from '../../../../constants';
import {userApi} from '../../../../lib/api/apis';
import {getTimeFormat} from '../../../../lib/getTimeFormat';
import {useMoodLight} from '../../../../lib/hooks';
import {useAdaptiveSafeArea} from '../../../../lib/hooks/useAdaptiveSafeArea';
import {
  appFlagsSelector,
  updateAppFlags,
} from '../../../../lib/redux/appFlagsSlice';
import {appSettingsSelector} from '../../../../lib/redux/appSettingsSlice';
import {exclusiveMoodLightsSelector} from '../../../../lib/redux/moodLightSlice';
import {userSelector} from '../../../../lib/redux/userSlice';
import styled from '../../../../lib/styled';
import {
  AppSettingsStore,
  DabHistorySummaryDto,
  MoodLight,
  Theme,
  User,
  isExclusiveMoodLight,
} from '../../../../lib/types';
import {useTheme} from '../../../../lib/useTheme';
import {Temperature} from '../../../../lib/utils/temperature';
import {HomeEmulatedDrawerNavigatorScreenProps} from '../../../../navigation/navigators/HomeDrawerNavigator';
import {
  CaptureCompRefProps,
  CaptureContainer,
  ShareHandler,
} from '../../../../screens/components';
import {
  DabHistoryContainer,
  DabHistoryGraph,
} from './components/DabHistoryGraph';
import {DurationGraph} from './components/DurationGraph';
import {FirstViewAdvancedMetrics} from './components/FirstViewAdvancedMetrics';
import {LoadingSkeleton} from './components/LoadingSkeleton';
import {TemperatureGraph} from './components/TemperatureGraph';
import {TimeFilterRow} from './components/TimeFilterRow';
import {getStartDay} from './util/getStartDay';

const {
  DAB_HISTORY_TITLE,
  DAB_AVG_TITLE,
  AVG_DURATION_TITLE,
  POPULAR_TEMPERATURE,
  NO_MOOD_LIGHT_DATA,
  USE_MOODLIGHTS,
} = Messages;

const {TEMPERATURE_MIN_FAHRENHEIT, TEMPERATURE_MAX_FAHRENHEIT, IS_WEB} =
  Constants;

const {width} = Dimensions.get('window');

const MetricHandler = ({
  children,
}: {
  children: (data: {
    data: DabHistorySummaryDto | null;
    temp: {lowest: number; highest: number; temperature: number};
    appSettings: AppSettingsStore;
    timeFilter: TimeFilterPeriod;
    setTimeFilter: React.Dispatch<React.SetStateAction<TimeFilterPeriod>>;
    isFetching: boolean;
  }) => React.ReactNode;
}) => {
  const navigation = useNavigation<Navigation>();
  const [timeFilter, setTimeFilter] = React.useState<TimeFilterPeriod>(
    TimeFilterPeriod.M,
  );
  const {value, loading: isFetching} = useAsync(
    () =>
      userApi.getDabHistorySummary({
        id: 'me',
        timezone: getTimeZone(),
        period: timeFilter,
      }),
    [timeFilter],
  );
  const appSettings = useSelector(appSettingsSelector);
  const dispatch = useDispatch();

  const {hasSeenAdvancedMetrics} = useSelector(appFlagsSelector);

  React.useEffect(() => {
    if (hasSeenAdvancedMetrics) return;
    Modal.display({
      element: <FirstViewAdvancedMetrics />,
      onClose: () => dispatch(updateAppFlags({hasSeenAdvancedMetrics: true})),
    });
  }, [dispatch, hasSeenAdvancedMetrics]);

  const hasDabHistory = value && !isFetching && value.data.hasDabHistory;

  //Memoize result to avoid recalculate on every render
  const {lowest, highest, temperature} = React.useMemo(() => {
    const temperature = value?.data.temperatureSummaryMode
      ? //convert celsius to fahrenheight
        Temperature.convert(Number(value.data.temperatureSummaryMode), {
          from: TemperatureUnit.Celsius,
          to: TemperatureUnit.Fahrenheit,
        })
      : appSettings.tempPreference === TemperatureUnit.Fahrenheit
        ? 0
        : 32;

    return {
      lowest: Temperature.convert(TEMPERATURE_MIN_FAHRENHEIT, {
        from: TemperatureUnit.Fahrenheit,
        to: appSettings.tempPreference,
      }),
      highest: Temperature.convert(TEMPERATURE_MAX_FAHRENHEIT, {
        from: TemperatureUnit.Fahrenheit,
        to: appSettings.tempPreference,
      }),
      temperature: Temperature.convert(temperature, {
        from: TemperatureUnit.Fahrenheit,
        to: appSettings.tempPreference,
      }),
    };
  }, [appSettings.tempPreference, value?.data.temperatureSummaryMode]);

  return (
    <>
      {isFetching || hasDabHistory ? (
        children({
          // @ts-expect-error type mismatch for moodlight because of the difference between the api client types and the types in the project
          data: value?.data ?? null,
          temp: {lowest, highest, temperature},
          appSettings,
          timeFilter,
          setTimeFilter,
          isFetching,
        })
      ) : (
        <MainContainer>
          <IconContainer>
            <IconImage source={dataUnavailable} />
          </IconContainer>

          <TextContainer>
            <Title>{Messages.ADVANCED_METRICS_EMPTY_TITLE}</Title>
            <Description>{Messages.ADVANCED_METRICS_EMPTY_MSG}</Description>
          </TextContainer>

          <ButtonContainer>
            <StyledButton
              title={Strings.START_DABBING}
              style={{width: '100%', alignSelf: 'flex-end'}}
              onPress={() => {
                navigation.navigate(Navigators.HomeTabNavigator, {
                  screen: Screens.HeatProfileList,
                  params: {},
                });
              }}
            />
          </ButtonContainer>
        </MainContainer>
      )}
    </>
  );
};

interface Props
  extends HomeEmulatedDrawerNavigatorScreenProps<
    typeof Screens.AdvancedMetrics
  > {}

type Navigation = Props['navigation'];

export const AdvancedMetricsScreen = ({navigation}: Props) => {
  const currentUser = useSelector(userSelector);
  const exclusiveMoodLights = useSelector(exclusiveMoodLightsSelector);

  const isFocused = useIsFocused();

  const ref = React.useRef<CaptureCompRefProps>(null);
  const theme = useTheme();

  const {top, bottom} = useAdaptiveSafeArea();

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <ShareHandler
          load={async () => {
            const b64Img = await ref?.current?.capture();

            if (!b64Img) return;

            return {url: b64Img};
          }}
          onShareEnd={async () => {
            Analytics.shared().logCustomEventAction(
              CustomEventAction.SHARE_ADVANCED_METRICS_DASHBOARD,
              CustomCategory.SHARE,
            );
          }}
          icon={props => <StyledIcon name="shareV2" size={26} {...props} />}
          alertTitle={Alerts.ADVANCED_METRICS_SHARE_TITLE}
          alertMessage={Alerts.ADVANCED_METRICS_SHARE_MESSAGE}
          loadingMessage={Strings.PREPARING_ADVANCED_METRICS}
        />
      ),
    });
  }, [navigation]);

  return (
    <ImgBackground>
      <MetricHandler>
        {({data, timeFilter, setTimeFilter, temp, appSettings, isFetching}) => {
          if (!currentUser) return null;

          return (
            <>
              <CaptureContainer ref={ref}>
                <View
                  style={{
                    height: '100%',
                    width,
                    flex: 1,
                    backgroundColor: appColors.black,
                  }}>
                  <DabMetrics
                    exclusiveMoodLight={exclusiveMoodLights}
                    currentUser={currentUser}
                    data={data}
                    theme={theme}
                    temperatureGraph={temp}
                    appSettings={appSettings}
                    timeFilter={timeFilter}
                    isFetching={isFetching || !isFocused}
                    convertToBase64={IS_WEB}
                    moodLightStyle={{
                      overflow: 'hidden',
                    }}
                    gradientStyle={{
                      position: 'relative',
                      justifyContent: 'center',
                      overflow: 'hidden',
                      left: -2,
                      height: 64,
                      width: 64,
                    }}
                  />

                  <View
                    style={{
                      width: '100%',
                      marginTop: 14,
                    }}>
                    <TimeFilterRow timeFilter={timeFilter} />
                  </View>
                </View>
              </CaptureContainer>

              <ContentContainer style={{paddingTop: top}}>
                <ScrollView
                  style={{
                    marginTop: 64,
                  }}>
                  <DabMetrics
                    exclusiveMoodLight={exclusiveMoodLights}
                    currentUser={currentUser}
                    data={data}
                    theme={theme}
                    temperatureGraph={temp}
                    appSettings={appSettings}
                    timeFilter={timeFilter}
                    isFetching={isFetching || !isFocused}
                  />
                </ScrollView>

                <TimeFilterRow
                  timeFilter={timeFilter}
                  setTimeFilter={setTimeFilter}
                  safeAreaBottom={bottom}
                />
              </ContentContainer>
            </>
          );
        }}
      </MetricHandler>
    </ImgBackground>
  );
};

interface DabMetricsProps {
  exclusiveMoodLight: MoodLight[];
  theme: Theme;
  temperatureGraph: {
    lowest: number;
    highest: number;
    temperature: number;
  };
  appSettings: {tempPreference: TemperatureUnit};
  timeFilter: TimeFilterPeriod;
  data?: DabHistorySummaryDto | null;
  isFetching: boolean;
  currentUser: User;
  convertToBase64?: boolean;
  moodLightStyle?: StyleProp<ViewStyle>;
  gradientStyle?: ViewStyle;
}

const DabMetrics = ({
  currentUser,
  children,
  theme,
  temperatureGraph: {lowest, highest, temperature},
  appSettings,
  data,
  timeFilter,
  isFetching,
  exclusiveMoodLight,
  convertToBase64,
  moodLightStyle,
  gradientStyle,
}: React.PropsWithChildren<DabMetricsProps>) => {
  const [t, setT] = React.useState<TimeFilterPeriod>(timeFilter);
  const {accessMoodLight} = useMoodLight();
  const dabHistorySummary = data?.dabHistory ? data.dabHistory : [];
  const earliestDabDate =
    timeFilter === 'all'
      ? dabHistorySummary
          ?.map(v => {
            const parsed = parse(v.dateTimeStr, 'yyyy', new Date());
            return isNaN(parsed as any) ? new Date() : parsed;
          })
          .sort()[0]
      : undefined;
  const startAt = getStartDay(timeFilter, earliestDabDate);

  const moodLight = data?.popularMoodLight;
  const durationSummaryAvg = data?.durationSummaryAvg || undefined;
  const totalHits = data?.totalHits || 0;

  //To avoid displaying other timeperiod if it will start to fetch.
  React.useEffect(() => {
    setT(timeFilter);
  }, [timeFilter]);

  return (
    <>
      <TopContainer>
        <HorizontalContainer>
          <PeakImageContainer>
            <PeakImage source={RECORDS.THEME_RECORD.OG.peakImageTheme.peak} />
          </PeakImageContainer>

          <InfoContainer>
            <UsernameText>{currentUser.username}</UsernameText>

            {!!isFetching || t !== timeFilter ? (
              <ActivityIndicator
                size={14}
                color={appColors.white}
                style={{marginTop: 6}}
              />
            ) : (
              <DeviceCountText>{`${data?.peakCount ?? 0} ${
                (data?.peakCount ?? 0) > 1 ? Strings.DEVICES : Strings.DEVICE
              }`}</DeviceCountText>
            )}
          </InfoContainer>
        </HorizontalContainer>
      </TopContainer>

      <DabHistoryContainer
        dabHistorySummary={dabHistorySummary}
        timeFilter={timeFilter}
        allTimeDabs={totalHits}
        startDate={startAt}>
        {({data, avg, totalDabs, maximum}) => (
          <LoadingSkeleton
            isLoading={!!isFetching || !data || t !== timeFilter}
            title={<TitleText>{DAB_HISTORY_TITLE}</TitleText>}>
            <BoldText>{totalDabs.toLocaleString()}</BoldText>

            <RowContainer
              style={{
                justifyContent: 'space-between',
              }}>
              <LabelText>
                {timeFilter === 'all'
                  ? `${format(startAt, 'yyyy')} - Today`
                  : `${format(startAt, 'MMM d, yyyy')} - Today`}
              </LabelText>

              <RowContainer>
                <LabelText>{`${DAB_AVG_TITLE}: `}</LabelText>
                <AvgValText>{(avg || 0).toFixed(2)}</AvgValText>
              </RowContainer>
            </RowContainer>

            <DabHistoryGraph
              key={timeFilter}
              timeFilter={timeFilter}
              data={data}
              avg={avg}
              maximum={maximum}
            />
          </LoadingSkeleton>
        )}
      </DabHistoryContainer>

      <GraphRow>
        <LeftSectionContainer
          isLoading={!!isFetching || !data || t !== timeFilter}
          title={<TitleText>{AVG_DURATION_TITLE}</TitleText>}>
          <RowContainer style={{alignItems: 'baseline'}}>
            <SmallBoldText>
              {getTimeFormat(durationSummaryAvg ?? 0, {
                padMinutes: false,
                padSeconds: true,
              })}
            </SmallBoldText>
            <LabelText style={{top: -11, left: 2}}>{'sec'}</LabelText>
          </RowContainer>

          <DurationGraph durationSummaryAvg={durationSummaryAvg} />
        </LeftSectionContainer>

        <RightSectionContainer
          isLoading={!!isFetching || !data || t !== timeFilter}
          title={
            <TitleText style={{width: '100%'}}>{POPULAR_TEMPERATURE}</TitleText>
          }>
          <View
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <TitleText
              style={{
                width: '100%',
                paddingHorizontal: 16,
                paddingTop: 16,
              }}>
              {' '}
            </TitleText>

            <TemperatureGraph
              scale={appSettings.tempPreference}
              temperature={temperature}
              lowest={lowest}
              highest={highest}
            />
          </View>
        </RightSectionContainer>
      </GraphRow>

      {!!moodLight || isFetching ? (
        <PopularMoodLightContainer
          isLoading={!!isFetching || t !== timeFilter}
          style={{
            flexDirection: isFetching ? 'column' : 'row',
            alignItems: isFetching ? 'flex-start' : 'center',
          }}
          title={
            isFetching ? (
              <View
                style={{
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  width: '100%',
                }}>
                <PopularMoodLightTextTop>
                  {Strings.POPULAR_MOOD_LIGHTS}
                </PopularMoodLightTextTop>
              </View>
            ) : undefined
          }>
          <PopularMoodLightTextContainer>
            <PopularMoodLightTextTop>
              {Strings.POPULAR_MOOD_LIGHTS}
            </PopularMoodLightTextTop>

            {!isFetching && (
              <PopularMoodLightTextBottom
                numberOfLines={1}
                ellipsizeMode="tail">{`${moodLight?.name}`}</PopularMoodLightTextBottom>
            )}
          </PopularMoodLightTextContainer>

          {!!moodLight && (
            <GradientCircle
              style={moodLightStyle}
              moodLight={
                (isExclusiveMoodLight(moodLight) &&
                  exclusiveMoodLight.find(
                    value => value.id === moodLight.id,
                  )) ||
                moodLight
              }
              theme={theme}
              convertToBase64={convertToBase64}
              gradientStyle={gradientStyle}
            />
          )}
        </PopularMoodLightContainer>
      ) : (
        <PopularMoodLightContainer
          isLoading={!!isFetching || t !== timeFilter}
          style={{
            flexDirection: isFetching ? 'column' : 'row',
            alignItems: isFetching ? 'flex-start' : 'center',
          }}
          title={
            isFetching ? (
              <View
                style={{
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  width: '100%',
                }}>
                <PopularMoodLightTextTop>
                  {Strings.POPULAR_MOOD_LIGHTS}
                </PopularMoodLightTextTop>
              </View>
            ) : undefined
          }>
          <NoPopularMoodLightContainer>
            <Image
              source={noLightbulb}
              style={{height: 50, width: 50, marginHorizontal: 15}}
            />

            <PopularMoodLightTextContainer>
              <NoPopularMoodLightTextTop>
                {NO_MOOD_LIGHT_DATA}
              </NoPopularMoodLightTextTop>

              <NoPopularMoodLightTextBottom onPress={() => accessMoodLight({})}>
                {USE_MOODLIGHTS}
              </NoPopularMoodLightTextBottom>
            </PopularMoodLightTextContainer>
          </NoPopularMoodLightContainer>
        </PopularMoodLightContainer>
      )}

      {children}
    </>
  );
};

const TopContainer = styled(View)({
  flexDirection: 'column',
  marginTop: 8,
  marginHorizontal: 24,
});
const HorizontalContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
});

const ContentContainer = styled(View)({
  flex: 1,
  flexBasis: 0,
  backgroundColor: appColors.black,
});

const PeakImageContainer = styled(View)({
  height: 64,
  width: 64,
  backgroundColor: appColors.coal,
  borderRadius: 1000,
  alignItems: 'center',
  justifyContent: 'center',
});

const PeakImage = styled(Image)({
  height: 49,
  width: 49,
  resizeMode: 'contain',
});

const InfoContainer = styled(View)({
  marginLeft: 12,
  alignItems: 'flex-start',
});

const DeviceCountText = styled(Text)({
  color: appColors.mediumGray50,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
  lineHeight: 20,
  textTransform: 'uppercase',
  letterSpacing: 0.05,
});

const TitleText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: 14,
  lineHeight: 20,
  textTransform: 'uppercase',
  color: appColors.white50,
  letterSpacing: 0.05,
});

const UsernameText = styled(TitleText)({
  fontFamily: 'BigShouldersDisplay-Bold',
  alignSelf: 'center',
  color: appColors.baseText,
  fontSize: 18,
  textAlign: 'left',
  fontWeight: '400',
});

const RowContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
});

const BoldText = styled(Text)({
  color: appColors.baseText,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 48,
  lineHeight: 56,
  fontWeight: '400',
  letterSpacing: 0.05,
});

const SmallBoldText = styled(BoldText)({
  fontSize: 32,
});

const LabelText = styled(TitleText)({
  textTransform: 'none',
  fontSize: 12,
  lineHeight: 16,
});

const DabHistoryLabelText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  fontWeight: '400',
  lineHeight: 16,
  letterSpacing: 0.05,
  color: '#878787',
});

const AvgValText = styled(DabHistoryLabelText)({color: appColors.baseText});
const GraphRow = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
});
const LeftSectionContainer = styled(LoadingSkeleton)({
  flex: 0.5,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 24,
  marginRight: 8,
  height: 160,
});

const RightSectionContainer = styled(LoadingSkeleton)({
  flex: 0.5,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 8,
  marginRight: 24,
  height: 160,
});

const PopularMoodLightContainer = styled(LoadingSkeleton)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 12,
  backgroundColor: appColors.coal,
  height: 92,
  paddingRight: 24,
  paddingLeft: 24,
  marginTop: 14,
  marginHorizontal: 24,
});

const NoPopularMoodLightContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: -15,
});

const PopularMoodLightTextContainer = styled(View)({
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flex: 1,
});

const PopularMoodLightTextTop = styled(TitleText)({
  fontFamily: 'Roboto-Regular',
  textTransform: 'uppercase',
});

const NoPopularMoodLightTextTop = styled(Text)({
  fontSize: 16,
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  color: appColors.white,
});

const PopularMoodLightTextBottom = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 32,
  lineHeight: 40,
  color: appColors.white,
  width: 245,
  fontWeight: '400',
  letterSpacing: 0.1,
});

const NoPopularMoodLightTextBottom = styled(Text)({
  fontSize: 16,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  color: appColors.white50,
  textDecorationLine: 'underline',
  marginTop: 5,
});

const GradientCircle = styled(MoodLightCircle)({
  height: 54,
  width: 54,
  borderRadius: 1000,
  backgroundColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 2,
});

const MainContainer = styled(View)({
  flex: 1,
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: appColors.black,
});

const IconContainer = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
});

const IconImage = styled(Image)({
  resizeMode: 'contain',
  height: 200,
});

const TextContainer = styled(View)({
  textAlign: 'center',
  marginHorizontal: 40,
});

const ButtonContainer = styled(View)({
  position: 'absolute',
  bottom: 36,
  alignItems: 'center',
  width: '100%',
  paddingHorizontal: 24,
  paddingVertical: 20,
});

const Title = styled(AppText)({
  marginTop: 40,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 40,
  lineHeight: 48,
  textAlign: 'center',
  textTransform: 'uppercase',
});

const Description = styled(AppText)({
  marginTop: 20,
  fontFamily: 'Roboto-Regular',
  fontStyle: 'normal',
  fontSize: 14,
  lineHeight: 20,
  textAlign: 'center',
  color: appColors.white50,
});
