import {EmailDto, SmsOptInSubscribeDto} from 'puffco-api-axios-client';

import {
  Analytics,
  CustomCategory,
  CustomEventAction,
  CustomLabel,
} from '../../analytics/Analytics';
import {marketOptInApi} from './apis';

const subscribeToEmail = async (emailDto: EmailDto) => {
  Analytics.shared().logCustomEventAction(
    CustomEventAction.OPT_IN_ACCEPT,
    CustomCategory.OPT_IN,
    CustomLabel.OPT_IN_SERVICE,
    {service: 'email'},
  );

  return marketOptInApi.subscribeEmail({emailDto});
};

const subscribeToSms = async (smsOptInSubscribeDto: SmsOptInSubscribeDto) => {
  Analytics.shared().logCustomEventAction(
    CustomEventAction.OPT_IN_ACCEPT,
    CustomCategory.OPT_IN,
    CustomLabel.OPT_IN_SERVICE,
    {service: 'sms'},
  );

  return marketOptInApi.subscribeSms({smsOptInSubscribeDto});
};

export const marketOptInAccess = {
  subscribeToSms,
  subscribeToEmail,
};
