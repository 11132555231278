import {BLOCKS, Block, INLINES, Inline} from '@contentful/rich-text-types';
import React from 'react';
import {
  Animated,
  FlatList,
  Image,
  LayoutChangeEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {useToggle} from 'react-use';

import {faqAdd, faqSubstact} from '../../../assets/images';
import {Constants, appColors} from '../../../constants';
import {TypeFaqSkeleton} from '../../../lib/api/content-access/types';
import {sleep} from '../../../lib/sleep';
import {Hyperlink} from './Hyperlink';
import {RichTextRenderer, isEmptyParagraph} from './RichTextRenderer';

interface Props {
  faq: TypeFaqSkeleton;
  listRef: React.RefObject<FlatList>;
  selected?: string;
  select: (item: string) => void;
  index: number;
}

export const FaqDisclosure = ({
  faq,
  listRef,
  selected,
  index,
  select,
}: Props) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (
        node: Block | Inline,
        children: React.ReactNode,
      ) => {
        const {uri} = node.data;

        return (
          <Hyperlink style={styles.link} {...{uri}}>
            {children}
          </Hyperlink>
        );
      },
      [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => {
        if (isEmptyParagraph(node)) return <Text style={{height: 12}} />;

        return <Text style={styles.answerText}>{children}</Text>;
      },
    },
  };

  const [isOpen, toggle] = useToggle(false);

  const heightAnimationValue = React.useRef(new Animated.Value(0)).current;
  const contentHeight = React.useRef(0);

  React.useEffect(() => {
    if (selected !== faq.sys.id) onBlur();
  }, [selected]);

  const onPress = async () => {
    toggle();

    select(faq.sys.id);

    const toValue = isOpen ? 0 : contentHeight.current;

    // On android the previous opened content is included in the layout and it scrolls too far down if we don't wait
    if (Constants.IS_NATIVE_ANDROID) await sleep(150);

    listRef.current?.scrollToIndex({index, animated: true});

    if (Constants.IS_NATIVE_ANDROID) return;

    Animated.timing(heightAnimationValue, {
      toValue,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const onBlur = () => {
    if (isOpen) toggle();

    if (Constants.IS_NATIVE_ANDROID) return;

    Animated.timing(heightAnimationValue, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const onLayout = (event: LayoutChangeEvent) => {
    if (Constants.IS_NATIVE_ANDROID) return;

    contentHeight.current = event.nativeEvent.layout.height;

    if (isOpen) heightAnimationValue.setValue(contentHeight.current);
  };

  return (
    <Pressable
      {...{onPress}}
      style={[
        styles.container,
        {
          backgroundColor: isOpen ? appColors.darkGray : appColors.transparent,
          paddingTop: isOpen ? 26 : 24,
          marginVertical: isOpen ? -2 : 0,
        },
      ]}>
      <View style={styles.questionContainer}>
        <Text style={styles.question}>{faq.fields.question}</Text>

        <Image style={styles.icon} source={!isOpen ? faqAdd : faqSubstact} />
      </View>

      {/* TODO: Make animations and scroll work for android too */}
      {Constants.IS_WEB ? (
        <Animated.View
          style={[styles.disclosedContent, {height: heightAnimationValue}]}>
          <Text style={styles.answer} {...{onLayout}}>
            <RichTextRenderer
              content={faq.fields.answer}
              customOptions={options}
            />
          </Text>
        </Animated.View>
      ) : (
        <View style={styles.disclosedContent} {...{onLayout}}>
          {isOpen && (
            <View style={styles.answer}>
              <RichTextRenderer
                content={faq.fields.answer}
                customOptions={options}
              />
            </View>
          )}
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 26,
    paddingVertical: 24,
  },
  questionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  question: {
    flex: 1,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: appColors.white,
  },
  disclosedContent: {
    overflow: 'hidden',
  },
  answer: {
    flex: 1,
    opacity: 0.75,
    paddingTop: 20,
    paddingRight: 42,
    paddingBottom: 22,
    flexWrap: 'wrap',
  },
  answerText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: appColors.textOnSurface,
    lineHeight: 22,
  },
  icon: {
    width: 18,
    height: 18,
    marginLeft: 24,
    resizeMode: 'contain',
  },
  link: {
    fontSize: 14,
  },
});
