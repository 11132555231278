import {
  BottomTabScreenProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import {CompositeScreenProps} from '@react-navigation/native';
import React from 'react';

import {Navigators, Screens} from '../../constants';
import {
  HeatProfileListScreen,
  Props as HeatProfileListScreenProps,
} from '../../screens/main/HeatProfileListScreen';
import {HomeScreen} from '../../screens/main/HomeScreen';
import {StyledTabBar} from '../StyledTabBar';
import {HomeEmulatedDrawerStackScreenProps} from './HomeDrawerNavigator';
import {defaultNavigationOptions} from './common';
import {RedirectionParam} from './params';

export type HomeTabNavigatorParamList = {
  [Screens.Home]: RedirectionParam | undefined;
  [Screens.HeatProfileList]: HeatProfileListScreenProps;
};

export type HomeTabNavigatorScreenProps<
  T extends keyof HomeTabNavigatorParamList,
> = CompositeScreenProps<
  BottomTabScreenProps<HomeTabNavigatorParamList, T>,
  HomeEmulatedDrawerStackScreenProps<typeof Navigators.HomeTabNavigator>
>;

const HomeTab = createBottomTabNavigator<HomeTabNavigatorParamList>();

export const HomeTabNavigator = () => (
  <HomeTab.Navigator
    initialRouteName={Screens.Home}
    screenOptions={{
      ...defaultNavigationOptions,
      headerTitle: '',
    }}
    tabBar={props => <StyledTabBar {...props} />}>
    <HomeTab.Screen name={Screens.Home} component={HomeScreen} />

    <HomeTab.Screen
      name={Screens.HeatProfileList}
      component={HeatProfileListScreen}
    />
  </HomeTab.Navigator>
);
