import {combineReducers} from 'redux';
import {createTransform, persistReducer} from 'redux-persist';

import {PersistStorage} from '../../shims/PersistStorage';
import {Store} from '../types';
import {appFlagsReducer} from './appFlagsSlice';
import {appSettingsReducer} from './appSettingsSlice';
import {bleReducer} from './bleSlice';
import {interstitialScreenReducer} from './interstitialScreenSlice';
import {interstitialReducer} from './interstitialSlice';
import {moodLightReducer} from './moodLightSlice';
import {ProviderStore} from './persistor';
import {profileReducer} from './profilesSlice';
import {referralsReducer} from './referralsSlice';
import {userReducer} from './userSlice';
import {viewedChamberTypesReducer} from './viewedChamberTypesSlice';
import {warrantyReducer} from './warrantySlice';

export const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  ble: persistReducer<Store['ble']>(
    {
      key: 'ble',
      storage: PersistStorage,
      transforms: [
        createTransform(
          (state: Store['ble']['devices'], key) => {
            if (key !== 'devices') return state;

            return state.map(
              ({battery, batteryChargeState, approxDabsRemainingCount, ...d}) =>
                d,
            );
          },
          undefined,
          {},
        ),
      ],
      blacklist: [
        'connectedDevice',
        'connecting',
        'canReconnect',
      ] as (keyof Store['ble'])[],
    },
    bleReducer,
  ),
  appFlags: appFlagsReducer,
  appSettings: appSettingsReducer,
  moodLight: moodLightReducer,
  warranty: warrantyReducer,
  referrals: referralsReducer,
  viewedChamberTypes: viewedChamberTypesReducer,
  viewedInterstitialIds: interstitialReducer,
  interstitialScreen: interstitialScreenReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof ProviderStore.dispatch;

export const stateSelector = (state: Store) => state;
