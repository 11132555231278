import {Alerts, Strings} from '../../../constants';
import {appLog} from '../../../lib/Logger';
import {sleep} from '../../../lib/sleep';
import {Alert} from '../../../shims/alert';
import {PermissionError} from '../PermissionError';
import {settingsService} from '../settings';
import {BaseBluetoothService} from './BaseBluetoothService';
import {IBluetoothService} from './IBluetoothService';

const navigator: Puffco.Navigator = window.navigator;

class BrowserBluetoothService implements IBluetoothService {
  required = false;

  async enabled(): Promise<boolean> {
    return true;
  }

  async enable(): Promise<void> {}

  async granted(): Promise<boolean> {
    return true;
  }

  async request(): Promise<void> {}
}

class PathBluetoothService
  extends BaseBluetoothService
  implements IBluetoothService
{
  constructor(
    private services: Puffco.Services,
    private permissions: Puffco.Permissions,
  ) {
    super();
  }
  required = true;

  async enabled(): Promise<boolean> {
    appLog.info('Check if bluetooth service is enabled.');

    const enabled = this.services.hasBluetoothServiceEnabled();

    appLog.info('Bluetooth service state.', {enabled});

    return enabled;
  }

  protected async enable(): Promise<void> {
    appLog.info('Enable bluetooth service.');

    await this.services.promptBleService?.();
  }

  async granted(): Promise<boolean> {
    appLog.info('Check bluetooth permissions are granted.');

    const {state} = await this.permissions.query({name: 'bluetooth'});

    appLog.info('Bluetooth permissions state.', {state});

    return state === 'granted';
  }

  protected async requestPermissions(): Promise<void> {
    appLog.info('Request bluetooth permissions.');

    const {state} = await this.permissions.query({name: 'bluetooth'});

    appLog.info('Bluetooth permissions result.', {state});

    if (state === 'granted')
      return appLog.info('Bluetooth permissions are granted.');

    if (state === 'prompt') {
      await this.waitForActive({
        onRequest: async () => {
          // This promise won't ever resolve/reject so we will wait
          // until the app becomes active again.
          this.permissions.request?.({name: 'bluetooth'}).catch(() => void 0);
          await sleep(500);
        },
        onCheck: async () => {
          const enabled = await this.granted();

          if (enabled) return;

          throw new Error(PermissionError.BluetoothDenied);
        },
      });
      return;
    }

    await new Promise<never>((_, reject) => {
      Alert.alert(
        Alerts.permissions.ios.bluetooth.denied.title,
        Alerts.permissions.ios.bluetooth.denied.body,
        [
          {
            text: Strings.CANCEL,
            style: 'cancel',
            onPress: () => reject(new Error(PermissionError.BluetoothCanceled)),
          },
          {
            text: Strings.SETTINGS,
            onPress: async () => {
              try {
                await settingsService.openBluetooth();
                throw new Error(PermissionError.BluetoothRequiresAction);
              } catch (error) {
                reject(error);
              }
            },
          },
        ],
        {
          onDismiss: () =>
            reject(new Error(PermissionError.BluetoothDismissed)),
        },
      );
    });
  }
}

export const bluetoothService: IBluetoothService =
  navigator.services && navigator.permissions?.request
    ? new PathBluetoothService(navigator.services, navigator.permissions)
    : new BrowserBluetoothService();
