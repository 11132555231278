import React from 'react';
import {LayoutRectangle} from 'react-native';

import {Constants} from '../constants';
import {Analytics, CustomCategory, CustomEventAction} from './Analytics';

/*
 * Hook that tracks iOS web user screen viewing size.
 */
export function useSendScreenInfoAnalytics(
  screenHeight: number,
  viewLayout: LayoutRectangle | undefined,
) {
  const [sentLayoutAnalytics, setSentLayoutAnalytic] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (viewLayout && Constants.IS_WEB && !sentLayoutAnalytics) {
      Analytics.shared().logCustomEventAction(
        screenHeight === viewLayout.height
          ? CustomEventAction.FULL_VIEW
          : CustomEventAction.BROWSER_VIEW,
        CustomCategory.INFO,
        undefined,
        viewLayout.height,
      );
      setSentLayoutAnalytic(true);
    }
  }, [viewLayout]);
}
