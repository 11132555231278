import {ProductName} from 'pikaparam';

import {ChamberType, MoodType, Theme} from '../lib/types';
import {
  blackPeachTheme,
  defaultTheme,
  desertTheme,
  flourishTheme,
  guardianTheme,
  indiglowTheme,
  opalTheme,
  whitePeachTheme,
} from '../themes';

/* Only create records if all possible keys are defined for the key type, or there is a
 * guarantee that only the defined keys will be used. Otherwise, create a Dictionary
 * instead. Should also think about the likelihood of changes to the key type in the
 * future before creating a Record since this can cause errors when old Android app
 * versions connect to Peaks with data from a newer Android app.
 */

type Records = {
  CHAMBER_NAME_RECORD: Record<ChamberType, string>;
  MOOD_TYPE_NAME_RECORD: Record<MoodType, string>;
  THEME_RECORD: Record<ProductName, Theme>;
};

export const RECORDS: Records = {
  CHAMBER_NAME_RECORD: {
    [ChamberType.None]: '',
    [ChamberType.Classic]: '',
    [ChamberType.XL]: 'XL',
    [ChamberType.Performance]: '3D CHAMBER',
  },
  MOOD_TYPE_NAME_RECORD: {
    [MoodType.NO_ANIMATION]: 'Static',
    [MoodType.DISCO]: 'Disco',
    [MoodType.FADE]: 'Fade',
    [MoodType.SPIN]: 'Spin',
    [MoodType.SPLIT_GRADIENT]: 'Split',
    [MoodType.VERTICAL_SLIDESHOW]: 'Fill',
    [MoodType.TORNADO]: 'Tornado',
    [MoodType.BREATHING]: 'Breathing',
    [MoodType.CIRCLING_SLOW]: 'Circling Slow',
    [MoodType.LAVA_LAMP]: 'Lava Lamp',
    [MoodType.CONFETTI]: 'Confetti',
  },
  THEME_RECORD: {
    Guardian: guardianTheme,
    Indiglow: indiglowTheme,
    Opal: opalTheme,
    OG: defaultTheme,
    Pearl: whitePeachTheme,
    Onyx: blackPeachTheme,
    Desert: desertTheme,
    Flourish: flourishTheme,
    // TODO: Add the theme when we have the assets
    Storm: blackPeachTheme,
  },
};
