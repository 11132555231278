import React from 'react';

import {Constants} from '../../constants';

const {IS_ANDROID_WEB, IS_IOS_WEB, IS_USING_APP} = Constants;

/* Used to attempt to open the Puffco Connect app, or the web app in the Path browser. */
export const useOpenApp = (url: string) => {
  React.useEffect(() => openApp(url), [url]);
};

export const openApp = (url: string) => {
  if (IS_USING_APP) return;

  if (url.length > 0 && !url.startsWith('/')) url = `/${url}`;

  if (IS_ANDROID_WEB) {
    window.location.href = `puffco-connect://${url}`;
    return;
  }

  if (!window?.location) return;

  const fullUrl = `${window.location.protocol}//${window.location.host}${url}`;

  if (IS_IOS_WEB) {
    window.location.href = `path-web-fullscreen://${fullUrl}`;
    return;
  }
};
