import React from 'react';
import {LayoutChangeEvent, View, ViewStyle} from 'react-native';

import {
  PeakImage,
  STYLED_BUTTON_DEFAULT_HEIGHT,
  StyledButton,
  SwitchableBackground,
} from '../../components';
import {Constants, Screens, appColors} from '../../constants';
import {useBackPress} from '../../lib/hooks';
import styled from '../../lib/styled';
import {ChamberType, Theme} from '../../lib/types';
import {useSafeArea} from '../../lib/useSafeArea';
import {useTheme} from '../../lib/useTheme';
import {MainNavigatorScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {RedirectionParam} from '../../navigation/navigators/params';
import {Image} from '../../shims/ImageWithFilter';
import {desertTheme, flourishTheme} from '../../themes';
import {Info, Title} from './components';

const BUTTON_BOTTOM_MARGIN = 30;
const SCREEN_HORIZONTAL_PADDING = 16;
const SCREEN_VERTICAL_PADDING = 22;

const onBackPress = () => true;

export interface Props extends RedirectionParam {}

type ScreenProps = MainNavigatorScreenProps<typeof Screens.LimitedEditionModal>;

export const LimitedEditionModalScreen = ({route, navigation}: ScreenProps) => {
  const {top, bottom} = useSafeArea();
  const [peakImageSize, setPeakImageSize] = React.useState({
    height: 0,
    y: 0,
  });

  const currentTheme = useTheme();

  const {
    limitedEditionModalScreenTheme,
    primaryColor,
    styledButtonTheme,
  }: Theme = currentTheme;
  const {backgroundImage, groundLayerImage, groundLayer} =
    limitedEditionModalScreenTheme || {};

  useBackPress(onBackPress);

  const setImageDimensions = (e: LayoutChangeEvent) => {
    const {height, y} = e.nativeEvent.layout;
    setPeakImageSize({height, y});
  };

  const makeGroundLayerStyle = () => {
    if (!groundLayer) {
      // use old logic if no groundLayer theme values are defined
      return {
        bottom: -(BUTTON_BOTTOM_MARGIN + STYLED_BUTTON_DEFAULT_HEIGHT),
      };
    }
    if (peakImageSize.height === 0) {
      // hide Ground Layer until Peak Image is rendered
      return {width: 0, height: 0};
    }

    const {PEAK_IMAGE_ORIGINAL_DIMENSIONS} = Constants;

    const scaledPeakImageSizeWidth =
      peakImageSize.height *
      (PEAK_IMAGE_ORIGINAL_DIMENSIONS.width /
        PEAK_IMAGE_ORIGINAL_DIMENSIONS.height);
    const scaledGroundLayerImageWidth =
      scaledPeakImageSizeWidth *
      groundLayer.groundLayerImageWidthToPeakImageWidthRatio;
    const scaledGroundLayerImageHeight =
      scaledGroundLayerImageWidth *
      (groundLayer.groundLayerImageOriginalHeight /
        groundLayer.groundLayerImageOriginalWidth);
    const groundLayerImageTop =
      peakImageSize.y +
      peakImageSize.height * groundLayer.normalizedPeakImageYOffset -
      scaledGroundLayerImageHeight * groundLayer.normalizedGroundLayerYOffset;

    return {
      top: groundLayerImageTop,
      width: scaledGroundLayerImageWidth,
      height: scaledGroundLayerImageHeight,
    };
  };

  return (
    <SafeAreaContainer
      style={{
        paddingTop: top + SCREEN_VERTICAL_PADDING,
        paddingBottom: bottom + SCREEN_VERTICAL_PADDING,
      }}>
      <ModalContainer>
        <SwitchableBackground
          isGradient={
            currentTheme === desertTheme || currentTheme === flourishTheme
          }
          isDesertTheme={currentTheme === desertTheme}
          isFlourishTheme={currentTheme === flourishTheme}
          background={backgroundImage}
          gradientArr={
            limitedEditionModalScreenTheme?.gradientBackground?.gradientArray
          }
          backgroundColor={
            limitedEditionModalScreenTheme?.gradientBackground?.backgroundColor
          }
          style={SwitchableBackgroundContainerStyle}>
          <TitleText style={{color: primaryColor}}>
            Limited Edition Found!
          </TitleText>

          <InfoText style={{color: primaryColor}}>
            You just accessed a Limited Edition app experience!
          </InfoText>

          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}>
            {groundLayerImage && (
              <GroundLayerContainer style={makeGroundLayerStyle()}>
                <Image
                  source={groundLayerImage}
                  resizeMode={'cover'}
                  style={{flex: 1, width: '100%'}}
                />
              </GroundLayerContainer>
            )}

            <PeakImage
              colorSource={appColors.black}
              style={{
                height: '80%',
                width: '100%',
              }}
              onLayout={setImageDimensions}
              chamberType={ChamberType.Classic}
            />
          </View>

          <StyledButton
            title={'UNLOCK'}
            style={{
              alignSelf: 'center',
              marginBottom: BUTTON_BOTTOM_MARGIN,
            }}
            theme={styledButtonTheme}
            onPress={() =>
              navigation.navigate(Screens.LimitedEditionVideo, {
                redirect: route.params?.redirect,
              })
            }
          />
        </SwitchableBackground>
      </ModalContainer>
    </SafeAreaContainer>
  );
};

const SafeAreaContainer = styled(View)({
  display: 'flex',
  flex: 1,
  backgroundColor: appColors.black,
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  paddingLeft: SCREEN_HORIZONTAL_PADDING,
  paddingRight: SCREEN_HORIZONTAL_PADDING,
});

const ModalContainer = styled(View)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  borderRadius: 20,
  overflow: 'hidden',
});

const SwitchableBackgroundContainerStyle: ViewStyle = {
  width: '100%',
  height: '100%',
};

const GroundLayerContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  top: '65%',
  bottom: -(BUTTON_BOTTOM_MARGIN + STYLED_BUTTON_DEFAULT_HEIGHT),
});

const TitleText = styled(Title)({
  marginLeft: 20,
  marginRight: 20,
  marginTop: 20,
});

const InfoText = styled(Info)({
  marginLeft: 20,
  marginRight: 20,
});
