import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {backgroundMetricsAlertScreen} from '../../assets/images';
import {Alert} from '../../components';
import {Alerts, Messages, Navigators, Screens, Strings} from '../../constants';
import {InterstitialScreen} from '../../lib/InterstitialManager';
import {currentDeviceSWRevisionSelector} from '../../lib/redux/bleSlice';
import {updateSeen} from '../../lib/redux/interstitialScreenSlice';
import {userSelector} from '../../lib/redux/userSlice';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {HomeEmulatedDrawerNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {RedirectionParameter} from '../../navigation/navigators/params';
import {ImageAndContentContainer} from '../components';

interface Props
  extends HomeEmulatedDrawerNavigatorScreenProps<typeof Screens.MetricsAlert> {}

export const MetricsAlertScreen = ({navigation}: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return (
    <ImageAndContentContainer
      image={backgroundMetricsAlertScreen}
      header={{content: Messages.METRICS_SCREEN_ALERT_TITLE}}
      body={{
        content: Messages.METRICS_SCREEN_ALERT_MSG,
        style: {textAlign: 'left'},
      }}
      primaryButton={{
        title: Strings.CHECK_IT_OUT.toUpperCase(),
        onClick: () => {
          dispatch(updateSeen({screen: Screens.MetricsAlert}));
          if (user) navigation.navigate(Screens.AdvancedMetrics);
          else
            Alert.alert(Alerts.ACCESS_DENIED, Alerts.FEATURE_ACCESS_MESSAGE_2, [
              {
                text: Strings.SIGN_IN,
                onPress: () =>
                  navigation.navigate(Screens.Login, {
                    redirect: new RedirectionParameter(
                      Navigators.MainNavigator,
                      {
                        screen: Navigators.HomeDrawerNavigator,
                        params: {
                          screen: Navigators.HomeEmulatedDrawer,
                          params: {
                            screen: Screens.MetricsAlert,
                          },
                        },
                      },
                    ).encode(),
                  }),
              },
              {
                text: Strings.CREATE_ACCOUNT,
                onPress: () =>
                  navigation.navigate(Screens.Register, {
                    redirect: new RedirectionParameter(
                      Navigators.MainNavigator,
                      {
                        screen: Navigators.HomeDrawerNavigator,
                        params: {
                          screen: Navigators.HomeEmulatedDrawer,
                          params: {
                            screen: Screens.MetricsAlert,
                          },
                        },
                      },
                    ).encode(),
                  }),
              },
              {
                text: Strings.CANCEL,
                style: 'cancel',
              },
            ]);
        },
      }}
      secondaryButton={{
        onClick: () => {
          dispatch(updateSeen({screen: Screens.MetricsAlert}));
          navigation.navigate(Navigators.HomeTabNavigator, {
            screen: Screens.Home,
          });
        },
      }}
    />
  );
};

export const MetricsInterstitial: InterstitialScreen = {
  screen: Screens.MetricsAlert,
  conditionMet: ({lastSeenTime, state, initializeTime}) => {
    if (
      !initializeTime ||
      lastSeenTime ||
      state.appFlags.hasSeenMetricsAlertScreen
    )
      return false;
    const deviceSWRevision = currentDeviceSWRevisionSelector(state);
    return meetsMinimumFirmware(deviceSWRevision, 'AC');
  },
  startDate: new Date('2023-03-25'),
};
