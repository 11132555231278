import React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {appColors} from '../../../constants';
import {TypeTagSkeleton} from '../../../lib/api/content-access/types';
import {Tag} from './Tag';

export interface Props {
  title: string;
  tags: TypeTagSkeleton[];
  selectedTag?: TypeTagSkeleton;
  onPress: (tag: TypeTagSkeleton) => void;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
}

export const TagFilter = ({
  tags,
  selectedTag,
  onPress,
  title,
  style,
  titleStyle,
}: Props) => (
  <View style={[{paddingBottom: 23}, style]}>
    <Text style={[styles.title, titleStyle]}>{title}</Text>

    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingHorizontal: 20,
        margin: -3,
        flexGrow: 1,
      }}>
      {tags.map(item => (
        <Tag
          key={item.sys.id}
          tag={item.fields.name}
          selected={item.sys.id === selectedTag?.sys.id}
          onPress={() => onPress(item)}
          style={{margin: 3}}
        />
      ))}
    </View>
  </View>
);

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontWeight: '400',
    color: appColors.white,
    opacity: 0.5,
    paddingHorizontal: 20,
    marginBottom: 16,
  },
});
