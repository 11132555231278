import React from 'react';

import {useGetAppState} from './useGetAppState';

//Hook that calls callback functions when the app state switches between active and background
export function useAppState(
  activeCallback: () => void,
  backgroundCallback: () => void,
) {
  const appState = useGetAppState();

  React.useEffect(() => {
    if (appState === 'active') {
      activeCallback();
    } else if (appState === 'background') {
      backgroundCallback();
    }
  }, [appState]);
}
