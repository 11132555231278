import React from 'react';
import {Dimensions, View} from 'react-native';
import {useSelector} from 'react-redux';

import {pulse, radiate, spin} from '../../assets/images';
import {
  BlurredBackgroundSafeArea,
  CloseButton,
  ColorPicker,
  LanternTypeButton,
} from '../../components';
import {Messages, Screens, appColors} from '../../constants';
import {useAppDispatch, useBackPress} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  connectedPeakSelector,
  currentDeviceSelector,
  currentDeviceSettingsSelector,
  updateDeviceSettings,
} from '../../lib/redux/bleSlice';
import {setLanternMoodLightId} from '../../lib/redux/moodLightSlice';
import styled from '../../lib/styled';
import {LightingPattern} from '../../lib/types';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {useTheme} from '../../lib/useTheme';
import {useThrottle} from '../../lib/useThrottle';
import {ControlCenterNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {desertTheme} from '../../themes';
import {InfoText} from './components/InfoText';

const PARTY_MODE_INITIAL_COLOR = '#ff2000';

interface Props
  extends ControlCenterNavigatorScreenProps<typeof Screens.LanternMode> {}

export const LanternModeScreen = ({navigation}: Props) => {
  useDisconnectGuard();
  const theme = useTheme();
  const {navMenuIconColor, navMenuTitleStyle, controlCenterTheme} = theme;

  const peak = useSelector(connectedPeakSelector);
  const deviceSettings = useSelector(currentDeviceSettingsSelector);
  const currentDevice = useSelector(currentDeviceSelector);
  const lanternColor = deviceSettings?.lanternColor ?? appColors.defaultColor;
  const lanternPattern = deviceSettings?.lanternPattern;
  const isPartyMode = deviceSettings?.partyMode || false;
  const dispatch = useAppDispatch();
  const colorPickerContainerDiameter = Dimensions.get('window').width - 60;
  const isDesert = theme === desertTheme;

  useAdaptiveSafeArea();

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: 'LANTERN MODE',
      headerTitleStyle: {
        ...navMenuTitleStyle,
        ...(isDesert && {color: appColors.white}),
      },
      headerLeft: () => null,
      headerRight: () => (
        <CloseButton
          onPress={() => navigation.replace(Screens.ControlCenter)}
          iconStyle={{tintColor: isDesert ? appColors.white : navMenuIconColor}}
        />
      ),
    });
  }, [navigation, navMenuTitleStyle, isDesert, navMenuIconColor]);

  React.useEffect(() => {
    peak?.startLantern().finally(async () => {
      if (currentDevice) {
        dispatch(
          updateDeviceSettings({
            syncUserLanternPreference: true,
            ...currentDevice,
            settings: {
              ...currentDevice.settings,
              lanternMode: true,
              lanternColor:
                isPartyMode && lanternColor === PARTY_MODE_INITIAL_COLOR
                  ? appColors.defaultColor
                  : lanternColor,
            },
          }),
        );
        if (!isPartyMode) await updateColor(lanternColor);
      }
    });
  }, [peak]);

  useBackPress(
    React.useCallback(() => {
      navigation.replace(Screens.ControlCenter);
      return true;
    }, [navigation]),
  );

  const updateColor = React.useCallback(
    async (color: string) => {
      await peak?.writeLanternColor(color, lanternPattern);
      currentDevice &&
        dispatch(
          updateDeviceSettings({
            syncUserLanternPreference: true,
            id: currentDevice?.id,
            settings: {
              lanternColor: color,
              partyMode: false,
            },
          }),
        );
      dispatch(setLanternMoodLightId());
    },
    [dispatch, peak, lanternPattern],
  );

  const throttledUpdateColor = useThrottle(updateColor, 500);

  const updatePattern = async (
    pattern: LightingPattern,
    isPartyMode?: boolean,
  ) => {
    await peak?.writeLanternColor(lanternColor, pattern);

    if (currentDevice) {
      dispatch(
        updateDeviceSettings({
          syncUserLanternPreference: true,
          ...currentDevice,
          settings: {
            lanternPattern: pattern,
            partyMode: !!isPartyMode,
          },
        }),
      );
    }
    dispatch(setLanternMoodLightId());
  };

  return (
    <BlurredBackgroundSafeArea
      style={{backgroundColor: controlCenterTheme.blurBackgroundColor}}>
      <UpperContainer>
        <InfoLabel>{Messages.DEVICE_SETTINGS_LANTERN_MODE}</InfoLabel>
      </UpperContainer>
      <ColorPickerContainer>
        <ColorPicker
          diameter={colorPickerContainerDiameter}
          initialColor={
            //Initial color is white if there is no lantern color or party mode
            //is on and the initial color is changed because of party mode
            !lanternColor ||
            (isPartyMode && lanternColor === PARTY_MODE_INITIAL_COLOR)
              ? appColors.defaultColor
              : lanternColor
          }
          onValueChange={throttledUpdateColor}
        />
      </ColorPickerContainer>
      <LowerContainer>
        <LanternTypeButton
          onPress={async () => {
            await updatePattern(
              lanternPattern === LightingPattern.BREATHING
                ? LightingPattern.STEADY
                : LightingPattern.BREATHING,
            );
          }}
          isActive={lanternPattern === LightingPattern.BREATHING}
          onIconSource={pulse}
          iconWidth={28}
          iconHeight={21}
        />
        <LanternTypeButton
          onPress={async () => {
            await updatePattern(
              lanternPattern === LightingPattern.CIRCLING
                ? LightingPattern.STEADY
                : LightingPattern.CIRCLING,
            );
          }}
          isActive={lanternPattern === LightingPattern.CIRCLING}
          onIconSource={spin}
          iconWidth={30}
          iconHeight={24}
        />
        <LanternTypeButton
          onPress={async () => {
            await updatePattern(LightingPattern.STEADY, !isPartyMode);
            if (!isPartyMode) {
              await peak?.setPartyMode();
            }
          }}
          isActive={isPartyMode}
          onIconSource={radiate}
          iconWidth={24}
          iconHeight={29}
        />
      </LowerContainer>
    </BlurredBackgroundSafeArea>
  );
};

const UpperContainer = styled(View)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  alignItems: 'center',
  paddingHorizontal: '3%',
});

const ColorPickerContainer = styled(View)({
  display: 'flex',
  flex: 3,
  alignItems: 'center',
  justifyContent: 'center',
});

const LowerContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '100%',
  height: 100,
  alignItems: 'flex-start',
  paddingHorizontal: '6%',
  flex: 0.5,
  paddingBottom: 30,
});

const InfoLabel = styled(InfoText)({
  width: '90%',
  marginTop: 60,
});
