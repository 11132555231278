import {createAsyncThunk} from '@reduxjs/toolkit';

import {bluetoothService} from '../../../src/services/bluetooth';
import {locationService} from '../../../src/services/location';
import {bleLog} from '../../Logger';
import {bleManager} from '../../ble2/v2/BleManager/BleManager';
import {Store} from '../../types';
import {
  canReconnectSelector,
  connectedPeakSelector,
  connectingSelector,
  currentDeviceSelector,
} from '../bleSlice';
import {bleReconnectDevice} from './bleReconnectDevice';

interface ReconnectArgs {
  timeout?: number;
  onBootloader(): Promise<void>;
}

export const bleReconnect = createAsyncThunk<void, ReconnectArgs>(
  'ble/reconnect',
  async ({timeout, onBootloader}, {dispatch, getState}) => {
    const state = getState() as Store;
    const device = currentDeviceSelector(state);
    const connecting = connectingSelector(state);
    const canReconnect = canReconnectSelector(state);

    if (connecting) {
      bleLog.info('Reconnect skipped. Another connection is in progress.');
      return;
    }

    if (!canReconnect) {
      bleLog.info('Reconnect skipped. Cannot reconnect at this moment.');
      return;
    }

    if (!device?.id) {
      bleLog.info('Reconnect skipped. No current device found.');
      return;
    }

    const peak = connectedPeakSelector(state);

    await locationService.request();
    await bluetoothService.request();

    if (peak) {
      const connected = await bleManager.isConnected(peak.peripheralId);

      if (connected) {
        bleLog.info('Reconnect skipped. Peak already connected.');
      }

      bleLog.info('Peak found but not connected.');
    }

    await dispatch(
      bleReconnectDevice({
        peripheralId: device.id,
        name: device.euid,
        timeout,
        onBootloader,
      }),
    ).unwrap();
  },
);
