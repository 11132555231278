import {
  DefaultTheme,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import React from 'react';
import {ActivityIndicator, Platform, View} from 'react-native';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Provider, useSelector} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import {AppStateSensor} from './AppStateSensor';
import {Analytics} from './analytics/Analytics';
import borealis from './assets/b64_images/borealis_base64.json';
import {
  BottomSheetProvider,
  ModalProvider,
  SpinnerProvider,
} from './components';
import {StatusBar} from './components/StatusBar';
import {Navigators, ScreenNames, Screens, fillStyle} from './constants';
import {Auth} from './contexts/auth';
import {appLog} from './lib/Logger';
import NavigationService, {linking} from './lib/NavigationService';
import {copyImgToCacheAsync} from './lib/copyImgToCacheAsync';
import {usePushNotification} from './lib/hooks';
import {useDeeplinking} from './lib/hooks/useDeeplinking';
import {currentDeviceIdSelector} from './lib/redux/bleSlice';
import {Persistor, ProviderStore} from './lib/redux/persistor';
import styled from './lib/styled';
import {showBootloaderAlert} from './lib/utilityFunctions/showBootloaderAlert';
import {
  RootStackNavigator,
  RootStackParamList,
} from './navigation/navigators/RootStackNavigator';
import {
  optionsToRoute,
  toBluetoothStartPairingRedirect,
  toHome,
} from './navigation/navigators/util';
import {GoogleAuthProvider} from './shims/GoogleAuthProvider';
import {ErrorBoundary, registerAppContainer} from './shims/sentry';

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

const AppWithAuth: React.FC<React.PropsWithChildren> = ({children}) => {
  const hasDevice = !!useSelector(currentDeviceIdSelector);

  return (
    <GoogleAuthProvider>
      <Auth.Provider
        initialState={{
          onLogout: () => {
            const redirect = hasDevice
              ? toHome
              : toBluetoothStartPairingRedirect.value;

            const route = optionsToRoute(redirect);

            const instance = NavigationService.instance();

            // TODO: Why do we need to call resetRoot multiple times to take effect?
            // E.g. on referral screen, we have to reset twice to navigate back to `redirect`
            const reset = (index: number) => {
              const schedule = (): void => {
                instance?.removeListener('state', schedule);

                if (index < 0) return;

                reset(index - 1);
              };

              instance?.addListener('state', schedule);
              instance?.resetRoot({index, routes: [route]});
            };

            reset(instance?.getState().index ?? 0);
          },
        }}>
        {children}
      </Auth.Provider>
    </GoogleAuthProvider>
  );
};

const InnerApp: React.FC = () => {
  usePushNotification();
  useDeeplinking();

  return (
    <AppStateSensor
      onBootloader={async () => {
        showBootloaderAlert(() =>
          NavigationService.instance()?.navigate(Navigators.MainNavigator, {
            screen: Navigators.HomeDrawerNavigator,
            params: {
              screen: Navigators.HomeEmulatedDrawer,
              params: {screen: Screens.FirmwareUpdating},
            },
          }),
        );
      }}
    />
  );
};

export const App: React.FC = () => {
  const navigationRef = useNavigationContainerRef<RootStackParamList>();
  const [activeRouteName, setActiveRouteName] = React.useState<ScreenNames>();

  React.useEffect(() => {
    const load = async () => {
      if (Platform.OS === 'android') {
        await copyImgToCacheAsync(borealis, 'borealis');
      }
    };
    load();
  }, []);

  React.useEffect(() => {
    NavigationService.setTopLevelNavigator(navigationRef);

    registerAppContainer(navigationRef);
  }, [navigationRef]);

  return (
    <ErrorBoundary>
      <Provider store={ProviderStore}>
        <PersistGate loading={<ActivityIndicator />} persistor={Persistor}>
          <AppWithAuth>
            <GestureHandlerRootView style={{flex: 1}}>
              <SafeAreaProvider>
                <CenteredContainer>
                  <FillView>
                    <StatusBar {...{activeRouteName}} />

                    <NavigationContainer
                      ref={navigationRef}
                      {...{theme, linking}}
                      onReady={() => {
                        NavigationService.setTopLevelNavigator(navigationRef);

                        setActiveRouteName(
                          navigationRef.current?.getCurrentRoute()?.name as
                            | ScreenNames
                            | undefined,
                        );
                      }}
                      onStateChange={() => {
                        const previous = activeRouteName;

                        const current = navigationRef.current?.getCurrentRoute()
                          ?.name as ScreenNames | undefined;

                        setActiveRouteName(current);

                        appLog.info('Navigate', {previous, current});

                        if (!current || previous === current) return;

                        Analytics.shared()
                          .logScreenView(current)
                          .catch(() => void 0);
                      }}
                      onUnhandledAction={action => {
                        appLog.error('Unhandled navigation action.', {action});
                      }}>
                      <ModalProvider>
                        <RootStackNavigator />
                      </ModalProvider>
                    </NavigationContainer>

                    <BottomSheetProvider />
                  </FillView>

                  <InnerApp />

                  <SpinnerProvider />
                </CenteredContainer>
              </SafeAreaProvider>
            </GestureHandlerRootView>
          </AppWithAuth>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

const FillView = styled(View)(fillStyle);

const CenteredContainer = styled(View)({
  ...fillStyle,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
