import React from 'react';
import {useSelector} from 'react-redux';

import {pushService} from '../../src/services/push';
import {appLog} from '../Logger';
import {deviceTokenApi} from '../api/apis';
import {appSettingsSelector} from '../redux/appSettingsSlice';
import {userSelector} from '../redux/userSlice';

export const usePushNotification = () => {
  const {agreeAgreement} = useSelector(appSettingsSelector);
  const user = useSelector(userSelector);

  const agreedLegal = agreeAgreement.PP && agreeAgreement.TC;
  const turnedOff = user?.pushNotifications === false;
  const shouldAsk = agreedLegal && !turnedOff;

  React.useEffect(() => {
    if (!shouldAsk) return;

    pushService.getToken().then(token => {
      if (!token) return;
      appLog.info('Sync device token.');
      return deviceTokenApi.updateDeviceToken({token});
    });

    return pushService.onTokenUpdated(token => {
      appLog.info('Sync device token.');
      return deviceTokenApi.updateDeviceToken({token});
    });
  }, [shouldAsk]);
};
