import React from 'react';
import {useDispatch} from 'react-redux';

import {backgroundXLUnlockedAlertScreen} from '../../assets/images';
import {
  Constants,
  Messages,
  Navigators,
  Screens,
  Strings,
} from '../../constants';
import {InterstitialScreen} from '../../lib/InterstitialManager';
import {useWindowHeight} from '../../lib/hooks';
import {updateSeen} from '../../lib/redux/interstitialScreenSlice';
import {ChamberType} from '../../lib/types';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {ImageAndContentContainer} from '../components';

const {MINIMUM_FIRMWARE_VERSION} = Constants;
const {XL_UNLOCKED_ALERT_SCREEN_TITLE, XL_UNLOCKED_ALERT_SCREEN_MSG} = Messages;

interface Props
  extends HomeEmulatedDrawerStackScreenProps<typeof Screens.XLUnlockedAlert> {}

export const XLUnlockedAlertScreen = ({navigation}: Props) => {
  const dispatch = useDispatch();
  const {windowHeight} = useWindowHeight();

  const primaryButton = {
    title: Strings.DEFAULT_BTN.toUpperCase(),
    onClick: () => {
      dispatch(updateSeen({screen: Screens.XLUnlockedAlert}));
      navigation.navigate(Navigators.HomeTabNavigator, {
        screen: Screens.Home,
      });
    },
  };

  return (
    <ImageAndContentContainer
      image={backgroundXLUnlockedAlertScreen}
      imageContainerStyle={{
        maxHeight: windowHeight / 2,
      }}
      header={{content: XL_UNLOCKED_ALERT_SCREEN_TITLE}}
      body={{
        content: XL_UNLOCKED_ALERT_SCREEN_MSG,
        style: {textAlign: 'left'},
      }}
      footerContainerStyle={{marginTop: 'auto'}}
      primaryButton={primaryButton}
    />
  );
};

export const XLUnlockedInterstitial: InterstitialScreen = {
  screen: Screens.XLUnlockedAlert,
  autoInitialize: true,
  conditionMet: ({
    lastSeenTime,
    state,
    hasChamberType,
    meetFirmwareVersion,
  }) => {
    if (lastSeenTime || state.appFlags.hasSeenXLUnlockedAlertScreen)
      return false;

    return (
      hasChamberType(ChamberType.XL) &&
      meetFirmwareVersion(MINIMUM_FIRMWARE_VERSION.XL_CHAMBER)
    );
  },
  retriggerField: 'chamberType',
  startDate: new Date('2023-06-23'),
};
