import {createAsyncThunk} from '@reduxjs/toolkit';

import {bluetoothService} from '../../../src/services/bluetooth';
import {locationService} from '../../../src/services/location';
import {bleManager} from '../../ble2/v2/BleManager';
import {
  OnConnectProgress,
  OnDisconnect,
} from '../../ble2/v2/BleManager/BleManagerBase';
import {ConnectionError} from '../../ble2/v2/types';
import {setConnecting} from '../bleSlice';
import {bleConnect} from './bleConnect';
import {bleDisconnectDevice} from './bleDisconnectDevice';

type ScanAndConnectDeviceArgs = {
  onProgress: OnConnectProgress;
  onBootloader(): Promise<void>;
};

export const bleScanAndConnect = createAsyncThunk<
  void,
  ScanAndConnectDeviceArgs
>('ble/scanAndConnect', async ({onProgress, onBootloader}, {dispatch}) => {
  try {
    dispatch(setConnecting(true));

    const timeout = 3000;

    const onDisconnect: OnDisconnect = async () => {
      await dispatch(bleDisconnectDevice()).unwrap();
    };

    await locationService.request();
    await bluetoothService.request();

    onProgress({value: 0.1, data: 'scanning', duration: timeout});

    const peak = await bleManager.scanForDevice({timeout});

    await bleManager.connectToDevice(peak, {onDisconnect, onProgress});

    if (!bleManager.peak) {
      await onBootloader();
      throw new Error(ConnectionError.IN_BOOTLOADER_STATE);
    }

    await dispatch(bleConnect({peak: bleManager.peak})).unwrap();
  } finally {
    dispatch(setConnecting(false));
  }
});
