import {Screens} from '../constants';
import {appVersion} from '../shims/AppVersion';
import NavigationService from './NavigationService';
import {interstitialApi} from './api/apis';
import {AppDispatch} from './redux';
import {
  currentDeviceSWRevisionSelector,
  currentDeviceSelector,
} from './redux/bleSlice';
import {add, updateSeen} from './redux/interstitialScreenSlice';
import {ChamberType, Store} from './types';
import {meetsMinimumFirmware} from './utilityFunctions';

/** The parameter for filtering */
export type ConditionMet = {
  /** Redux store. */
  state: Store;
  /** Current screen. */
  currentActiveRouteName: string;
  /** The timer for when the interstitial is initialize. */
  initializeTime?: Date;
  /** The time when the user last seen the interstitial. */
  lastSeenTime?: Date;
  /** The function to check if current device includes the chamber type. */
  hasChamberType: (chamberType: ChamberType | ChamberType[]) => boolean;
  /** The function to check if current device meets the firmware version. */
  meetFirmwareVersion: (version: string) => boolean;
  /** Data returned by getData function. */
  data: any;
};

/** The interstitial screen that includes the conditions of displaying */
export type InterstitialScreen = {
  /** Screen to navigate in condition is matched. */
  screen?: string;
  /** Get the data for the interstitial. */
  getData?: () => Promise<any>;

  /** modal component for displaying */
  modalComponent?: (
    dispatch: AppDispatch,
    data: any,
  ) => {
    element: JSX.Element;
    onClose: () => void;
  };
  /** Date started for showing the interstitial. */
  startDate?: Date;
  /** Date ended for showing the interstitial. */
  expireDate?: Date;
  /** Initialize function for the interstitial timer. */
  initializer?: (
    state: Store,
    screenName: string,
  ) => Promise<Date | undefined> | Date | undefined;
  /** If true, initialize the interstitial timer with current time. */
  autoInitialize?: boolean;
  /** Function of filtering the conditions to show the interstitial.  */
  conditionMet: (props: ConditionMet) => Promise<boolean> | boolean;
  /** The field that retrigger the screen if the value is changed */
  retriggerField?: string;
  /** The interstitial will be shown if current screen is trigger screen of included in trigger screen. Detault: HomeScreen. */
  triggerScreen?: string | string[];
  /** Disable showing the interstitial. */
  disable?: boolean;
};

export type GetCurrentScreenData = InterstitialScreen & {
  modal?: {
    element: any;
    onClose: () => void;
  };
};

export class InterstitialManager {
  private static instance: InterstitialManager;

  static interstitialPromise = (async () => {
    const res = await interstitialApi
      .getActive({xAppVersion: appVersion})
      .catch(() => undefined);

    if (!res) return;

    return res.data ?? [];
  })();

  index = 0;
  seenScreens: string[] = [];

  constructor(
    private screens: InterstitialScreen[],
    private params: {[x: string]: any},
  ) {}

  static getInstance(
    screens: InterstitialScreen[],
    params: {[x: string]: any},
  ) {
    if (!InterstitialManager.instance) {
      InterstitialManager.instance = new InterstitialManager(screens, params);
    }
    return InterstitialManager.instance;
  }

  hasNextScreen() {
    return this.index + 1 < this.screens.length;
  }

  nextScreen() {
    this.index += 1;
  }

  resetIndex() {
    this.index = 0;
  }

  async getCurrentScreen(
    state: Store,
    dispatch: AppDispatch,
    retriggerParams: any,
  ): Promise<GetCurrentScreenData | null> {
    const {interstitialScreen} = state;
    const currentActiveRouteName = NavigationService.getCurrentRouteName();

    while (this.index < this.screens.length) {
      const currentScreen = this.screens[this.index];
      const {
        initializer,
        autoInitialize,
        conditionMet,
        startDate,
        expireDate,
        disable,
        screen,
        getData,
        modalComponent,
        triggerScreen = Screens.Home,
        retriggerField,
      } = currentScreen;

      // Filter conditions based on the screen
      let interstitialInitializeTime: number | undefined = undefined;
      let lastSeenTime: number | undefined = undefined;
      let skip = false;
      if (
        typeof triggerScreen === 'string'
          ? currentActiveRouteName !== triggerScreen
          : !triggerScreen.includes(currentActiveRouteName)
      )
        skip = true;
      if (disable) skip = true;
      if (startDate && startDate.getTime() > new Date().getTime()) skip = true;
      if (expireDate && expireDate.getTime() < new Date().getTime())
        skip = true;
      const hasChamberType = (chamberType: ChamberType | ChamberType[]) => {
        const device = currentDeviceSelector(state);
        if (
          !device?.chamberType ||
          (device.chamberType && typeof chamberType === 'number'
            ? device.chamberType !== chamberType
            : !(chamberType as number[]).includes(device.chamberType))
        )
          return false;
        return true;
      };
      const meetFirmwareVersion = (minVersion: string) => {
        const version = currentDeviceSWRevisionSelector(state);
        return meetsMinimumFirmware(version, minVersion);
      };
      let initializeTime: Date | undefined = undefined;

      if (screen) {
        // Get the interstitial initialize time.
        interstitialInitializeTime = interstitialScreen[screen]?.initialize;
        lastSeenTime = interstitialScreen[screen]?.seen;

        if (interstitialInitializeTime) {
          initializeTime = new Date(interstitialInitializeTime);
        } else {
          // Autoinitialize the interstitial timer.
          if (autoInitialize) {
            initializeTime = new Date();
            dispatch(
              add({
                screen,
                start: initializeTime.getTime(),
              }),
            );
          }
          if (initializer) initializeTime = await initializer(state, screen);
        }
      }

      // Check if the condition is met.
      const data = await getData?.().catch(() => undefined);

      // Trigger when launch the app
      if (
        !this.seenScreens.includes(currentActiveRouteName) &&
        !(await conditionMet({
          state,
          initializeTime,
          lastSeenTime: lastSeenTime ? new Date(lastSeenTime) : undefined,
          currentActiveRouteName,
          hasChamberType,
          meetFirmwareVersion,
          data,
        }))
      )
        skip = true;

      // Retrigger when the retrigger value is changed.
      if (this.seenScreens.includes(currentActiveRouteName)) {
        if (!retriggerField) skip = true;
        else if (
          this.params[retriggerField] === retriggerParams[retriggerField]
        )
          skip = true;
        else if (
          !(await conditionMet({
            state,
            initializeTime,
            lastSeenTime: lastSeenTime ? new Date(lastSeenTime) : undefined,
            currentActiveRouteName,
            hasChamberType,
            meetFirmwareVersion,
            data,
          }))
        )
          skip = true;
      }

      if (!skip) {
        if (!this.seenScreens.includes(currentActiveRouteName))
          this.seenScreens = [...this.seenScreens, currentActiveRouteName];
        this.params = retriggerParams;
        if (screen) {
          dispatch(updateSeen({screen}));
          return currentScreen;
        } else if (modalComponent) {
          return {
            ...currentScreen,
            modal: modalComponent(dispatch, data),
          };
        }
      }

      // If condition not met, go to the next item in the list.
      this.index += 1;
    }
    this.params = retriggerParams;
    return null;
  }
}
