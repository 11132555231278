import React from 'react';
import {AppState, AppStateStatus} from 'react-native';
import {useSelector} from 'react-redux';

import {Constants} from './constants';
import {appLog, setUser as setLoggerUser} from './lib/Logger';
import {userApi} from './lib/api/apis';
import {ConnectionError} from './lib/ble2/v2/types';
import {useAppDispatch, useSyncPeakDevice} from './lib/hooks';
import {connectedPeakSelector} from './lib/redux/bleSlice';
import {bleDisconnectDevice, bleReconnect} from './lib/redux/thunk';
import {setUser, userSelector} from './lib/redux/userSlice';
import {BackgroundTimerTask} from './shims/backgroundTimerTask';
import {setUser as setSentryUser} from './shims/sentry';
import {PermissionError} from './src/services/PermissionError';

interface Props {
  onBootloader(): Promise<void>;
}

export const AppStateSensor: React.FC<Props> = ({onBootloader}) => {
  const dispatch = useAppDispatch();
  const [appState, setAppState] = React.useState<AppStateStatus>(
    AppState.currentState,
  );

  const peak = useSelector(connectedPeakSelector);
  const user = useSelector(userSelector);
  const reconnectPromise = React.useRef<Promise<void>>();

  useSyncPeakDevice();

  // TODO: remove this once session handling is fixed
  React.useEffect(() => {
    setLoggerUser(user ?? undefined);
    setSentryUser(user ?? undefined);
  }, [user]);

  React.useEffect(() => {
    if (!user?.id) return;

    userApi.getUserById({id: 'me'}).then(({data}) => dispatch(setUser(data)));
  }, [dispatch, user?.id]);

  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', setAppState);

    return () => subscription.remove();
  }, []);

  const reconnect = React.useCallback(
    async (attempt = 1): Promise<void> => {
      try {
        appLog.info('Reconnect initiated.', {attempt});
        await dispatch(bleReconnect({timeout: 3000, onBootloader})).unwrap();
      } catch (error: any) {
        if (Object.values(PermissionError).includes(error.message)) {
          appLog.error('Reconnect failed with permission error.', {error});
          return;
        }

        if (
          [
            ConnectionError.USER_CANCELLED,
            ConnectionError.WEB_USER_CANCELLED,
            ConnectionError.IN_BOOTLOADER_STATE,
          ].includes(error.message)
        ) {
          appLog.error('Reconnect failed with unretriable error.', {error});
          return;
        }

        if (attempt >= 3) {
          appLog.error('Reconnect failed.', {error});
          return;
        }

        appLog.error('Reconnect failed. Retrying...', {error});

        setTimeout(() => reconnect(attempt + 1));
      }
    },
    [dispatch],
  );

  React.useEffect(() => {
    switch (appState) {
      case 'background': {
        if (!peak) return;

        const timer = BackgroundTimerTask.setTimeout(() => {
          dispatch(bleDisconnectDevice());
        }, Constants.DISCONNECT_TIMEOUT);

        return () => BackgroundTimerTask.clearTimeout(timer);
      }
    }
  }, [appState, peak, dispatch]);

  React.useEffect(() => {
    if (appState !== 'active') return;
    if (peak) return;

    /**
     * Permission prompts will move the app into the background.
     * We do not want to retrigger the connection flow just away because
     * that would display the permission prompts again.
     */
    if (reconnectPromise.current) return;

    reconnectPromise.current = reconnect()
      .catch(() => void 0)
      .finally(() => {
        // TODO: figure out if there is a better way to do this
        // For Android 1s is enough to trigger the app state change, but iOS needs 2s.
        setTimeout(() => {
          reconnectPromise.current = undefined;
        }, 2000);
      });
  }, [appState, peak, reconnect]);

  return null;
};
