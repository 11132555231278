import React from 'react';
import {AppState, AppStateStatus} from 'react-native';

export function useGetAppState() {
  const currentState = AppState.currentState;
  const [appState, setAppState] = React.useState(currentState);

  function onChange(newState: AppStateStatus) {
    setAppState(newState);
  }

  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', onChange);

    return () => {
      subscription.remove();
    };
  }, []);

  return appState;
}
