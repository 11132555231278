import {
  CompositeScreenProps,
  NavigatorScreenParams,
} from '@react-navigation/native';
import {
  StackScreenProps,
  TransitionPresets,
  createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';
import {Platform} from 'react-native';

import {Navigators, Screens, appColors} from '../../constants';
import {LegalIntroScreen} from '../../screens/DeviceConnect/LegalIntroScreen';
import {WelcomeScreen} from '../../screens/DeviceConnect/WelcomeScreen';
import {
  ExclusiveMoodScreen,
  Props as ExclusiveMoodScreenProps,
} from '../../screens/controlCenter/ExclusiveMoodScreen';
import {
  AccountCreatedScreen,
  Props as AccountCreatedScreenProps,
} from '../../screens/login/AccountCreatedScreen';
import {CarouselScreen} from '../../screens/login/CarouselScreen';
import {
  EmailConfirmedScreen,
  Props as EmailConfirmedScreenProps,
} from '../../screens/login/EmailConfirmedScreen';
import {
  ForgotPasswordScreen,
  Props as ForgotPasswordScreenProps,
} from '../../screens/login/ForgetPasswordScreen';
import {
  LoginScreen,
  Props as LoginScreenProps,
} from '../../screens/login/LoginScreen';
import {
  PasswordConfirmedScreen,
  Props as PasswordConfirmedScreenProps,
} from '../../screens/login/PasswordConfirmedScreen';
import {
  PasswordResetScreen,
  Props as PasswordResetScreenProps,
} from '../../screens/login/PasswordResetScreen';
import {
  PasswordResetSentScreen,
  Props as PasswordResetSentScreenProps,
} from '../../screens/login/PasswordResetSentScreen';
import {
  RegisterScreen,
  Props as RegisterScreenProps,
} from '../../screens/login/RegisterScreen';
import {LoadingScreen} from '../../screens/main/LoadingScreen';
import {
  PrivacyPolicyScreen,
  Props as PrivacyPolicyScreenProps,
} from '../../screens/settings/PrivacyPolicyScreen';
import {
  TermsConditionsScreen,
  Props as TermsConditionsScreenProps,
} from '../../screens/settings/TermsConditionsScreen';
import {CustomModalSlideFromBottomIOS} from '../CustomTransitionPreset';
import {
  DownloadSharedHeatProfileStackNavigator,
  DownloadSharedHeatProfileStackParamList,
} from './DownloadSharedHeatProfileStackNavigator';
import {
  HeatProfileEditStackNavigator,
  HeatProfileEditStackParamList,
} from './HeatProfileEditStackNavigator';
import {MainStackNavigator, MainStackParamList} from './MainStackNavigator';
import {
  defaultNavigationOptions,
  defaultNoHeaderScreenNavigation,
  modalNavigationOptions,
  withBackButton,
} from './common';

export type RootStackParamList = {
  [Navigators.MainNavigator]: NavigatorScreenParams<MainStackParamList>;
  [Navigators.DownloadSharedHeatProfile]: NavigatorScreenParams<DownloadSharedHeatProfileStackParamList>;
  [Navigators.HeatProfileEdit]: NavigatorScreenParams<HeatProfileEditStackParamList>;

  [Screens.Loading]?: undefined;
  [Screens.Welcome]?: undefined;
  [Screens.LegalIntro]: undefined;
  [Screens.Carousel]?: undefined;

  [Screens.Login]?: LoginScreenProps;
  [Screens.Register]?: RegisterScreenProps;
  [Screens.AccountCreated]?: AccountCreatedScreenProps;
  [Screens.ForgotPassword]?: ForgotPasswordScreenProps;
  [Screens.PasswordReset]: PasswordResetScreenProps;
  [Screens.PasswordResetSent]?: PasswordResetSentScreenProps;
  [Screens.PasswordConfirmed]?: PasswordConfirmedScreenProps;
  [Screens.EmailConfirmed]: EmailConfirmedScreenProps;
  [Screens.ExclusiveMood]?: ExclusiveMoodScreenProps;

  [Screens.TermsConditions]: TermsConditionsScreenProps;
  [Screens.PrivacyPolicy]: PrivacyPolicyScreenProps;
};

export type RootStackScreenProps<T extends keyof RootStackParamList> =
  StackScreenProps<RootStackParamList, T>;

export type MainNavigatorScreenProps<T extends keyof MainStackParamList> =
  CompositeScreenProps<
    StackScreenProps<MainStackParamList, T>,
    RootStackScreenProps<keyof RootStackParamList>
  >;

export type DownloadSharedHeatProfileNavigator<
  T extends keyof DownloadSharedHeatProfileStackParamList,
> = CompositeScreenProps<
  StackScreenProps<DownloadSharedHeatProfileStackParamList, T>,
  RootStackScreenProps<keyof RootStackParamList>
>;

export type HeatProfileEditNavigator<
  T extends keyof HeatProfileEditStackParamList,
> = CompositeScreenProps<
  StackScreenProps<HeatProfileEditStackParamList, T>,
  RootStackScreenProps<keyof RootStackParamList>
>;

const RootStack = createStackNavigator<RootStackParamList>();

export interface Props {
  initialRouteName?: keyof RootStackParamList;
}

export const RootStackNavigator = ({
  initialRouteName = Screens.Loading,
}: Props) => {
  return (
    <RootStack.Navigator
      screenOptions={defaultNavigationOptions}
      {...{initialRouteName}}>
      <RootStack.Screen
        name={Screens.Loading}
        component={LoadingScreen}
        options={defaultNoHeaderScreenNavigation}
      />

      <RootStack.Screen
        name={Screens.Welcome}
        component={WelcomeScreen}
        options={defaultNoHeaderScreenNavigation}
      />

      <RootStack.Screen
        name={Screens.LegalIntro}
        component={LegalIntroScreen}
        options={defaultNoHeaderScreenNavigation}
      />

      <RootStack.Screen
        name={Screens.Carousel}
        component={CarouselScreen}
        options={{
          ...modalNavigationOptions,
          gestureEnabled: false,
          headerShown: false,
        }}
      />

      <RootStack.Screen
        name={Screens.Login}
        component={LoginScreen}
        options={{
          ...modalNavigationOptions,
          headerShown: true,
          gestureEnabled: false,
          ...withBackButton({color: appColors.black}),
        }}
      />

      <RootStack.Screen
        name={Screens.Register}
        component={RegisterScreen}
        options={{
          ...modalNavigationOptions,
          headerShown: false,
          gestureEnabled: false,
        }}
      />

      <RootStack.Screen
        name={Screens.AccountCreated}
        component={AccountCreatedScreen}
        options={{...modalNavigationOptions, gestureEnabled: false}}
      />

      <RootStack.Screen
        name={Screens.ForgotPassword}
        component={ForgotPasswordScreen}
        options={{
          ...modalNavigationOptions,
          headerShown: false,
          gestureEnabled: false,
        }}
      />

      <RootStack.Screen
        name={Screens.PasswordReset}
        component={PasswordResetScreen}
        options={{...modalNavigationOptions, gestureEnabled: false}}
      />

      <RootStack.Screen
        name={Screens.PasswordResetSent}
        component={PasswordResetSentScreen}
        options={{
          ...modalNavigationOptions,
          gestureEnabled: false,
          headerShown: false,
        }}
      />

      <RootStack.Screen
        name={Screens.PasswordConfirmed}
        component={PasswordConfirmedScreen}
        options={{...modalNavigationOptions, gestureEnabled: false}}
      />

      <RootStack.Screen
        name={Screens.EmailConfirmed}
        component={EmailConfirmedScreen}
        options={{...modalNavigationOptions, gestureEnabled: false}}
      />

      <RootStack.Screen
        name={Screens.PrivacyPolicy}
        component={PrivacyPolicyScreen}
        options={{
          ...modalNavigationOptions,
          headerShown: true,
          gestureEnabled: false,
          ...withBackButton({color: appColors.black}),
        }}
      />

      <RootStack.Screen
        name={Screens.TermsConditions}
        component={TermsConditionsScreen}
        options={{
          ...modalNavigationOptions,
          headerShown: true,
          gestureEnabled: false,
          ...withBackButton({color: appColors.black}),
        }}
      />

      <RootStack.Screen
        name={Navigators.MainNavigator}
        component={MainStackNavigator}
        options={{headerShown: false}}
      />

      <RootStack.Screen
        name={Navigators.HeatProfileEdit}
        component={HeatProfileEditStackNavigator}
        options={{
          ...CustomModalSlideFromBottomIOS,
          ...defaultNavigationOptions,
          gestureEnabled: false,
          cardOverlayEnabled: true,
          headerTransparent: false,
          headerShown: false,
          cardStyle: {
            backgroundColor:
              Platform.OS !== 'android' ? '#00000033' : '#000000E6',
          },
        }}
      />

      <RootStack.Screen
        name={Navigators.DownloadSharedHeatProfile}
        component={DownloadSharedHeatProfileStackNavigator}
        options={{
          ...TransitionPresets.ModalSlideFromBottomIOS,
          ...modalNavigationOptions,
          headerShown: false,
          gestureEnabled: false,
          cardOverlayEnabled: true,
        }}
      />

      <RootStack.Screen
        name={Screens.ExclusiveMood}
        component={ExclusiveMoodScreen}
        options={{
          ...TransitionPresets.ModalSlideFromBottomIOS,
          ...defaultNavigationOptions,
          gestureEnabled: false,
          cardOverlayEnabled: true,
          headerTransparent: true,
        }}
      />
    </RootStack.Navigator>
  );
};
