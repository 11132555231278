import React from 'react';
import {StyleSheet, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import {Strings} from '../../../../constants';
import {resolveAssetUrl} from '../../../../lib/api';
import {TypeDeviceSkeleton} from '../../../../lib/api/content-access/types';
import {Section} from '../Section';
import {DeviceItem} from './DeviceItem';

interface Props {
  devices: TypeDeviceSkeleton[];
  selectedDevice: TypeDeviceSkeleton;
  onPress: (item: TypeDeviceSkeleton) => void;
}

export const DeviceCarouselSelector = ({
  devices,
  selectedDevice,
  onPress,
}: Props) => (
  <Section title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.CHOOSE_DEVICE}>
    <FlatList
      data={devices}
      renderItem={({item: deviceItem}) => {
        return (
          <DeviceItem
            thumbnailImage={{
              uri: resolveAssetUrl(deviceItem.fields.thumbnailImage),
            }}
            name={deviceItem.fields.name}
            isSelected={deviceItem.sys.id === selectedDevice.sys.id}
            onPress={() => onPress(deviceItem)}
          />
        );
      }}
      keyExtractor={item => item.fields.name}
      extraData={selectedDevice.sys.id}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      ItemSeparatorComponent={() => <View style={styles.separator} />}
      contentContainerStyle={styles.container}
    />
  </Section>
);

const styles = StyleSheet.create({
  container: {paddingHorizontal: 20},
  separator: {width: 16},
});
