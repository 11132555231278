import {createAsyncThunk} from '@reduxjs/toolkit';

import {Store} from '../../types';
import {
  connectedPeakSelector,
  setConnectedDeviceId,
  updateDeviceInfo,
} from '../bleSlice';

export const bleDisconnectDevice = createAsyncThunk(
  'ble/disconnectDevice',
  async (_, {dispatch, getState}) => {
    const state = getState() as Store;
    const peak = connectedPeakSelector(state);

    if (!peak) return;

    await peak.disconnect();

    dispatch(setConnectedDeviceId(undefined));

    dispatch(
      updateDeviceInfo({
        id: peak.peripheralId,
        // TODO: do not clear these if still broadcasting
        battery: undefined,
        batteryChargeState: undefined,
        approxDabsRemainingCount: undefined,
        lastAdvertisement: Date.now(),
      }),
    );
  },
);
