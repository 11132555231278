import React from 'react';
import {Text, View} from 'react-native';

import {appColors} from '../../../constants';
import styled from '../../../lib/styled';

type XLMetricsProps = {
  xlMetricsData: {type: string; value: string}[];
};

export const XLMetrics = ({xlMetricsData}: XLMetricsProps) => (
  <MetricsContainer>
    {xlMetricsData.map((metric, index) => (
      <React.Fragment key={`${metric.type}-${metric.value}`}>
        <MetricSection>
          <MetricValue>{metric.value}</MetricValue>
          <MetricType>{metric.type}</MetricType>
        </MetricSection>
        {index < xlMetricsData.length - 1 && <VerticalLine />}
      </React.Fragment>
    ))}
  </MetricsContainer>
);

const MetricsContainer = styled(View)({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginVertical: 10,
});

const VerticalLine = styled(View)({
  width: 1,
  height: '100%',
  backgroundColor: appColors.black20,
});

const MetricSection = styled(View)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
});

const MetricValue = styled(Text)({
  fontSize: 40,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  letterSpacing: 0.6,
  includeFontPadding: false,
  color: appColors.black,
});

const MetricType = styled(Text)({
  fontSize: 16,
  fontFamily: 'Roboto-Regular',
  color: appColors.black,
  fontWeight: '400',
  letterSpacing: 0.6,
  includeFontPadding: false,
});
