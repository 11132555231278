import React from 'react';
import {StyleSheet, View} from 'react-native';

import {appColors} from '../constants';
import {Theme} from '../lib/types';
import {defaultTheme} from '../themes';
import {AppText} from './AppText';

export interface Props {
  status: 'SEARCHING' | string;
  percentage?: number;
  error?: string;
  theme?: Theme;
}

export const StatusDisplay = ({
  error,
  percentage,
  status,
  theme = defaultTheme,
}: Props) => {
  const percentageString =
    status === 'SEARCHING' || percentage === undefined
      ? ''
      : `... ${percentage}%`;

  return (
    <View style={styles.container}>
      {error ? (
        <AppText style={styles.error}>{error}</AppText>
      ) : (
        <AppText
          style={{
            color: theme.statusDisplayTextColor,
          }}>{`${status}${percentageString}`}</AppText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 50,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {color: appColors.red},
});
