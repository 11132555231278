import {addMonths} from 'date-fns';
import React from 'react';
import {useDispatch} from 'react-redux';

import {backgroundXLUpsellAlertScreen} from '../../assets/images';
import {
  Constants,
  Messages,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {InterstitialScreen} from '../../lib/InterstitialManager';
import {navigateToWebsite} from '../../lib/navigateToWebsite';
import {currentDeviceSelector} from '../../lib/redux/bleSlice';
import {updateSeen} from '../../lib/redux/interstitialScreenSlice';
import {HomeEmulatedDrawerNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {ImageAndContentContainer} from '../components';
import {XLFooter} from './components/XLFooter';
import {XLMetrics} from './components/XLMetrics';

const xlMetrics = [
  {type: 'larger bowl', value: '+78%'},
  {type: 'more vapor', value: '2x'},
  {type: 'ready to rip', value: '100%'},
];

interface Props
  extends HomeEmulatedDrawerNavigatorScreenProps<
    typeof Screens.XLUpsellAlert
  > {}

export const XLUpsellAlertScreen = ({navigation}: Props) => {
  const dispatch = useDispatch();

  return (
    <ImageAndContentContainer
      image={backgroundXLUpsellAlertScreen}
      header={{content: Messages.XL_CHAMBER_UPSELL_TITLE}}
      body={{
        content: Messages.XL_CHAMBER_UPSELL_BODY,
        style: {
          textAlign: 'left',
          color: appColors.black,
          width: '100%',
        },
      }}
      footerContainerStyle={{marginTop: 'auto'}}
      primaryButton={{
        title: Strings.SHOP_NOW,
        onClick: () => {
          dispatch(
            updateSeen({
              screen: Screens.XLUpsellAlert,
            }),
          );

          navigateToWebsite(Constants.SHOP_URL_PATH);
        },
      }}
      secondaryButton={{
        onClick: () => {
          dispatch(
            updateSeen({
              screen: Screens.XLUpsellAlert,
            }),
          );

          navigation.navigate(Navigators.HomeTabNavigator, {
            screen: Screens.Home,
          });
        },
      }}
      bottom={{
        content: <XLFooter />,
      }}>
      <XLMetrics xlMetricsData={xlMetrics} />
    </ImageAndContentContainer>
  );
};

export const XLUpsellInterstitial: InterstitialScreen = {
  screen: Screens.XLUpsellAlert,
  conditionMet: ({initializeTime, lastSeenTime, state}) => {
    const currentDevice = currentDeviceSelector(state);
    // After a user has been using vapor control on “Max” for 3 months.
    if (
      !initializeTime ||
      lastSeenTime ||
      state.appFlags.hasSeenXLUpsellAlertScreen
    )
      return false;

    const conditionMet = currentDevice
      ? (currentDevice.hits ?? 0) > 25 &&
        new Date().getTime() >= addMonths(initializeTime, 3).getTime()
      : false;

    return conditionMet;
  },
  startDate: new Date('2023-06-23'),
  expireDate: new Date('2025-01-01'), // Disable on 2025-01-01
};
