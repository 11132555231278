import Constants from 'expo-constants';
import {v4 as uuidv4} from 'uuid';

import {Constants as Defaults, Environment} from '../constants';
import {Storage} from '../lib/Storage';
import {Dictionary} from '../lib/types';
import {appVersion} from '../shims/AppVersion';
import {FirebaseAnalyticsJS} from './FirebaseAnalyticsJS';
import {FirebaseAnalyticsJSConfig} from './FirebaseAnalyticsJS.types';

/**
 * Common User Interactions.
 */
export enum EventAction {
  EXCEPTION = 'exception',
  SCREEN_VIEW = 'screen_view',
}

export type ExceptionProperties = {
  description: string;
  fatal: boolean;
};

type ScreenViewProperties = {
  app_name: string;
  app_version: string;
  page_title: string;
  page_location: string;
  screen_name: string;
};

/**
 * Custom User Interactions.
 */
export enum CustomCategory {
  CONTROL = 'Control',
  CLEANINGREMINDER = 'CleaningReminder',
  HEATPROFILES = 'Heatprofiles',
  INFO = 'Info',
  MOODLIGHT = 'Moodlight',
  OUTBOUND = 'Outbound',
  PROMOTION = 'Promotion',
  URLSHARING = 'URLSharing',
  WARNING = 'Warning',
  SUCCESS = 'Success',
  ERROR = 'Error',
  OPT_IN = 'Opt-In',
  SHARE = 'Share',
}

export enum CustomEventAction {
  BROWSER_VIEW = 'iOS_browser_view',
  DABBING_START = 'dabbing_start',
  SESSION_START = 'session_start',
  EPILEPSY_WARNING_VIEW = 'epilepsy_warning_view',
  FULL_VIEW = 'iOS_full_view',
  LINK_CLICK = 'link_click',
  MASTER_OFF = 'master_off',
  USE_MOODLIGHT = 'mood_light_use',
  READY_MODE = 'ready_mode_select',
  SHARE_SENT = 'share_sent',
  SNOOZE = 'snooze',
  STEALTH_ON = 'stealth_on',
  VIEW_PROMOTION = 'view_promotion',
  BLE_CONNECT = 'ble_connect',
  OPT_IN_ACCEPT = 'opt_in_accept',
  SHARE_ADVANCED_METRICS_DASHBOARD = 'share_advanced_metrics_dashboard',
}

export enum bleConnectError {
  SCAN_ERROR = 'scan_error',
  SERVICE_NOT_FOUND = 'service_not_found',
  NOT_FOUND = 'not_found',
  NOT_IN_RANGE = 'not_in_range',
  POWER_OFF = 'power_off',
  CHAR_DESP_READ = 'char_desp_read',
  DISCONNECT = 'disconnect',
  IOS_BOND = 'ios_bond',
  ANDROID_BOND = 'android_bond',
  RECONNECT_MAX_ATTEMPTS = 'reconnect_max_attempts',
}

export enum CustomLabel {
  OPT_IN_SERVICE = 'service',
  MOODLIGHT_ID = 'mood_light_id',
}

export const CUSTOM_ACTION_LABEL_DICTIONARY = {
  [CustomEventAction.BROWSER_VIEW]: 'iOS web browser screen viewed.',
  [CustomEventAction.DABBING_START]: 'Dabbing cycle initiated.',
  [CustomEventAction.EPILEPSY_WARNING_VIEW]: 'Epilepsy warning screen viewed.',
  [CustomEventAction.FULL_VIEW]: 'iOS web full screen viewed.',
  [CustomEventAction.LINK_CLICK]: 'External page viewed.',
  [CustomEventAction.MASTER_OFF]: 'Master off enabled.',
  [CustomEventAction.OPT_IN_ACCEPT]: 'Opt-in service accepted.',
  [CustomEventAction.USE_MOODLIGHT]: 'Mood Light in use.',
  [CustomEventAction.READY_MODE]: 'Ready mode profile selected.',
  [CustomEventAction.SHARE_SENT]: 'Share profile natively shared.',
  [CustomEventAction.SNOOZE]: 'Snooze triggered.',
  [CustomEventAction.STEALTH_ON]: 'Stealth Mode Enabled.',
  [CustomEventAction.VIEW_PROMOTION]: 'Promo viewed.',
  [CustomEventAction.BLE_CONNECT]: 'Ble connect.',
  [CustomEventAction.SHARE_ADVANCED_METRICS_DASHBOARD]:
    'Advanced Metrics Dashboard is shared.',
} as Dictionary<CustomEventAction, string>;

export type CustomEventActionProperties = {
  app_name: string;
  app_version: string;
  event_category: string;
  event_label: string;
  value?: number;
};

export class Analytics {
  private static _shared: Analytics;
  private pureJSAnalyticsTracker?: FirebaseAnalyticsJS;
  private _gaClientID?: string;
  private static _agreeConsent: boolean;

  public static shared(): Analytics {
    if (Analytics._shared == null) {
      Analytics._shared = new Analytics();
      Analytics._shared.setUpAnalytics();
    }
    return this._shared;
  }
  constructor() {
    Analytics._agreeConsent = false;
    if (Analytics._shared) {
      throw new Error(
        'Error: Instantiation failed: Use Analytics.shared() instead of new.',
      );
    }
  }

  /**
   * Private Methods.
   */

  private createGAClientID = (): string => {
    return uuidv4();
  };

  private getGAClientID = (): string => {
    const gaClientID = Analytics.shared()._gaClientID;
    if (!gaClientID) {
      throw new Error('Error: gaClientID not set.');
    } else {
      return gaClientID;
    }
  };

  private setUpAnalytics = async () => {
    if (
      Analytics._shared._gaClientID &&
      Analytics._shared.pureJSAnalyticsTracker
    )
      return;
    // appLog.info('+++++ setting up Analytics +++++');
    const gaClientID = await Storage.getItem(Defaults.GA_CLIENT_ID_STORAGE_KEY);
    if (gaClientID) {
      Analytics._shared._gaClientID = gaClientID;
    } else {
      Analytics._shared._gaClientID = this.createGAClientID();
      await Storage.setItem(
        Defaults.GA_CLIENT_ID_STORAGE_KEY,
        this._gaClientID,
      );
    }
    Analytics._shared.createTracker();
  };

  private createTracker = () => {
    if (Analytics.shared().pureJSAnalyticsTracker) return;
    if (!Environment.google.measurementId) return;

    const gaClientID = Analytics.shared().getGAClientID();
    // appLog.info('+++++ creating FireBaseAnalyticsJS tracker +++++');
    const config: FirebaseAnalyticsJSConfig = {
      measurementId: Environment.google.measurementId,
    };
    Analytics.shared().pureJSAnalyticsTracker = new FirebaseAnalyticsJS(
      config,
      {
        clientId: gaClientID,
        sessionId: Constants.sessionId,
        strictNativeEmulation: true,
        appName: 'Puffco Connect',
        appVersion,
        headers: {
          // Google Analaytics seems to ignore certain user-agents. (e.g. "okhttp/3.12.1")
          // Set a user-agent that clearly identifies the client.
          'user-agent': `PuffcoConnect/${Constants.nativeAppVersion}`,
        },
      },
    );
  };

  private destroyTracker = () => {
    // appLog.info('----- destroying FireBaseAnalyticsJS tracker -----');
    Analytics.shared().pureJSAnalyticsTracker = undefined;
  };

  /**
   * Public Methods.
   */
  public setAgreeConsent(status: boolean): void {
    Analytics._agreeConsent = status;
  }

  public async logScreenView(currentScreen: string): Promise<void> {
    if (!Analytics._agreeConsent) return;
    if (currentScreen.length < 1) return;
    const properties: ScreenViewProperties = {
      app_name: 'Puffco Connect',
      app_version: appVersion,
      page_title: currentScreen,
      page_location: Environment.hostUrl,
      screen_name: currentScreen,
    };
    // appLog.info(`+++++ logging screen_view: ${currentScreen} +++++`);
    await Analytics.shared().pureJSAnalyticsTracker?.logEvent(
      EventAction.SCREEN_VIEW,
      properties,
    );
  }

  public async logException(
    description: string,
    fatal: boolean,
  ): Promise<void> {
    if (!Analytics._agreeConsent) return;
    const properties: ExceptionProperties = {
      description: description,
      fatal: fatal,
    };
    await Analytics.shared().pureJSAnalyticsTracker?.logEvent(
      EventAction.EXCEPTION,
      properties,
    );
  }

  public async logCustomEventAction(
    actionName: CustomEventAction,
    category: CustomCategory,
    appendStringToLabel?: string,
    value?:
      | {
          [key: string]: any;
        }
      | number,
  ): Promise<void> {
    if (!Analytics._agreeConsent) return;
    const appendString = appendStringToLabel ? ' ' + appendStringToLabel : '';
    const properties: CustomEventActionProperties = {
      app_name: 'Puffco Connect',
      app_version: appVersion,
      event_category: category,
      event_label:
        (CUSTOM_ACTION_LABEL_DICTIONARY[actionName] || '') + appendString,
    };
    const eventParam = typeof value === 'number' ? {value} : value;
    await Analytics.shared().pureJSAnalyticsTracker?.logEvent(
      actionName,
      value ? {...properties, ...eventParam} : properties,
    );
  }
}

Analytics.shared();
