import React from 'react';
import {Animated, Easing} from 'react-native';

import {Progress} from '../ble2/v2/types';

export interface AnimatedProgress<T> extends Progress<T> {
  target: number;
}

export const useProgress = <T>(initial: Progress<T>) => {
  const [animated] = React.useState(new Animated.Value(0));
  const [progress, setProgress] = React.useState<AnimatedProgress<T>>(() => ({
    ...initial,
    target: initial.value,
  }));

  const updateProgress = React.useCallback(
    ({value, ...progress}: Partial<Progress<T>>) => {
      setProgress(p => ({
        ...p,
        ...progress,
        value: p.value,
        target: value ?? p.target,
      }));

      if (value !== undefined) {
        Animated.timing(animated, {
          toValue: value,
          duration: progress.duration ?? 500,
          easing: Easing.linear,
          useNativeDriver: true,
        }).start();
      }
    },
    [animated],
  );

  const resetProgress = React.useCallback(() => {
    animated.stopAnimation();
    animated.setValue(0);
    setProgress({...initial, target: initial.value});
  }, [animated]);

  React.useEffect(() => {
    const listenerId = animated.addListener(({value}) =>
      setProgress(progress => ({...progress, value})),
    );

    return () => {
      animated.stopAnimation();
      animated.removeListener(listenerId);
    };
  }, [animated]);

  return [progress, updateProgress, resetProgress] as const;
};
