import {createAsyncThunk} from '@reduxjs/toolkit';

import {Store} from '../../types';
import {
  connectedPeakSelector,
  removeBleDevice,
  setCanReconnect,
} from '../bleSlice';
import {bleDisconnectDevice} from './bleDisconnectDevice';

type RemoveDeviceArgs = {
  peripheralId: string;
};

export const bleRemoveDevice = createAsyncThunk<void, RemoveDeviceArgs>(
  'ble/removeDevice',
  async ({peripheralId}, {dispatch, getState}) => {
    const state = getState() as Store;

    const peak = connectedPeakSelector(state);

    if (peak?.peripheralId !== peripheralId) {
      dispatch(removeBleDevice(peripheralId));
      return;
    }

    dispatch(setCanReconnect(false));
    await dispatch(bleDisconnectDevice()).unwrap();

    dispatch(removeBleDevice(peripheralId));
  },
);
