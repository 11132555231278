import {EntryFields} from 'contentful';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {appColors} from '../../../constants';
import {RichTextRenderer} from './RichTextRenderer';

interface Props {
  title: string;
  description: EntryFields.RichText;
}

export const Description = ({title, description}: Props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>

      <RichTextRenderer content={description} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    color: appColors.white,
    marginBottom: 48,
  },
  title: {
    fontFamily: 'BigShouldersDisplay-Bold',
    fontSize: 32,
    color: appColors.white,
    textTransform: 'uppercase',
    letterSpacing: 0.64,
    marginBottom: 24,
  },
});
