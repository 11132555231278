import {createAsyncThunk} from '@reduxjs/toolkit';

import {bleConnectSuccessEvent} from '../../../analytics/BleConnect';
import {IPeakDevice} from '../../ble2/v2/PeakDevice/IPeakDevice';
import {Device, DeviceAppSettings, Store} from '../../types';
import {getDeviceFromPeak} from '../../utilityFunctions/getDeviceFromPeak';
import {appSettingsSelector} from '../appSettingsSlice';
import {
  currentDeviceSelector,
  setConnectedDeviceId,
  updateDeviceInfo,
} from '../bleSlice';
import {bleReadDeviceSettings} from './bleReadDeviceSettings';

export const bleConnect = createAsyncThunk<void, {peak: IPeakDevice}>(
  'ble/connect',
  async ({peak}, {dispatch, getState}) => {
    const state = getState() as Store;
    const {batteryPreservationOn} = appSettingsSelector(state);

    const updateTime = async (peak: IPeakDevice) => {
      const state = getState() as Store;
      const device = currentDeviceSelector(state);

      const now = Math.round(Date.now() / 1000);
      const time = device?.utcTime;

      if (!time || now - time < 10) return;

      await peak.writeUtcTime(now);
    };

    await peak.updateBatterySaver(batteryPreservationOn);

    const connected = getDeviceFromPeak(peak);
    const stored: Device = state.ble.devices.find(
      ({id}) => connected.id === id,
    ) ?? {id: connected.id};

    // appSettings is stored in redux but not on the phisycal device, so we need to merge the two
    const device: Device = {
      ...stored,
      ...connected,
      appSettings: stored.appSettings,
    };

    const getCleaningReminderSettings = ({
      lastCleanReminderDabCount,
      cleaningReminderDabs,
      snoozeDabs,
      delay,
    }: DeviceAppSettings) => {
      return {
        lastCleanReminderDabCount:
          lastCleanReminderDabCount ?? peak.attributes?.totalHeatCycles ?? 0,
        cleaningReminderDabs: cleaningReminderDabs ?? 100,
        snoozeDabs: snoozeDabs ?? 10,
        delay: delay ?? 0,
        cleaningReminderLastUpdated: Date.now(),
      };
    };

    dispatch(
      updateDeviceInfo({
        ...device,
        appSettings: getCleaningReminderSettings(device.appSettings ?? {}),
      }),
    );
    dispatch(setConnectedDeviceId(device.id));

    await dispatch(bleReadDeviceSettings()).unwrap();
    await updateTime(peak);

    bleConnectSuccessEvent();
  },
);
