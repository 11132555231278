import React from 'react';
import {View, ViewProps} from 'react-native';

import {LoadingHaloSVG} from '.';
import {appColors} from '../constants';
import styled from '../lib/styled';

interface Props extends ViewProps {
  percentage: number;
  color?: string;
}

export const LoadingHalo = ({
  percentage,
  color = appColors.invisible,
  ...rest
}: Props) => {
  return (
    <HaloContainer {...rest}>
      <LoadingHaloSVG {...{percentage, color}} />
    </HaloContainer>
  );
};

const HaloContainer = styled(View)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  padding: 15,
});
