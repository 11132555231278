import {
  Log1,
  LogRecord,
  Pikaparam,
  auditLog1Def,
  faultLog1Def,
} from 'pikaparam';
import {DeviceLogType} from 'puffco-api-axios-client';
import {Subscription, auditTime} from 'rxjs';

export interface StartPeakLogCollectorOptions {
  configs: {type: DeviceLogType; begin?: number}[];
  onRecords: (records: LogRecord[]) => void;
}

export class PeakLogCollector {
  private map = {
    [DeviceLogType.Audit]: auditLog1Def,
    [DeviceLogType.Fault]: faultLog1Def,
  };

  private instances: [Log1, Subscription][] = [];

  constructor(private pikaparam: Pikaparam) {}

  start({configs, onRecords}: StartPeakLogCollectorOptions) {
    this.stop();

    const instances = configs.map<[Log1, Subscription]>(({type, begin}) => {
      let lastIndex: number | undefined;

      const logger = new Log1(
        this.map[type],
        this.pikaparam.logger,
        this.pikaparam.param,
        {startIndex: begin, updateDuringInit: true},
      );

      const subscription = logger.recordsObs
        // Batch updates every 1s
        .pipe(auditTime(1000))
        .subscribe(map => {
          // Assuming that map keys are returned in the order they were added
          const keys = Array.from(map.keys());

          if (!keys.length) return;

          const records = keys
            .slice(lastIndex)
            .map(key => map.get(key))
            .filter((record): record is LogRecord => !!record);

          onRecords(records);

          lastIndex = keys.length;
        });

      return [logger, subscription] as const;
    });

    this.instances = this.instances.concat(instances);
  }

  stop() {
    this.instances.forEach(([logger, subscription]) => {
      logger.dispose();
      subscription.unsubscribe();
    });

    this.instances = [];
  }
}
