import {TextStyle} from 'react-native';

import {
  le02BackgroundHomeScreen,
  le02BackgroundIntro,
  le02BackgroundProfile,
  le02Gem,
  le02GemMask,
  le02HeatProfileCardBackground,
  le02HeatProfileListBackground,
  le02LatticeIntroBottom,
  le02LatticeIntroModal,
  le02Peak,
  le02PeakLightsBaseFarLeft,
  le02PeakLightsBaseFarRight,
  le02PeakLightsBaseMidLeft,
  le02PeakLightsBaseMidRight,
  le02PeakLightsBaseRingFarLeft,
  le02PeakLightsBaseRingFarRight,
  le02PeakLightsBaseRingMidLeft,
  le02PeakLightsBaseRingMidRight,
  le02PeakLightsGlassFarLeft,
  le02PeakLightsGlassFarRight,
  le02PeakLightsGlassMidLeft,
  le02PeakLightsGlassMidRight,
} from '../assets/images';
import {appColors} from '../constants/Colors';
// Import from exact file to prevent cycle
import {shadeColor} from '../lib/shadeColor';
import {
  ConnectScreenTheme,
  ControlCenterTheme,
  HeatProfileCardTheme,
  HeatProfileCoreImageTheme,
  HeatProfileListScreenTheme,
  HeatProfileScreenBackgroundTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  TabBarTheme,
  Theme,
} from '../lib/types';
import {addOpacityToColorHex} from '../lib/utilityFunctions/addOpacityToColorHex';
import {defaultTheme} from './DefaultTheme';

const textShadowStyle: TextStyle = {
  textShadowColor: appColors.black,
  textShadowRadius: 4,
  textShadowOffset: {width: 0, height: 2},
};

// General
const durationStyle: TextStyle = {
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 14,
  letterSpacing: 0.75,
  textAlign: 'center',
  lineHeight: 20,
  ...textShadowStyle,
};

const temperatureBigStyle: TextStyle = {
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 65,
  color: appColors.white,
  letterSpacing: 0,
  textAlign: 'center',
  lineHeight: 41,
  ...textShadowStyle,
};

// Components

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  backgroundImage: le02HeatProfileCardBackground,
  durationFontColor: appColors.white,
  durationBubbleColor: appColors.black,
  gradientShadeFunction: (color: string) =>
    [0, 0].map(shade => shadeColor(color, shade)),
  overLayGradientColors: [
    addOpacityToColorHex(appColors.black, 0.8),
    addOpacityToColorHex(appColors.black, 0.2),
    addOpacityToColorHex(appColors.black, 0),
  ],
};

const heatProfileCoreImageTheme: HeatProfileCoreImageTheme = {
  baseImageSource: le02Gem,
  maskImageSource: le02GemMask,
};

const heatProfileScreenBackgroundTheme: HeatProfileScreenBackgroundTheme = {
  ...defaultTheme.heatProfileScreenBackgroundTheme,
  background: le02BackgroundProfile,
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: le02Peak,
  lightLayers: {
    glass: {
      farLeft: le02PeakLightsGlassFarLeft,
      farRight: le02PeakLightsGlassFarRight,
      midLeft: le02PeakLightsGlassMidLeft,
      midRight: le02PeakLightsGlassMidRight,
    },
    base: {
      farLeft: le02PeakLightsBaseFarLeft,
      farRight: le02PeakLightsBaseFarRight,
      midLeft: le02PeakLightsBaseMidLeft,
      midRight: le02PeakLightsBaseMidRight,
    },
    ring: {
      farLeft: le02PeakLightsBaseRingFarLeft,
      farRight: le02PeakLightsBaseRingFarRight,
      midLeft: le02PeakLightsBaseRingMidLeft,
      midRight: le02PeakLightsBaseRingMidRight,
    },
  } as Record<PeakSection, PeakImageLayers>,
  peakDarkScreen: le02Peak,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  backgroundColor: appColors.darkPurple,
};

// Screens

const connectScreenTheme: ConnectScreenTheme = {
  backgroundImage: le02BackgroundIntro,
  peakNameBackgroundColor: appColors.white,
  groundLayerImage: le02LatticeIntroBottom,
};

const controlCenterTheme: ControlCenterTheme = {
  ...defaultTheme.controlCenterTheme,
  infoText: appColors.white,
};

const dabbingScreenTheme = {
  ...defaultTheme.dabbingScreenTheme,
  targetTemperatureStyle: {
    fontFamily: 'BigShouldersDisplay-Bold',
    ...textShadowStyle,
  },
  timeStatusStyle: {
    fontFamily: 'Roboto-Bold',
    ...textShadowStyle,
  },
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: le02HeatProfileListBackground,
  hoverBackgroundColor: appColors.white,
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  ...defaultTheme.heatProfileSelectScreenTheme,
  durationColor: appColors.white,
  startTextStyle: {
    ...defaultTheme.heatProfileSelectScreenTheme.startTextStyle,
    fontFamily: 'Roboto-Bold',
    ...textShadowStyle,
  },
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,
  // General
  backgroundImage: le02BackgroundHomeScreen,
  groundLayerImage: le02LatticeIntroBottom,

  // Status info
  batteryBarBackgroundColor: appColors.white20,
  preserveIndicator: appColors.white,
  batteryDisconnectedColor: appColors.white,
  statusTitleTextColor: appColors.white,

  // Metric info
  dataBackgroundGradientArray: ['#85009b73', '#2B65FF00'],

  // Limited edition info
  limitedEditionText: {
    leftBorderGradientArray: [appColors.white, appColors.white],
    name: {color: appColors.white, value: 'Indiglow'},
    numberString: {
      color: addOpacityToColorHex(appColors.white, 0.8),
      value: '02',
    },
  },
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  backgroundImage: le02BackgroundIntro,
  groundLayerImage: le02LatticeIntroModal,
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl:
    'https://puffco-prod.s3.us-west-2.amazonaws.com/welcome/indiglow.mp4',
};

export const indiglowTheme: Theme = {
  ...defaultTheme,

  // General
  durationStyle,
  temperatureBigStyle,

  // Components
  heatProfileScreenBackgroundTheme,
  heatProfileCardTheme,
  heatProfileCoreImageTheme,
  peakImageTheme,
  tabBarTheme,

  // Screens
  connectScreenTheme,
  controlCenterTheme,
  dabbingScreenTheme,
  heatProfileSelectScreenTheme,
  heatProfileListScreenTheme,
  homeScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
};
