import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {Store} from '../types';

const interstitialScreenSlice = createSlice({
  name: 'interstitialScreen',
  initialState: {} as {
    [x: string]: {
      initialize?: number;
      seen?: number;
    };
  },
  reducers: {
    add(state, action: PayloadAction<{screen: string; start: number}>) {
      state[action.payload.screen] = {
        initialize: action.payload.start,
      };
    },
    updateSeen(state, action: PayloadAction<{screen: string}>) {
      state[action.payload.screen] = {
        ...state[action.payload.screen],
        seen: new Date().getTime(),
      };
    },
  },
});

export const interstitialScreenSelector = (state: Store) =>
  state.interstitialScreen;

export const {add, updateSeen} = interstitialScreenSlice.actions;
export const interstitialScreenReducer = interstitialScreenSlice.reducer;
