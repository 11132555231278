import {
  background,
  le05Peak,
  legacyPeakPeachBlackLightsBaseLeft,
  legacyPeakPeachBlackLightsBaseRight,
  legacyPeakPeachLightsGlassFarLeft,
  legacyPeakPeachLightsGlassFarRight,
  legacyPeakPeachLightsGlassMidLeft,
  legacyPeakPeachLightsGlassMidRight,
} from '../assets/images';
// Import from exact file to prevent cycle
import {appColors} from '../constants/Colors';
import {
  ConnectScreenTheme,
  ControlCenterTheme,
  DabbingScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  HeatProfileListScreenTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  NavMenuTitleStyle,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  StyledButtonTheme,
  TabBarTheme,
  Theme,
} from '../lib/types';
// Import from exact file to prevent cycle
import {addOpacityToColorHex} from '../lib/utilityFunctions/addOpacityToColorHex';
import {defaultTheme} from './DefaultTheme';

const BACKGROUND_COLOR = '#5F7665';
const LINEAR_GRADIENT_ARRAY = ['rgba(65, 87, 70, 0)', 'rgba(65, 87, 70, 1)'];

/* region: Components */
const navMenuTitleStyle: NavMenuTitleStyle = {
  ...defaultTheme.navMenuTitleStyle,
  color: appColors.white,
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: le05Peak,
  lightLayers: {
    glass: {
      farLeft: legacyPeakPeachLightsGlassFarLeft,
      farRight: legacyPeakPeachLightsGlassFarRight,
      midLeft: legacyPeakPeachLightsGlassMidLeft,
      midRight: legacyPeakPeachLightsGlassMidRight,
    },
    base: {
      left: legacyPeakPeachBlackLightsBaseLeft,
      right: legacyPeakPeachBlackLightsBaseRight,
    },
    ring: {},
  } as Record<PeakSection, PeakImageLayers>,
  peakDarkScreen: le05Peak,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: appColors.gold,
  inactiveColor: appColors.goldMediumGray,
  backgroundColor: 'transaprent',
};

const styledButtonTheme: StyledButtonTheme = {
  buttonColor: appColors.white,
  buttonTextColor: appColors.black,
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  durationBubbleColor: appColors.black,
  durationFontColor: appColors.white,
  specularColorStart: appColors.clear,
  specularColorEnd: appColors.clear,
};
/* endregion: Components */

/* region: Screens */
const connectScreenTheme: ConnectScreenTheme = {
  backgroundImage: background,
  peakNameBackgroundColor: appColors.black,
  gradientBackground: {
    backgroundColor: BACKGROUND_COLOR,
    gradientArray: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  backgroundImage: background,
  gradientBackground: {
    backgroundColor: BACKGROUND_COLOR,
    gradientArray: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl:
    'https://puffco-prod.s3.us-west-2.amazonaws.com/welcome/flourish.mp4',
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  gradientArray: LINEAR_GRADIENT_ARRAY,
  backgroundColor: BACKGROUND_COLOR,
  hoverBackgroundColor: appColors.gray,
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,

  // General
  backgroundImage: background,
  gradientBackground: {
    backgroundColor: BACKGROUND_COLOR,
    gradientArray: LINEAR_GRADIENT_ARRAY,
  },

  // Status info
  batteryBarBackgroundColor: appColors.mediumGray50,
  batteryDisconnectedColor: addOpacityToColorHex(appColors.white, 0.5),
  preserveIndicator: appColors.black,
  statusTextStealthColor: appColors.black,
  statusTitleTextColor: appColors.white,
  stealthIconColor: appColors.black,

  // Metric info
  dataBackgroundGradientArray: [
    addOpacityToColorHex('#485F4D', 1),
    addOpacityToColorHex('#384633', 0),
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(appColors.white, 0.5),
  metricTextColor: appColors.white,
  metricTitleTextColor: addOpacityToColorHex(appColors.white, 0.5),
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: appColors.white,
  dabbingScreenFadedText: appColors.mediumGray50,
  dabbingButtonBackground: appColors.bone,
  dabbingButtonBackgroundPressed: appColors.black,
  dabbingButtonForeground: appColors.black,
  dabbingButtonForegroundPressed: appColors.bone,
  iconColor: appColors.white,
  textColor: appColors.white,
  fadedTextColor: appColors.mediumGray50,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  ...defaultTheme.heatProfileEditScreenTheme,
  editTextColor: appColors.white,
  iconColor: appColors.white,
  navMenuTitleStyle: {
    ...defaultTheme.navMenuTitleStyle,
    color: appColors.white,
  },
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  primaryColor: appColors.white,
  durationColor: appColors.white50,
  startTextStyle: {color: appColors.white, fontWeight: '400'},
  backgroundLineColor: appColors.gray,
};

const controlCenterTheme: ControlCenterTheme = {
  titleText: appColors.white,
  infoText: appColors.white,
  fatSliderColor: appColors.white,
  fatSliderBGColor: appColors.black,
  buttonActiveForeground: appColors.black,
  buttonInactiveForeground: appColors.white,
  buttonActiveBackground: appColors.white,
  buttonInactiveBackground: appColors.black50,
  blurBackgroundColor: appColors.transluscentLightGray,
  backgroundColor: appColors.transluscentGray,
  boostScreenLabel: appColors.baseText,
  gradientArray: LINEAR_GRADIENT_ARRAY,
};
/* endregion: Screens */

export const flourishTheme: Theme = {
  ...defaultTheme,

  // Components
  primaryColor: appColors.white,
  primaryTextColor: appColors.white,
  logoTint: appColors.invisible,
  navMenuTitleStyle,
  navMenuIconColor: appColors.gold,
  peakImageTheme,
  heatProfileCardTheme,
  sectionDividerColor: appColors.white20,
  sectionTitleTextColor: appColors.white,
  statusDisplayTextColor: appColors.white,
  spinnerColor: appColors.black,
  tabBarTheme,
  styledButtonTheme,

  // Screens
  connectScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
  homeScreenTheme,
  heatProfileListScreenTheme,
  dabbingScreenTheme,
  heatProfileEditScreenTheme,
  heatProfileSelectScreenTheme,
  controlCenterTheme,

  dabTickTheme: {
    color: appColors.white,
  },
};
