import React from 'react';
import {useSelector} from 'react-redux';

import {RECORDS} from '../constants';
import {currentDeviceSelector} from './redux/bleSlice';
import {Store} from './types';
import {getProductName} from './utilityFunctions/getProductName';

export const useTheme = () => {
  const modelNumberString = useSelector(
    (state: Store) => currentDeviceSelector(state)?.modelNumberString,
  );
  const serialNumberString = useSelector(
    (state: Store) => currentDeviceSelector(state)?.serialNumberString,
  );
  const product = useSelector(
    (state: Store) => currentDeviceSelector(state)?.product,
  );

  return React.useMemo(
    () =>
      RECORDS.THEME_RECORD[
        getProductName({modelNumberString, serialNumberString, product})
      ],
    [modelNumberString, serialNumberString, product],
  );
};
