import {BLOCKS, Block, INLINES, Inline} from '@contentful/rich-text-types';
import React from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';

import {Constants, Screens, Strings, appColors} from '../../../constants';
import {resolveAssetUrl} from '../../../lib/api';
import {TypeDeviceDocumentSkeleton} from '../../../lib/api/content-access/types';
import {useAdaptiveSafeArea} from '../../../lib/hooks/useAdaptiveSafeArea';
import {DeviceEducationNavigatorScreenProps} from '../../../navigation/navigators/HomeDrawerNavigator';
import {useContactSupport} from '../../support/useContactSupport';
import {DeviceSupportHeader} from '../components/DeviceSupportHeader';
import {Footer} from '../components/Footer';
import {Hyperlink} from '../components/Hyperlink';
import {ListMarker, RichTextRenderer} from '../components/RichTextRenderer';

export interface DeviceDocumentScreenProps {
  document: TypeDeviceDocumentSkeleton;
}

interface ScreenProps
  extends DeviceEducationNavigatorScreenProps<typeof Screens.DeviceDocument> {}

export const DeviceDocumentScreen: React.FC<ScreenProps> = ({
  route,
  navigation,
}) => {
  const contactSupport = useContactSupport();
  useAdaptiveSafeArea();

  const options = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: (
        _node: Block | Inline,
        children: React.ReactNode,
      ) => {
        const render = () => {
          if (
            _node.content.length > 1 ||
            (_node.content[0].nodeType !== 'paragraph' &&
              _node.content[0].nodeType !== 'text')
          )
            return children;

          if (_node.content[0].nodeType === 'text')
            return _node.content[0].value;

          //@ts-ignore
          return _node.content[0].content[0].value;
        };

        return (
          <View style={styles.listItemContainer}>
            <ListMarker style={styles.bulletPoint} />
            <Text style={styles.listItemText}>{render()}</Text>
          </View>
        );
      },

      [INLINES.HYPERLINK]: (
        node: Block | Inline,
        children: React.ReactNode,
      ) => {
        const {uri} = node.data;

        return (
          <Hyperlink style={{color: appColors.azureBlue}} {...{uri}}>
            {children}
          </Hyperlink>
        );
      },
    },
  };

  const document = route.params.document;

  const fields = document?.fields;

  React.useEffect(() => {
    const defaultTitle =
      Strings.DEVICE_EDUCATION.DEVICE_DOCUMENTS.DEFAULT_TITLE;

    const headerTitle =
      !!document.fields.title && typeof document.fields.title === 'string'
        ? document.fields.title.toUpperCase()
        : defaultTitle;

    navigation.setOptions({
      headerTitle,
    });
  }, [navigation, document]);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={styles.layout}
      contentContainerStyle={{
        height: Constants.IS_WEB ? 0 : undefined,
      }}>
      <DeviceSupportHeader
        source={{
          uri: resolveAssetUrl(fields?.background),
        }}
      />

      <View style={styles.content}>
        <RichTextRenderer content={fields?.content} customOptions={options} />
      </View>

      {document.fields.showSupportQuestion && (
        <Footer
          style={styles.footer}
          title={Strings.DEVICE_EDUCATION.DEVICE_DOCUMENTS.FOOTER}
          onContactSupportPress={contactSupport}
        />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  layout: {
    flex: 1,
    backgroundColor: appColors.black,
  },
  content: {
    paddingBottom: 72,
    marginTop: 48,
    paddingHorizontal: 24,
  },
  footer: {
    paddingBottom: 82,
    textTransform: 'uppercase',
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  listItemText: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    alignSelf: 'flex-start',
    color: appColors.textOnSurface,
    lineHeight: 28,
  },
  bulletPoint: {
    lineHeight: 24,
  },
});
