import {useSelector} from 'react-redux';

import {Constants} from '../../constants';
import {currentDeviceSWRevisionSelector} from '../../lib/redux/bleSlice';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {ChamberType} from '../types';
import {useWatchDevice} from './useWatchDevice';

export const useChamberType = () => {
  const fw = useSelector(currentDeviceSWRevisionSelector);
  const chamberType = useWatchDevice('chamberType');

  const isMeetAC = meetsMinimumFirmware(
    fw,
    Constants.MINIMUM_FIRMWARE_VERSION.VAPOR_SETTING,
  );
  const isMeetAG = meetsMinimumFirmware(
    fw,
    Constants.MINIMUM_FIRMWARE_VERSION.XL_CHAMBER,
  );
  const is3dChamber = chamberType === ChamberType.Performance;
  const isXLChamber = chamberType === ChamberType.XL;

  const is3dVaporEnabled = isMeetAC && is3dChamber;
  const isXLEnabled = isMeetAG && isXLChamber;

  const isVaporEnabled = is3dVaporEnabled || isXLEnabled;

  const isChamberDisconnected = chamberType === ChamberType.None;

  return {
    isVaporEnabled,
    is3dVaporEnabled,
    isXLEnabled,
    isMeetAC,
    isChamberDisconnected,
    is3dChamber,
    isXLChamber,
    isMeetAG,
  };
};
