import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {
  AppText,
  CustomMenuList,
  ImgBackground,
  StyledIcon,
} from '../../components';
import {Constants, Messages, Screens, appColors} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  currentDeviceSelector,
  updateDeviceAppSettings,
} from '../../lib/redux/bleSlice';
import styled from '../../lib/styled';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.SnoozeFrequency
>;

export const SnoozeFrequencyScreen = (_props: ScreenProps) => {
  useAdaptiveSafeArea();
  const dispatch = useDispatch();

  const device = useSelector(currentDeviceSelector);

  const [preference, setPreference] = React.useState(
    device?.appSettings?.snoozeDabs || 10,
  );

  const handleSelection = (switchValue: number) => {
    if (preference !== switchValue && device) {
      // Update global state
      dispatch(
        updateDeviceAppSettings({id: device.id, snoozeDabs: switchValue}),
      );
      setPreference(switchValue);
    }
  };

  const renderButton = ({item}: {item: number}): React.ReactElement => (
    <PickerButton onPress={() => handleSelection(item)}>
      <ButtonText>{item}</ButtonText>
      {preference === item && (
        <StyledIcon name="check" color={appColors.green} />
      )}
    </PickerButton>
  );

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1, backgroundColor: appColors.black}}>
        <SectionContainer>
          <SectionContainerText>
            {Messages.SNOOZE_FREQUENCY_HEADER}
          </SectionContainerText>
          <CustomMenuList
            menuItemValues={[...Constants.DEFAULT_SNOOZE_VALUES]}
            renderItem={renderButton}
          />
        </SectionContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const SectionContainer = styled(View)({
  flexDirection: 'column',
  marginTop: 64,
});

const SectionContainerText = styled(AppText)({
  marginLeft: Constants.IS_NATIVE_ANDROID ? 22 : 24,
  lineHeight: 12,
  color: appColors.mediumGray50,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  textTransform: 'uppercase',
  fontWeight: '400',
});

const ButtonText = styled(Text)({
  marginLeft: 15,
  paddingTop: 10,
  color: appColors.white,
  fontSize: 12,
  fontFamily: 'Roboto-Regular',
  letterSpacing: 0.47,
  fontWeight: '400',
});

const PickerButton = styled(TouchableOpacity)({
  height: 40,
  borderRadius: 45,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
