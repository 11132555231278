import {ConnectedDevice} from 'pikaparam';

import {OtaDevice} from '../ble2/v2/OtaDevice';
import {IPeakDevice} from '../ble2/v2/PeakDevice/IPeakDevice';
import {Device} from '../types';

type AnyDevice = ConnectedDevice | IPeakDevice | OtaDevice | Device;

const isPeakDevice = (device: AnyDevice): device is IPeakDevice =>
  !!(device as IPeakDevice).firmwareType;

const isConnectedDevice = (device: AnyDevice): device is ConnectedDevice =>
  !!(device as ConnectedDevice).writeCharWithResponse;

export const serializeDevice = (device: AnyDevice) => {
  if (isPeakDevice(device)) return serializePeak(device);
  if (device instanceof OtaDevice) return serializeOtaDevice(device);
  if (isConnectedDevice(device)) return serializeConnectedDevice(device);
  return serializeReduxDevice(device);
};

const serializeConnectedDevice = (device: ConnectedDevice) => ({
  peripheralId: device.id,
  name: device.name,
  state: device.state,
});

const serializePeak = (device: IPeakDevice) => ({
  peripheralId: device.peripheralId,
  name: device.name,
  serialNumber: device.serialNumber,
  model: device.modelNumber,
  firmware: device.softwareRevision,
  type: device.firmwareType,
  githash: device.gitHash,
  product: device.product,
  connected: device.connected,
});

const serializeReduxDevice = (device: Device) => ({
  peripheralId: device.id,
  name: device.name,
  serialNumber: device.serialNumberString,
  model: device.modelNumberString,
  firmware: device.softwareRevisionString,
  githash: device.gitHashString,
});

const serializeOtaDevice = (device: OtaDevice) => ({
  peripheralId: device.peripheralId,
  deviceState: device.deviceState,
  flashState: device.flashState,
  serialNumber: device.serialNumber,
});
